import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ReactComponent as Img } from "../../assets/slider1.svg";
function UniqueSlider({ scrollVal }) {
    return (
        <>
            <ProgressBar
                percent={scrollVal}
                filledBackground=" var(--primary)"
                style={{ height: "3px" }}
            >
                <Step transition="scale">
                    {({ accomplished }) => (
                        <Img
                            fill={accomplished ? " var(--primary)" : "#bbbbbb"}
                        />
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <>
                            <Img
                                style={{
                                    filter: `grayscale(${
                                        accomplished ? 0 : 100
                                    }%)`,
                                }}
                                fill={
                                    accomplished ? " var(--primary)" : "#bbbbbb"
                                }
                            />
                        </>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <Img
                            fill={accomplished ? " var(--primary)" : "#bbbbbb"}
                        />
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <Img
                            fill={accomplished ? " var(--primary)" : "#bbbbbb"}
                        />
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <Img
                            fill={accomplished ? " var(--primary)" : "#bbbbbb"}
                        />
                    )}
                </Step>
            </ProgressBar>
        </>
    );
}

export default UniqueSlider;
