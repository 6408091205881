import { UPDATE_INFO, USER_INFO } from "../action/action";

const initialState = { user: "" };

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO:
      return action.payload;

    case UPDATE_INFO:
      return action.payload


    default:
      return state;
  }
};

export default userReducer;
