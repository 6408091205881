import React, { useState, useRef, useEffect } from "react";
import { UpdateProfile } from "../../../utils/apis";
import { UserInfo } from "../../../Redux/exportAllAction";
import { useSelector, useDispatch } from "react-redux";
import KYCSlider from "./KYCSlider";
import Viewer from "react-viewer";
import PanImg from "../../../assets/pancard.jpg";
// import UploadImg from "../../../assets/upload.gif";
import AadharCard from "../../../assets/aadharCard.png";
import Cheque from "../../../assets/Cheque.jpg";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../utils/firebase";
import { PanOCR, AddharOCR, ChequeOCR } from "../../../utils/OCR";
import { toast } from "react-toastify";
import { BsFillPatchCheckFill } from "react-icons/bs";
function KYC({
  setSelectedTab,
  pancard,
  setPancard,
  aadharcard,
  setAddharCard,
  bankDetails,
  setBankDetails,
  setLoader,
}) {
  const [KYCStep, setKYCStep] = useState(1);
  const [imageFile, setImageFile] = useState({});
  const dispatch = useDispatch();
  const { kycStatus } = useSelector((state) => state?.user);
  const [scrollVal, setScrollVal] = useState(0);
  const [select, setSelect] = useState("pan");
  const [click, setClick] = useState("add");
  const [imgviewer, setViewer] = useState({ state: false, value: "" });
  const [loading, setLoading] = useState("100%");
  const UploadInput = useRef(null);

  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const handlePanCardChange = (e) => {
    const { name } = e.target;
    setPancard({ ...pancard, [name]: e.target.value });
  };
  const handleaadharcardChange = (e) => {
    const { name } = e.target;
    setAddharCard({ ...aadharcard, [name]: e.target.value });
  };
  const handleBankDetailsChange = (e) => {
    const { name } = e.target;
    setBankDetails({ ...bankDetails, [name]: e.target.value });
  };
  const handleSubmit = async () => {
    const kycDetails = {
      pancard,
      aadharCard: aadharcard,
      bankDetails,
    };
    setLoader(true);
    try {
      const { data } = await UpdateProfile({
        kycDetails,
        kycStatus: "pending",
      });
      dispatch(UserInfo(data.result));
      setSelectedTab("PERSONAL");
      setLoader(false);
      toast.success("Form Submitted", ToastOptions);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  function fileUploadHandler() {
    const file = UploadInput.current.files[0];
    setImageFile(file);
    console.log(file);

    if (!file) return;
    setLoader(true);
    const sotrageRef = ref(storage, `files/${file.name}`);
    setLoading("0%");
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = `${Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )}%`;
        setLoading(prog === "100%" ? "90%" : prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fileExtract(downloadURL);
        });
      }
    );
  }
  useEffect(() => {
    if (CheckKYC()) {
      setKYCStep(5);
    }
  }, []);

  async function fileExtract(pic) {
    const postData = {
      task_id: process.env.REACT_APP_OCR_ACCOUNT_ID,
      group_id: process.env.REACT_APP_OCR_API_KEY,
      data: { document1: pic, consent: "yes" },
    };
    try {
      if (KYCStep === 1) {
        if (select === "pan") {
          const formData = new FormData();
          formData.append('file', UploadInput.current.files[0], "file");
          const { data } = await PanOCR(formData);
          console.log(data?.data?.ocr_fields[0]);
          const output = data?.data?.ocr_fields[0];
          setPancard({
            ...pancard,
            img: pic,
            name: output?.full_name?.value,
            number: output?.pan_number?.value,
            dateOfBirth: output?.dob.value,
          });
          setLoader(false);

        } else if (select === "aadhar") {
          const formData = new FormData();
          formData.append('file', UploadInput.current.files[0], "file");
          // const { data } = await AddharOCR(postData);
          const { data } = await AddharOCR(formData);
          const output = data?.data?.ocr_fields[0];
          setAddharCard({
            ...aadharcard,
            img: pic,
            number: output?.aadhaar_number?.value,
            name: output?.full_name?.value,
          });
          setLoader(false);
        } else if (select === "bank") {
          const formData = new FormData();
          formData.append('file', UploadInput.current.files[0], "file");
          const { data } = await ChequeOCR(formData);
          console.log(data.data.ifsc_code)
          const output = data?.data;

          setBankDetails({
            ...bankDetails,
            cancelledCheque: pic,
            accountNumber: output.account_number?.value, //number
            ifsc: output?.ifsc_code?.value,
          });
          setLoader(false);
        }
      } else if (KYCStep === 2) {
        const { data } = await PanOCR(postData);
        const output = data?.result?.extraction_output;
        setPancard({
          ...pancard,
          img: pic,
          name: output.name_on_card,
          number: output.id_number,
          dateOfBirth: output.date_of_birth,
        });
        setLoader(false);
      } else if (KYCStep === 3) {
        const { data } = await AddharOCR(postData);
        const output = data?.result?.extraction_output;
        setAddharCard({
          ...aadharcard,
          img: pic,
          number: output?.id_number,
          name: output?.name_on_card,
        });
        setLoader(false);
      } else if (KYCStep === 4) {
        const { data } = await ChequeOCR(postData);
        const output = data?.result?.extraction_output;
        console.log(output);
        setBankDetails({
          ...bankDetails,
          cancelledCheque: pic,
          accountHolderName: output.account_name,
          accountNumber: output.account_no, //number
          ifsc: output.ifsc_code,
        });
      }
      setLoading("100%");
      setLoader(false);
    } catch (error) {
      console.log(error);
      const { response } = error;
      console.log(response?.data?.message);
      toast.error(response?.data?.message, ToastOptions);

      setLoading("100%");
      setLoader(false);
    }
  }

  function CheckKYC() {
    if (kycStatus === null || kycStatus === "rejected") return false;
    else return true;
  }
  function changeSlider(e) {
    e.preventDefault();
    if (!pancard.img && kycStatus !== "approved")
      toast.error("please add pan card image", ToastOptions);
    else if (!aadharcard.img && kycStatus !== "approved")
      toast.error("please add aadhar card image", ToastOptions);
    else if (!bankDetails.cancelledCheque && kycStatus !== "approved")
      toast.error("please add cancelled cheque image", ToastOptions);
    else setKYCStep(click === "sub" ? KYCStep - 1 : KYCStep + 1);
  }
  useEffect(() => {
    if (KYCStep === 1) {
      setScrollVal(0);
    } else if (KYCStep === 2) {
      setScrollVal(35);
    } else if (KYCStep === 3) {
      setScrollVal(68);
    } else {
      setScrollVal(100);
    }
  }, [KYCStep]);

  const handlePanDOBFormat = (event) => {
    return new Date(event.split('/').reverse().join('-')).toISOString().split('T')[0]
  }
  return (
    <>
      <div className="d-flex flex-column w-100">
        <div className="d-flex  flex-column align-items-center KYC-Modal">
          <h4>
            {kycStatus !== null ? `View KYC information` : `Complete Your KYC`}
          </h4>
          <h6>{kycStatus === null && `within 3 steps`}</h6>
        </div>
        <div className="py-5 mt-1 px-5">
          <KYCSlider scrollVal={scrollVal} />
        </div>
        <form className="container py-2" onSubmit={changeSlider}>
          {KYCStep == 1 ? (
            <div className="d-flex justify-content-between mb-2">
              <div>
                <img
                  src={pancard.img || PanImg}
                  alt="pancard-img"
                  width="200px"
                  title="click to view"
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: pancard.img || PanImg,
                    })
                  }
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => {
                      UploadInput.current.click();
                      setSelect("pan");
                    }}
                  >
                    {loading !== "100%" && loading === "0%" && select === "pan"
                      ? `Uploading ${loading}`
                      : "Click To Upload Pan Image"}
                  </h6>
                )}
              </div>
              <div>
                <img
                  src={aadharcard.img || AadharCard}
                  alt="AadharCard"
                  title="click to view"
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: aadharcard.img || AadharCard,
                    })
                  }
                  width="200px"
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => {
                      UploadInput.current.click();
                      setSelect("aadhar");
                    }}
                  >
                    {loading !== "100%" &&
                      loading === "0%" &&
                      select === "aadhar"
                      ? `Uploading ${loading}`
                      : "Click To Upload Aadhar Image"}
                  </h6>
                )}
              </div>
              <div>
                <img
                  src={bankDetails.cancelledCheque || Cheque}
                  title="click to view"
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: bankDetails.cancelledCheque || Cheque,
                    })
                  }
                  alt="Cheque"
                  width="200px"
                  height="120px"
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => {
                      UploadInput.current.click();
                      setSelect("bank");
                    }}
                  >
                    {loading !== "100%" && loading === "0%" && select === "bank"
                      ? `Uploading ${loading}`
                      : "Click To Upload Bank Check Image"}
                  </h6>
                )}
              </div>
              <input
                ref={UploadInput}
                type="file"
                accept="image/*"
                disabled={CheckKYC()}
                hidden
                onChange={fileUploadHandler}
              />
            </div>
          ) : KYCStep == 2 ? (
            <div className="row">
              <div className="col-2">
                <img
                  src={pancard.img || PanImg}
                  alt="pancard-img"
                  width="100%"
                  title="click to view"
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: pancard.img,
                    })
                  }
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => UploadInput.current.click()}
                  >
                    {loading !== "100%" && loading === "0%"
                      ? `Uploading ${loading}`
                      : "Click To Upload Image"}
                  </h6>
                )}
                <input
                  ref={UploadInput}
                  accept="image/*"
                  disabled={CheckKYC()}
                  hidden
                  onChange={fileUploadHandler}
                />
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label>Pan Card Name *</label>
                  <input
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    value={pancard.name}
                    disabled={CheckKYC()}
                    name="name"
                    required
                    onChange={handlePanCardChange}
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>Date Of Birth *</label>
                  <input
                    type="date"
                    placeholder="DOB"
                    value={handlePanDOBFormat(pancard.dateOfBirth)}
                    disabled={CheckKYC()}
                    name="dateOfBirth"
                    required
                    onChange={handlePanCardChange}
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label>Pan Card No *</label>
                  <input
                    type="text"
                    minLength="10"
                    maxLength="10"
                    placeholder="PAN"
                    value={pancard.number}
                    disabled={CheckKYC()}
                    name="number"
                    required
                    onChange={handlePanCardChange}
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
              </div>
            </div>
          ) : KYCStep == 3 ? (
            <div className="row">
              <div className="col-2">
                <img
                  src={aadharcard.img || AadharCard}
                  alt="aadhar-card"
                  width="100%"
                  title="click to view "
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: aadharcard.img,
                    })
                  }
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => UploadInput.current.click()}
                  >
                    {loading !== "100%" && loading === "0%"
                      ? `Uploading ${loading}`
                      : "Click To Upload Image"}
                  </h6>
                )}
                <input
                  ref={UploadInput}
                  type="file"
                  accept="image/*"
                  disabled={CheckKYC()}
                  hidden
                  onChange={fileUploadHandler}
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="col form-group mb-3">
                    <label>Aadhar Card Name *</label>
                    <input
                      type="text"
                      placeholder="Aadhar Name"
                      className="form-control"
                      required
                      value={aadharcard.name}
                      disabled={CheckKYC()}
                      name="name"
                      onChange={handleaadharcardChange}
                      id="exampleInputEmail1"
                    />
                  </div>

                  <div className="col form-group mb-3">
                    <label>Aadhar Card No *</label>
                    <input
                      required
                      type="text"
                      pattern="\d*"
                      minLength="12"
                      maxLength="12"
                      placeholder="Aadhar Number"
                      value={aadharcard.number}
                      name="number"
                      disabled={CheckKYC()}
                      onChange={handleaadharcardChange}
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mb-3">
                    <label>Residential Address *</label>
                    <input
                      type="text"
                      required
                      placeholder="Residential Address"
                      className="form-control"
                      value={aadharcard.residentialAddress}
                      disabled={CheckKYC()}
                      name="residentialAddress"
                      onChange={handleaadharcardChange}
                      id="exampleInputEmail1"
                    />
                  </div>

                  <div className="col form-group mb-3">
                    <label>State *</label>
                    <input
                      type="text"
                      required
                      placeholder="State"
                      value={aadharcard.state}
                      name="state"
                      disabled={CheckKYC()}
                      onChange={handleaadharcardChange}
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mb-3">
                    <label>Pin Code *</label>
                    <input
                      type="text"
                      pattern="\d*"
                      minLength="6"
                      maxLength="6"
                      required
                      placeholder="Pin-Code"
                      className="form-control"
                      disabled={CheckKYC()}
                      value={aadharcard.pincode}
                      name="pincode"
                      onChange={handleaadharcardChange}
                      id="exampleInputEmail1"
                    />
                  </div>

                  <div className="col form-group mb-3">
                    <label>City *</label>
                    <input
                      required
                      type="text"
                      placeholder="City"
                      value={aadharcard.city}
                      name="city"
                      disabled={CheckKYC()}
                      onChange={handleaadharcardChange}
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : KYCStep == 4 ? (
            <div className="row">
              <div className="col-2">
                <img
                  src={bankDetails.cancelledCheque || Cheque}
                  alt="cheque"
                  width="100%"
                  title="click to view "
                  onClick={() =>
                    setViewer({
                      ...imgviewer,
                      state: true,
                      value: bankDetails.cancelledCheque,
                    })
                  }
                />
                {!CheckKYC() && (
                  <h6
                    className="upload-text"
                    onClick={() => UploadInput.current.click()}
                  >
                    {loading !== "100%" && loading === "0%"
                      ? `Uploading ${loading}`
                      : "Click To Upload Image"}
                  </h6>
                )}
                <input
                  ref={UploadInput}
                  type="file"
                  accept="image/*"
                  disabled={CheckKYC()}
                  hidden
                  onChange={fileUploadHandler}
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="col form-group mb-3">
                    <label>Account Holder Name *</label>
                    <input
                      required
                      type="text"
                      placeholder="Account Holder Name"
                      className="form-control"
                      value={bankDetails.accountHolderName}
                      name="accountHolderName"
                      disabled={CheckKYC()}
                      onChange={handleBankDetailsChange}
                      id="exampleInputEmail1"
                    />
                  </div>

                  <div className="col form-group mb-3">
                    <label>IFSC *</label>
                    <input
                      required
                      type="text"
                      placeholder="IFSC"
                      disabled={CheckKYC()}
                      value={bankDetails.ifsc}
                      name="ifsc"
                      onChange={handleBankDetailsChange}
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mb-3">
                    <label>Account Number *</label>
                    <input
                      required
                      type="text"
                      placeholder="Account Number"
                      disabled={CheckKYC()}
                      value={bankDetails.accountNumber}
                      name="accountNumber"
                      onChange={handleBankDetailsChange}
                      id="exampleInputEmail1"
                      className="form-control"
                    />
                  </div>

                  <div className="col form-group mb-3">
                    <label>Branch *</label>
                    <input
                      required
                      type="text"
                      placeholder="Branch"
                      disabled={CheckKYC()}
                      value={bankDetails.branch}
                      name="branch"
                      onChange={handleBankDetailsChange}
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <h4>
                {kycStatus === null && "KYC is Incomplete"}
                {kycStatus === "pending" && "Your KYC is under review."}
                {kycStatus === "approved" && (
                  <>
                    KYC approved{" "}
                    <BsFillPatchCheckFill color="green" size="24" />
                  </>
                )}
                {kycStatus == "rejected" && "KYC rejected ❌"}
              </h4>
            </div>
          )}

          <div className="d-flex justify-content-center w-100 gap-3">
            {KYCStep > 1 ? (
              <button
                type="submit"
                onClick={() => setClick("sub")}
                className="KYC-next-button"
              >
                Previous
              </button>
            ) : null}
            {KYCStep < 4 ? (
              <button
                type="submit"
                onClick={() => setClick("add")}
                className="KYC-next-button"
              >
                Next
              </button>
            ) : null}
            {KYCStep === 4 ? (
              <button
                disabled={CheckKYC()}
                onClick={handleSubmit}
                style={{ opacity: CheckKYC() ? 0.5 : 1 }}
                className="KYC-next-button"
              >
                {CheckKYC() ? "Submitted" : "Submit"}
              </button>
            ) : null}
          </div>
        </form>
      </div>
      <Viewer
        visible={imgviewer.state}
        zIndex="1000000"
        onClose={() => {
          setViewer({ ...Viewer, state: false });
        }}
        images={[{ src: imgviewer.value, alt: "imgviewer" }]}
      />
    </>
  );
}

export default KYC;
