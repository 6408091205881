import React, { useState, useEffect, useRef } from "react";
import RSlider from "./RSlider";
import { UserInfo } from "../../../Redux/exportAllAction";
import { toast } from "react-toastify";
import img1 from "../../../assets/pic1.png";
import { useSelector, useDispatch } from "react-redux";
import {
    AiOutlineDelete,
    AiOutlineLink,
    AiOutlineCloudUpload,
} from "react-icons/ai";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../utils/firebase";
import { UpdateRaiserProfile } from "../../../utils/apis";
import Loading from "../../../common/Loading";
const RProfile = () => {
    const [scrollVal, setScrollVal] = useState(0);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState("100%");
    const [loader, setLoader] = useState(false);
    const [update, setUpdate] = useState(false);
    const [click, setClick] = useState("add");
    const [progress, setProgress] = useState({
        financials: 0,
        business: 0,
        projections: 0,
    });
    const [multiple, setMultiple] = useState([]);
    const [input, setInput] = useState({
        companyName: "",
        companyWebsite: "",
        industry: "",
        typeOfFinancing: "fixed",
        fundedAlready: false,
        timeLine: "<12months",
        returns: "10%",
        kycStatus: "pending",
        buisnessModel: { url: "" },
        financialProjection: { url: "" },
        valuation: { url: "" },
        otherDocuments: [],
        profile: "",
    });
    const ToastOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    };

    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const upload = useRef();
    const financeUploadRef = useRef();
    const projectionRef = useRef();
    const businessRef = useRef();
    const handleChange = (e) => {
        const { name } = e.target;
        setInput({ ...input, [name]: e.target.value });
    };

    function changeSlider(e) {
        e.preventDefault();
        setStep(click === "sub" ? step - 1 : step + 1);
    }
    console.log(input);
    async function updateProfile(e) {
        setLoader(true);
        e.preventDefault();
        setUpdate(true);
        console.log(input);
        try {
            const { data } = await UpdateRaiserProfile(input);
            dispatch(UserInfo(data.result));
            toast.success("Kyc Submitted ", ToastOptions);
            setStep(1);
            setUpdate(false);
            setLoader(false);
        } catch (error) {
            const { response } = error;
            console.log(response);
            setUpdate(false);
            setLoader(false);
        }
    }

    function fileUploadHandler(e, n) {
        const file = e.target.files[0];
        if (!file) return;
        setLoader(true);
        setProgress(0);
        const storageRef = ref(storage, `files/${file.name}`);
        setLoading("0%");
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = `${Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )}%`;
                setLoading(prog);
            },
            (error) => console.log(error),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let url = { url: downloadURL };
                    if (n === "buisnessModel")
                        setInput({ ...input, buisnessModel: url });
                    else if (n === "valuation")
                        setInput({ ...input, valuation: url });
                    else if (n === "financialProjection")
                        setInput({ ...input, financialProjection: url });
                    else if (n === "pic") {
                        setInput({ ...input, profile: downloadURL });
                    } else {
                        setMultiple([...multiple, url]);
                        upload.current.value = "";
                    }
                    setLoader(false);
                });
            }
        );
    }
    const removeItem = (index) => {
        let data = [...multiple];

        data.splice(index, 1);
        setMultiple(data);
    };

    useEffect(() => {
        if (step === 1) {
            setScrollVal(0);
        } else if (step === 2) {
            setScrollVal(50);
        } else {
            setScrollVal(100);
        }
    }, [step]);
    // console.log(multiple);
    useEffect(() => {
        setInput({ ...input, otherDocuments: multiple });
    }, [multiple]);
    console.log(input);
    console.log(user);
    useEffect(() => {
        setInput({
            ...input,
            profile: user.profile,
            companyName: user.companyName,
            companyWebsite: user.companyWebsite,
            industry: user.industry,
            typeOfFinancing: user.typeOfFinancing,
            fundedAlready: user.fundedAlready,
            timeLine: user.timeLine,
            returns: user.returns,
            buisnessModel: user.buisnessModel,
            financialProjection: user.financialProjection,
            valuation: user.valuation,
            otherDocuments: user.otherDocuments,
        });
        setMultiple(user.otherDocuments);
    }, [user]);

    function CheckKYC() {
        if (user.kycStatus === null || user.kycStatus === "rejected")
            return false;
        else return true;
    }

    return (
        <>
            <section className="profile-wrapper container h-100 m-5">
                <div className="my-2" style={{ width: "90%", margin: "auto" }}>
                    <RSlider scrollVal={scrollVal} />
                </div>
                <div className="profile-box p-5 mt-5">
                    <div className="profile-personal-right col-12 ">
                        <form
                            className="d-flex flex-wrap justify-content-between"
                            onSubmit={changeSlider}
                        >
                            {step === 1 && (
                                <>
                                    <div className="form-group col-5 mb-4">
                                        {!CheckKYC() && (
                                            <h6
                                                style={{
                                                    color: "var(--primary)",
                                                }}
                                                onClick={() =>
                                                    upload.current.click()
                                                }
                                            >
                                                {loading === "100%"
                                                    ? "Click To Upload Image"
                                                    : loading}
                                            </h6>
                                        )}

                                        <div
                                            style={{
                                                cursor: !CheckKYC()
                                                    ? "pointer"
                                                    : "default",
                                            }}
                                            onClick={() =>
                                                !CheckKYC() &&
                                                upload.current.click()
                                            }
                                        >
                                            <img
                                                src={
                                                    input.profile ||
                                                    user?.profile ||
                                                    img1
                                                }
                                                alt="User-image"
                                                width="80px"
                                                height="80px"
                                                style={{ borderRadius: "50%" }}
                                            />

                                            <input
                                                type="file"
                                                ref={upload}
                                                accept="image/*"
                                                hidden
                                                onChange={(e) =>
                                                    fileUploadHandler(e, "pic")
                                                }
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Company Name *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            aria-describedby="emailHelp"
                                            required
                                            disabled={CheckKYC()}
                                            value={input?.companyName}
                                            name="companyName"
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Website *
                                        </label>
                                        <input
                                            type="url"
                                            required
                                            disabled={CheckKYC()}
                                            className="form-control"
                                            value={input?.companyWebsite}
                                            name="companyWebsite"
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group col-5">
                                        <label htmlFor="exampleInputEmail1">
                                            Email{" "}
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            disabled
                                            value={user?.email}
                                            name="email"
                                        />
                                    </div>

                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Industry *
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            disabled={CheckKYC()}
                                            className="form-control"
                                            value={input?.industry}
                                            name="industry"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            KYC Status
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={
                                                user?.kycStatus === null
                                                    ? "Incomplete"
                                                    : user?.kycStatus ===
                                                      "rejected"
                                                    ? "Rejected ❌"
                                                    : user?.kycStatus ===
                                                      "approved"
                                                    ? "Approved ✅"
                                                    : "Under review"
                                            }
                                            name="companyName"
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label htmlFor="exampleInputEmail1">
                                            Funded Already *
                                        </label>
                                        <br />
                                        <input
                                            type="radio"
                                            name="Funded Already"
                                            value="true"
                                            disabled={CheckKYC()}
                                            checked={input.fundedAlready}
                                            onChange={() =>
                                                setInput({
                                                    ...input,
                                                    fundedAlready: true,
                                                })
                                            }
                                        />{" "}
                                        <label htmlFor="Yes">Yes</label>&nbsp;
                                        <input
                                            type="radio"
                                            name="Funded Already"
                                            value="false"
                                            checked={!input.fundedAlready}
                                            onChange={() =>
                                                setInput({
                                                    ...input,
                                                    fundedAlready: false,
                                                })
                                            }
                                        />{" "}
                                        <label htmlFor="No">No</label>
                                    </div>
                                    {user?.reason && (
                                        <div className="form-group col-5 mb-4">
                                            <label htmlFor="exampleInputEmail1">
                                                Reject Reason
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                disabled
                                                value={user?.reason}
                                                name="companyName"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <div className="selectdiv form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Finance Type *
                                        </label>
                                        <br />
                                        <select
                                            id="Finance Type"
                                            disabled={CheckKYC()}
                                            name="typeOfFinancing"
                                            value={input.typeOfFinancing}
                                            onChange={handleChange}
                                            className="form-control"
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="risk">
                                                Risk based
                                            </option>
                                        </select>
                                    </div>
                                    <div className="selectdiv form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Timeline *
                                        </label>
                                        <br />
                                        <select
                                            disabled={CheckKYC()}
                                            name="timeLine"
                                            value={input.timeLine}
                                            onChange={handleChange}
                                            className="form-control"
                                        >
                                            <option value="12months">
                                                12 months
                                            </option>
                                            <option value="1-3 years">
                                                1-3 years
                                            </option>
                                            <option value="+3 years">
                                                +3 years
                                            </option>
                                        </select>
                                    </div>

                                    <div className="selectdiv form-group col-5">
                                        <label htmlFor="exampleInputEmail1">
                                            {" "}
                                            Return expected *
                                        </label>
                                        <br />
                                        <select
                                            disabled={CheckKYC()}
                                            name="returns"
                                            value={input.returns}
                                            onChange={handleChange}
                                            className="form-control"
                                        >
                                            <option value="10%">10%</option>
                                            <option value="10-20%">
                                                10-20%
                                            </option>
                                            <option value="+20%">+20%</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Business model *
                                            <span
                                                style={{
                                                    marginLeft: "10px",
                                                    color: "red",
                                                }}
                                            >
                                                {progress?.business ===
                                                    "100%" && "uploaded"}
                                            </span>
                                        </label>
                                        {input.buisnessModel?.url === "" ? (
                                            <input
                                                disabled={CheckKYC()}
                                                type="file"
                                                onChange={(e) =>
                                                    fileUploadHandler(
                                                        e,
                                                        "buisnessModel"
                                                    )
                                                }
                                                className="form-control"
                                                // accept="application/pdf,application/vnd.ms-excel"
                                                aria-describedby="emailHelp"
                                            />
                                        ) : (
                                            <span className="d-flex">
                                                <input
                                                    type="button"
                                                    className="form-control"
                                                    onClick={() =>
                                                        window.open(
                                                            input?.buisnessModel
                                                                ?.url
                                                        )
                                                    }
                                                    aria-describedby="emailHelp"
                                                    disabled
                                                    value="View Buisness Model"
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                    className="d-flex mt-2"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <AiOutlineLink
                                                        title="view document"
                                                        color="red"
                                                        size="24"
                                                        onClick={() =>
                                                            window.open(
                                                                input
                                                                    ?.buisnessModel
                                                                    ?.url
                                                            )
                                                        }
                                                    />
                                                    &nbsp;&nbsp;
                                                    {!CheckKYC() && (
                                                        <AiOutlineCloudUpload
                                                            title="upload document"
                                                            color="red"
                                                            size="24"
                                                            onClick={() =>
                                                                businessRef.current.click()
                                                            }
                                                        />
                                                    )}
                                                </span>
                                            </span>
                                        )}
                                        <input
                                            type="file"
                                            hidden
                                            disabled={CheckKYC()}
                                            ref={businessRef}
                                            onChange={(e) =>
                                                fileUploadHandler(
                                                    e,
                                                    "buisnessModel"
                                                )
                                            }
                                            className="form-control"
                                            // accept="application/pdf,application/vnd.ms-excel"
                                            aria-describedby="emailHelp"
                                        />
                                    </div>

                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Past financials *
                                            <span
                                                style={{
                                                    marginLeft: "10px",
                                                    color: "red",
                                                }}
                                            >
                                                {progress?.financials ===
                                                    "100%" && "uploaded"}
                                            </span>
                                        </label>
                                        {input.valuation?.url === "" ? (
                                            <input
                                                type="file"
                                                disabled={CheckKYC()}
                                                onChange={(e) =>
                                                    fileUploadHandler(
                                                        e,
                                                        "valuation"
                                                    )
                                                }
                                                className="form-control"
                                                // accept="application/pdf,application/vnd.ms-excel"
                                                aria-describedby="emailHelp"
                                            />
                                        ) : (
                                            <span className="d-flex">
                                                <input
                                                    type="button"
                                                    className="form-control"
                                                    aria-describedby="emailHelp"
                                                    disabled
                                                    value="View Past financials"
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                    className="d-flex mt-2"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <AiOutlineLink
                                                        title="view document"
                                                        color="red"
                                                        size="24"
                                                        onClick={() =>
                                                            window.open(
                                                                input?.valuation
                                                                    ?.url
                                                            )
                                                        }
                                                    />
                                                    &nbsp;&nbsp;
                                                    {!CheckKYC() && (
                                                        <AiOutlineCloudUpload
                                                            title="upload document"
                                                            color="red"
                                                            size="24"
                                                            onClick={() =>
                                                                financeUploadRef.current.click()
                                                            }
                                                        />
                                                    )}
                                                </span>
                                            </span>
                                        )}
                                        <input
                                            ref={financeUploadRef}
                                            type="file"
                                            disabled={CheckKYC()}
                                            hidden
                                            onChange={(e) =>
                                                fileUploadHandler(
                                                    e,
                                                    "valuation"
                                                )
                                            }
                                            className="form-control"
                                            // accept="application/pdf,application/vnd.ms-excel"
                                            aria-describedby="emailHelp"
                                        />
                                    </div>

                                    <div className="form-group col-5 mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Financial projections *
                                            <span
                                                style={{
                                                    marginLeft: "10px",
                                                    color: "red",
                                                }}
                                            >
                                                {progress?.projections ===
                                                    "100%" && "uploaded"}
                                            </span>
                                        </label>
                                        {input.financialProjection?.url ===
                                        "" ? (
                                            <input
                                                disabled={CheckKYC()}
                                                type="file"
                                                onChange={(e) =>
                                                    fileUploadHandler(
                                                        e,
                                                        "financialProjection"
                                                    )
                                                }
                                                className="form-control"
                                                // accept="application/pdf,application/vnd.ms-excel"
                                                aria-describedby="emailHelp"
                                            />
                                        ) : (
                                            <span className="d-flex">
                                                <input
                                                    type="button"
                                                    className="form-control"
                                                    aria-describedby="emailHelp"
                                                    disabled
                                                    value="View Financial projections"
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                    className="d-flex mt-2"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <AiOutlineLink
                                                        title="view document"
                                                        color="red"
                                                        size="24"
                                                        onClick={() =>
                                                            window.open(
                                                                input
                                                                    ?.financialProjection
                                                                    ?.url
                                                            )
                                                        }
                                                    />
                                                    &nbsp;&nbsp;
                                                    {!CheckKYC() && (
                                                        <AiOutlineCloudUpload
                                                            title="upload document"
                                                            color="red"
                                                            size="24"
                                                            onClick={() =>
                                                                projectionRef.current.click()
                                                            }
                                                        />
                                                    )}
                                                </span>
                                            </span>
                                        )}
                                        <input
                                            type="file"
                                            hidden
                                            disabled={CheckKYC()}
                                            ref={projectionRef}
                                            onChange={(e) =>
                                                fileUploadHandler(
                                                    e,
                                                    "financialProjection"
                                                )
                                            }
                                            className="form-control"
                                            // accept="application/pdf,application/vnd.ms-excel"
                                            aria-describedby="emailHelp"
                                        />
                                    </div>
                                </>
                            )}
                            {step === 3 && (
                                <div className="d-flex justify-content-center ">
                                    <div className="form-group  mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                            Other Documents *
                                        </label>
                                        {multiple?.length > 0 &&
                                            multiple.map((data, i) => (
                                                <div className="d-flex">
                                                    <input
                                                        key={i}
                                                        type="button"
                                                        value="View Other Documents"
                                                        className="form-control my-3"
                                                        disabled
                                                        aria-describedby="emailHelp"
                                                    />
                                                    &nbsp;
                                                    <span
                                                        className="mt-4"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            window.open(
                                                                data.url
                                                            )
                                                        }
                                                    >
                                                        {" "}
                                                        <AiOutlineLink
                                                            title="view document"
                                                            color="red"
                                                            size="24"
                                                        />
                                                    </span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    {!CheckKYC() && (
                                                        <span
                                                            className="mt-4"
                                                            onClick={() =>
                                                                removeItem(i)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <AiOutlineDelete
                                                                color="red"
                                                                size="24"
                                                                title="remove"
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        <input
                                            type="file"
                                            disabled={CheckKYC()}
                                            hidden={CheckKYC()}
                                            ref={upload}
                                            onChange={(e) =>
                                                fileUploadHandler(e, "other")
                                            }
                                            className="form-control"
                                            // accept="application/pdf,application/vnd.ms-excel"
                                            aria-describedby="emailHelp"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="d-flex justify-content-center mt-5 w-100">
                                {step > 1 && (
                                    <button
                                        onClick={() => setClick("sub")}
                                        type="submit"
                                        className="KYC-next-button mx-2"
                                    >
                                        Previous
                                    </button>
                                )}
                                {step !== 3 && (
                                    <button
                                        type="submit"
                                        onClick={() => setClick("add")}
                                        className="KYC-next-button  mx-2"
                                    >
                                        Next
                                    </button>
                                )}
                                {step === 3 && (
                                    <button
                                        type="button"
                                        disabled={update || CheckKYC()}
                                        style={{
                                            opacity:
                                                update || CheckKYC() ? 0.6 : 1,
                                        }}
                                        onClick={updateProfile}
                                        className="KYC-next-button  mx-2"
                                    >
                                        {CheckKYC()
                                            ? "Submitted"
                                            : update
                                            ? "Loading..."
                                            : "Submit"}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {loader && <Loading />}
        </>
    );
};

export default RProfile;
