import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import dateFormat from "dateformat";
import { type } from "@testing-library/user-event/dist/type";
import SingleVisualReturn from "./SingleVisualReturn";


const VisualizeReturn = (props) => {
  const [data, setData] = useState([]);
  const handleClose = () => props.setOpen(false);
  const Amount = parseInt(props?.Total?.replace(",", "")) / props.Time;
  const getTD = (m) => {
    let date = new Date(props?.date);
    date?.setMonth(date?.getMonth() + m);
    return date;
  };
  console.log(data)
  console.log(props?.data)

  useEffect(() => {
    if (props.date) {
      let item = [];
      for (let i = 1; i <= props.Time + 1; i++) {
        item[i] = dateFormat(getTD(i), "yyyy-mm-dd");
      }
      setData(item);
    }
  }, [props?.date]);



  return (
    <div>
      <Modal show={props.open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="visualize-title">
              <span>
                <HiOutlineCurrencyRupee size="22" />
              </span>
              Visualise Returns
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="visualize-body">
            <Table hover responsive className="text-center">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Tentative Date</th>
                  <th>Expected Amount*</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return <SingleVisualReturn item={item} index={index} length={data?.length} sdate={data[index]} data={props?.data} edate={data[index + 1]} />
                })}


              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              style={{ width: "8rem" }}
              variant="primary"
              className="login-btn btn-ripple"
              onClick={handleClose}
            >
              Invest Now
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisualizeReturn;
