import { AiOutlineArrowDown } from "react-icons/ai";
import "../../styles/banner.css";
import { BannerImage } from "../../assets/banner";
import "aos/dist/aos.css";

const Banner = ({
    Fill,
    firstOne,
    second,
    thirdOne,
    title,
    authorName,
    animation,
    id,
    refs,
}) => {
    return (
      <div ref={second && refs} className="banner_container container">
        {firstOne && (
          <>
            <div className="banner_heading">
              <h1>
                {title} <em>{authorName}</em>
              </h1>
            </div>
            <div className="banner_subHeading">
              <h2>Your access to universal capital and investments</h2>
              <div>
                <div className="home-arrow-loader">
                  <a
                    style={{
                      backgroundColor: `rgba(29, 68, 66, ${Fill})`,
                    }}
                    href={`#${id}`}
                  >
                    <AiOutlineArrowDown
                      size={"25"}
                      style={{
                        color: `${
                          Fill < 0.5
                            ? `rgba(29, 68, 66,${1 - Fill})`
                            : `rgba(255, 255, 255,${Fill})`
                        } `,
                        transform: `${
                          Fill > 0.5 ? "rotate(180deg)" : "rotate(0deg)"
                        }`,
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {second && (
          <>
            <div className="banner_two">
              <h1>
                We are tech a platform for alternate private deals across the
                world
              </h1>
              <div
                className={`banner_two_card_container ${
                  animation && "imageAnimation2"
                }`}
              >
                <div className="banner_two_card first_card">
                  <h6>Invest</h6>
                  <div className="banner_two_icon">
                    <img src={BannerImage.Hand} alt="" />
                  </div>
                  <p>Fixed Return</p>
                  <p>Rentals + Value Upgrade</p>
                  <p>Startup Equity</p>
                </div>
                <div className="banner_two_card secondCard">
                  <h6>Raise</h6>
                  <div className="banner_two_icon">
                    <img src={BannerImage.Iconomi} alt="" />
                  </div>
                  <p>Angel Investment</p>
                  <p>Debt Funding</p>
                  <p>Equity Financing </p>
                </div>
              </div>
            </div>
          </>
        )}
        {thirdOne && (
          <>
            <div className="banner_heading">
              <h1 style={{fontWeight: "bold"}}>
                {title}
              </h1>
            </div>
          </>
        )}
      </div>
    );
};

export default Banner;
