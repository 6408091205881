import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/logo.png";
import "../styles/header.css";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "../styles/signin.css";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Login, RaiserLogin, SocialLogin, SocialRaiserLogin } from "../utils/apis";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { FiUser, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { UserInfo, UserRole } from "../Redux/exportAllAction";
import googleIcon from "../../src/assets/googleicon.png";
import Logo from "../assets/logo.png";
import Vector from "../assets/signin.png";
import Message from "../assets/message.png";
import Signup from "../components/Auth/Signup";
import CustomModal from "./CustomModal";
import { BannerImage } from "../assets/banner";
import ForgotPassword from "../components/Auth/ForgotPassword";
import { auth } from "../utils/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "fitContent",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  border: "none",
  borderRadius: "6px",
};

const Header = ({ openlogin, setOpenlogin }) => {
  const [scrollTop, setscrollTop] = useState("static");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setOpenlogin(false);
  };
  const [forgotscreen, setForgotscreen] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({
    forgotScreen: false,
    otpScreen: false,
    resetScreen: false,
    successScreen: false,
  });

  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const [input, setInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const dispatch = useDispatch();
  const API = check ? RaiserLogin : Login;
  const GoogleApi = check ? SocialRaiserLogin : SocialLogin;
  const handleChange = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };
  const navigate = useNavigate();

  // login using google auth

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      const { displayName, email } = user;
      console.log(displayName, email);
      const { data } = await GoogleApi({
        name: displayName,
        email: email,
      });
      console.log(data);
      dispatch(UserRole(data?.data?.user));
      dispatch(UserRole(data?.role));
      Cookies.set("uniCap", data?.token);
      console.log(data?.token);
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (input.email && input.password) {
      setLoading(true);
      try {
        const { data } = await API({
          email: input.email.trim(),
          password: input.password.trim(),
        });
        data && setLoading(false);
        dispatch(UserRole(data.role));
        dispatch(UserInfo(data.data.user));
        Cookies.set("uniCap", data?.token);
        data.role === "user" ? navigate("/home") : navigate("/my-listing");
      } catch (error) {
        const { response } = error;
        toast.error(response?.data?.message, ToastOptions);
        // alert(response?.data?.message);
        setLoading(false);
      }
    } else {
      toast.error("input field is required", ToastOptions);
    }
  };

  const onscroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    if (winScroll > 648) {
      //setscrollTop(20);
      setscrollTop("sticky");
    } else {
      setscrollTop("static");
    }
  };
  useEffect(() => {
    document.documentElement.style.setProperty("--primary", "#1d4442");
    window.addEventListener("scroll", onscroll);

    return () => window.removeEventListener("scroll", onscroll);
  }, []);

  const forgotModalOpen = () => {
    setForgotscreen(true);
    setForgotPassword({
      forgotScreen: true,
      otpScreen: false,
      resetScreen: false,
      successScreen: false,
    });
    setIsSignUp(false);
  };

  useMemo(() => {
    if (openlogin) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openlogin]);

  console.log(openlogin);

  return (
    <>
      {" "}
      <header
        className="header-wrapper  container-fluid"
        style={{ position: "sticky", top: 0, zIndex: 100048000 }}
      >
        <div className="align-items-center header d-flex justify-content-between container  py-1 py-md-2">
          <HashLink to={"/#tech"}>About Us</HashLink>
          <Link to={"/"}>
            <img src={logo} width="100px" alt="unicap-logo" />
          </Link>
          {/* <Link to={"/signin"}> */}
          <button onClick={handleOpen} className="header-login-btn">
            Login
          </button>
          {/* </Link> */}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 999999999999999999 }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {isSignUp ? (
              <Signup handleClose={handleClose} setIsSignUp={setIsSignUp} />
            ) : forgotscreen ? (
              <ForgotPassword
                setForgotPassword={setForgotPassword}
                forgotPassword={forgotPassword}
                handleClose={handleClose}
                setForgotscreen={setForgotscreen}
              />
            ) : (
              <div className="signin-main">
                <div className="my-1 modalLogo" onClick={() => navigate("/")}>
                  <h1>
                    <span>uni</span> cap
                  </h1>
                  <div className="closeIcon">
                    <HighlightOffIcon className="close" onClick={handleClose} />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <img src={Vector} alt="vector" width="90%" />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <h3>Login</h3>
                    <Form className="form-main" onSubmit={submitForm}>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <FiUser size="20" color="#c4c4c4" />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Email"
                          type="text"
                          aria-label="Username"
                          disabled={loading ? true : false}
                          name="email"
                          value={input.email}
                          onChange={handleChange}
                          className="basic-addon2"
                          required
                        />
                      </InputGroup>
                      <InputGroup className="mb-0">
                        <InputGroup.Text id="basic-addon1">
                          <FiLock size="20" color="#c4c4c4" />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Password"
                          aria-label="Password"
                          type={show ? "text" : "password"}
                          aria-describedby="basic-addon1"
                          name="password"
                          disabled={loading ? true : false}
                          value={input.password}
                          onChange={handleChange}
                          required
                          id="basic-addon3"
                        />
                        <InputGroup.Text
                          className="basic-addon2"
                          onClick={() =>
                            show ? setShow(false) : setShow(true)
                          }
                        >
                          {show ? (
                            <FiEyeOff
                              size="20"
                              color="#c4c4c4"
                              title="hide"
                              cursor="pointer"
                            />
                          ) : (
                            <FiEye
                              size="20"
                              color="#c4c4c4"
                              title="show"
                              cursor="pointer"
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <div className="d-flex justify-content-start flex-column flex-md-row justify-content-md-between gap-2 gap-md-0">
                        <small>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Login as Fund-Raiser"
                            checked={check}
                            value={check}
                            onChange={() =>
                              check ? setCheck(false) : setCheck(true)
                            }
                          />
                        </small>
                        <small
                          onClick={forgotModalOpen}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Forgot Password ?
                        </small>
                      </div>
                      <InputGroup className="my-4">
                        <button
                          type="submit"
                          disabled={loading ? true : false}
                          className="login-btn btn-ripple"
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              &nbsp; Loading...
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </InputGroup>
                        <button
                          onClick={signUpWithGoogle}
                          className="google-signin-button login-btn btn-ripple"
                        >
                          <img
                            className="google-icon"
                            src={googleIcon}
                            alt="google icon"
                          />{" "}
                          Signup with Google
                        </button>
                      <h5>
                        Don't Have An Account?{" "}
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setIsSignUp(true)}
                        >
                          Sign up
                        </span>
                      </h5>
                      <h5> OR</h5>
                      <h5>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/home")}
                        >
                          Continue{" "}
                        </span>
                        as a Guest
                      </h5>
                    </Form>
                  </div>
                </div>
                {/* <div className="m-box">
                                    <img src={Message} alt="box" width="100%" />
                                </div> */}
              </div>
            )}
          </Box>
        </Modal>
        {/* <CustomModal open={open} hide={handleClose}>
                    <div className="connectModal">
                        <div className="closeIcon">
                            <HighlightOffIcon className="close" onClick={handleClose} />
                        </div>
                        <h2>Let’s Connect</h2>
                        <p>
                            We are working hard to create an amazing user
                            experience for you.
                            <br /> Be the first to know and become a part of our
                            community.
                        </p>
                        <div className="connectModal_footer">
                            <input
                                placeholder="Enter email address here"
                                type="text"
                            />
                            <button>Submit</button>
                        </div>
                    </div>
                </CustomModal> */}
      </header>
    </>
  );
};

export default Header;
