import ForgotPassword from "./forgotPassword.png";
import ResetPassword from "./resetPassword.png";
import OTP from "./otp.png";
import successful from "./succesful.png";
import GooglePay from "./googlepay.png";
import Amazon from "./amazon.png";
import Phonepe from "./phonepay.png";
import Paytm from "./paytm.png";
import bankIcon from "./bankIcon.png";
import cardIcon from "./cardIcon.png";
import bellIcon from "./bell.png";
import spider from "./spider.png";
import minion from "./minion.png";
import companyIocn from "./companyIcon.png";
import Paypal from "./paypal.png";
import MasterCard from "./masterCard.png";
import VisaCard from "./visaCard.png";
import Stripe from "./stripe.png";
import ApplePay from "./applePay.png";
import PaymentAccept from "./High.png";
import PaymentFail from "./Sorry.png";

export const Images = {
    ForgotPassword,
    ResetPassword,
    OTP,
    successful,
    GooglePay,
    Phonepe,
    Paytm,
    Amazon,
    bankIcon,
    cardIcon,
    bellIcon,
    spider,
    minion,
    companyIocn,
    Paypal,
    MasterCard,
    VisaCard,
    Stripe,
    ApplePay,
    PaymentAccept,
    PaymentFail,
};
