import React, { useEffect, useState, useRef } from "react";
import {
    IoArrowBackSharp,
    IoChevronForwardSharp,
    IoChevronBackSharp,
} from "react-icons/io5";
import { HiFilter } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "../../../styles/portfolio.css";
import { toast } from "react-toastify";
// import CardLogo from "../../../assets/cardLogo.png";
import Graph from "../../../assets/graph.png";
import dateFormat from "dateformat";
import { AllOrders, OrderByID } from "../../../utils/apis";
import Loading from "../../../common/Loading";
import BarCharts from "../../../common/BarCharts";
const Portfolio = () => {
    const scrollBy = useRef();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState([]);
    console.log({ detail });
    const [orderId, setOrderId] = useState("");
    const [select, setSelect] = useState("all");
    const [chartData1, setChartData1] = useState([]);
    const navigate = useNavigate();
    const ToastOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    };
    const GetListings = async () => {
        setLoading(true);
        try {
            const { data } = await AllOrders();
            setData(data.result);
            setSelect("list")
            setOrderId(data.result[0].listingId._id);
            setLoading(false);
        } catch (error) {
            const { response } = error;
            console.log(response);
            setLoading(false);
        }
    };
    console.log(detail)
    let chartData = [];
    const ListingDetail = async () => {
        let id = select === "all" ? `type=1y` : `listingId=${orderId}&type=1y`;
        try {
            setLoading(true);
            const { data } = await OrderByID(id);
            setDetail(data);
            const allInvestments = data.order.reduce((acc, item) => {
                return acc.concat(item.investments);
            }, []);
            setChartData1(allInvestments);
        } catch (error) {
            const { response } = error;
            console.log(response);
            toast.warning(response.data.message, ToastOptions);
        } finally {
            setLoading(false);
        }
    };
    console.log({ chartData1 });
    useEffect(() => {
        GetListings();
    }, []);
    useEffect(() => {
        if (orderId && select === "list") {
            setSelect("list");
            ListingDetail();
        } else {
            setSelect("all");
            // ListingDetail();
            GetListings();
        }
    }, [orderId, select]);

    function leftScroll() {
        scrollBy.current.scrollBy(-400, 0);
    }
    function rightScroll() {
        scrollBy.current.scrollBy(400, 0);
    }
    const portfolioData =
        detail?.order &&
        detail?.order?.reduce((acc, item) => {
            chartData.push(acc.investments);
            return acc;
            // return data.investments?.map;
        });

    const Card = ({ list }) => {
        const { listingId } = list;
        return (
            <>
                <div
                    className={
                        orderId === listingId._id
                            ? "card card-selected "
                            : "card"
                    }
                    onClick={() => {
                        setSelect("list");
                        setOrderId(listingId._id);
                    }}
                >
                    <div className="d-flex justify-content-between w-100">
                        <div style={{ width: "60px" }}>
                            <img
                                src={listingId?.companyLogo?.url}
                                alt="card"
                                width="100%"
                            />
                        </div>
                        <h5>{listingId.category}</h5>
                    </div>
                    <h4> {listingId.companyName}</h4>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h6>Invested</h6>
                            <h6>₹ {list.total}</h6>
                            {/* <p>+7657</p> */}
                        </div>
                        <div className="mt-5">
                            <img src={Graph} alt="graph" />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className="portfolio-main">
            {data.length > 0 ? (
                <>
                    <div className="d-flex justify-content-between">
                        <h3 className="title" onClick={() => navigate("/home")}>
                            <IoArrowBackSharp size="24" />
                            &nbsp; My Portfolio
                        </h3>
                        <div className="filter-box">
                            All <HiFilter size="24" color="var(--primary)" />
                        </div>
                    </div>
                    <div className="scroller-card">
                        <div className="prev-btn" onClick={leftScroll}>
                            <IoChevronBackSharp />
                        </div>
                        <div className="card-main" ref={scrollBy}>
                            {data.map((e) => {
                                return <Card list={e} key={e._id} />;
                            })}
                        </div>
                        <div className="next-btn" onClick={rightScroll}>
                            <IoChevronForwardSharp />
                        </div>
                    </div>
                    <div className="select-switch">
                        <button
                            className={select === "all" ? "active" : ""}
                            onClick={() => {
                                setSelect("all");
                            }}
                        >
                            All Transaction
                        </button>
                        {/* &nbsp; */}
                        {/* <button
              className={select === "list" ? "active" : ""}
              onClick={() => setSelect("list")}
            >
              Listed Company
            </button> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="finance-card-details">
                                <div className="first-section">
                                    <div
                                        style={{
                                            width: "60px",
                                            display: "flex",
                                        }}
                                    >
                                        {select === "list" && (
                                            <img
                                                src={
                                                    detail?.listing?.companyLogo
                                                        ?.url
                                                }
                                                alt="card"
                                                width="100%"
                                            />
                                        )}
                                        <h4 className="m-3 text-nowrap ">
                                            {select === "all"
                                                ? "All Transactions"
                                                : detail?.listing?.companyName}
                                        </h4>
                                    </div>
                                    {/* <div className="filters">
                <span>1d</span>
                <span>5d</span>
                <span>1m</span>
                <span className="active">6m</span>
                <span>1y</span>
                <span>All</span>
              </div> */}
                                </div>
                                <BarCharts Sales={detail?.result} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="balances">
                                <div>
                                    <h3>Total Invested</h3>
                                    <h4>₹ {detail?.totalAmount}</h4>
                                </div>

                                <div>
                                    <h3>Total Returns</h3>
                                    <h5>
                                        ₹ {detail?.totalReturns}
                                        <br />
                                        <small>{detail?.avgReturns} % </small>
                                    </h5>
                                </div>
                            </div>
                            <div className="balances">
                                <div className="justify-content-center">
                                    <h3>Invested Amount</h3>
                                    {/* <h4>₹ {detail?.order?.total}</h4> */}
                                </div>
                                {chartData1?.length > 0
                                    ? chartData1
                                        ?.slice(0, 4)
                                        ?.map((item, id) => {
                                            return (
                                                <div key={item._id}>
                                                    <h3>
                                                        Order -{" "}
                                                        {item._id
                                                            .slice(-6)
                                                            .toUpperCase()}
                                                    </h3>
                                                    <h5>
                                                        ₹ {item?.amount}
                                                        <br />
                                                        <small>
                                                            {dateFormat(
                                                                item?.paymentDate,
                                                                "dd-mm-yyyy"
                                                            )}
                                                        </small>
                                                    </h5>
                                                </div>
                                            );
                                        })
                                    : "No order present !"}
                                <div
                                    className="d-flex justify-content-end cursor links"
                                    onClick={() => navigate("/my-transactions")}
                                >
                                    View All
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className=" vh-100 d-flex justify-content-center mt-5">
                    <h2 className="mt-5">No order present !!</h2>
                </div>
            )}
            {loading && <Loading />}
        </div>
    );
};

export default Portfolio;
