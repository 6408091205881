import { useCallback } from "react";
import { useState } from "react";

export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);
    useCallback(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry?.isIntersecting);
            },
            { rootMargin }
        );

        element?.current && observer?.observe(element?.current);

        return () => observer?.unobserve(element?.current);
    }, [element, rootMargin]);

    return isVisible;
};
