import { Images } from "../../../assets";

export const CardInfo = [
    {
        id: 1,
        image: Images.VisaCard,
        name: "Visa ending in 1234",
        cardNumber: "1234 5678 9012 3456",
        expiry: "12/2024",
    },
    {
        id: 2,
        image: Images.MasterCard,
        name: "MasterCard ending in 1234",
        cardNumber: "1234 5678 9012 3456",
        expiry: "12/2024",
    },
    {
        id: 3,
        image: Images.ApplePay,
        name: "Apple Pay ending in 1234",
        cardNumber: "1234 5678 9012 3456",
        expiry: "12/2024",
    },
    {
        id: 4,
        image: Images.Stripe,
        name: "Stripe (Visa ending 1234)",
        cardNumber: "1234 5678 9012 3456",
        expiry: "12/2024",
    },
    {
        id: 5,
        image: Images.Paypal,
        name: "Paypal ending in 1234",
        cardNumber: "1234 5678 9012 3456",
        expiry: "12/2024",
    },
];
