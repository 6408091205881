export const ModalQuestion = [
    [
        {
            label: " Generate fixed income (Through rentals)",
            value: "Generate fixed income (Through rentals)",
        },
        {
            label: "Grow wealth (Through new ideas)",
            value: "Grow wealth (Through new ideas)",
        },
        {
            label: "Preserve capital (Through lending)",
            value: "Preserve capital (Through lending)",
        },
    ],
    [
        {
            label: "Short-term (Maximum 1 year)",
            value: "Short-term (Maximum 1 year)",
        },
        {
            label: "Medium-term (1-5 years)",
            value: "Medium-term (1-5 years)",
        },
        {
            label: "Long-term (More than 5 years)",
            value: "Long-term (More than 5 years)",
        },
    ],
    [
        {
            label: "Low (Ex: High quality corporate bonds, REITs)",
            value: "Low (Ex: High quality corporate bonds, REITs)",
        },
        {
            label: "Medium (Ex: Pre-leased rentals, Private equity fund)",
            value: "Medium (Ex: Pre-leased rentals, Private equity fund)",
        },
        {
            label: "High (Ex: Startups, Venture capital)",
            value: "High (Ex: Startups, Venture capital)",
        },
    ],
    [
        {
            label: "None",
            value: "None",
        },
        {
            label: "Invested few times in shares",
            value: "Invested few times in shares",
        },
        {
            label: "Regularly investing",
            value: "Regularly investing",
        },
    ],
    [
        {
            label: "Not important",
            value: "Not important",
        },
        {
            label: "Should not have negative impact",
            value: "Should not have negative impact",
        },
        {
            label: "Very important",
            value: "Very important",
        },
    ],

    [
        {
            label: "Daily",
            value: "Daily",
        },
        {
            label: "Weekly",
            value: "Weekly",
        },
        {
            label: "Monthly",
            value: "Monthly",
        },
    ],
    [
        {
            label: "Not important",
            value: "Not important",
        },
        {
            label: "Like to know the summary points",
            value: "Like to know the summary points",
        },
        {
            label: "Conduct detailed analysis",
            value: "Conduct detailed analysis",
        },
    ],
];
