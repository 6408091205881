import { Images } from "../assets";

export const UserData = [
    {
        id: 1,
        image: Images.bankIcon,
        isRead: false,
        title: "Congrats! you Earn Rs. 50,000",
        subHeading: "By referring your friend",
        time: "2 hours ago",
    },
    {
        id: 2,
        image: Images.bankIcon,
        isRead: true,
        title: "Congrats! you Earn Rs. 2,000",
        subHeading: "By referring your friend",
        time: "2 hours ago",
    },
    {
        id: 3,
        image: Images.cardIcon,
        isRead: false,
        title: "KYC Pending",
        subHeading: "Complete your KYC in less then 1 min",
        time: "3 hours ago",
    },
    {
        id: 4,
        image: Images.cardIcon,
        isRead: true,
        title: "KYC is completed now",
        subHeading: "Now you can do bank transactions",
        time: "4 hours ago",
    },
    {
        id: 5,
        image: Images.cardIcon,
        isRead: false,
        title: "KYC Pending",
        subHeading: "Complete your KYC in less then 1 min",
        time: "4 hours ago",
    },
    {
        id: 6,
        image: Images.cardIcon,
        isRead: true,
        title: "KYC is completed now",
        subHeading: "Now you can do bank transactions",
        time: "4 hours ago",
    },
];
export const RaiserData = [
    {
        id: 1,
        image: Images.companyIocn,
        isRead: false,
        title: "Kylo Apps listing has been Approved",
        subHeading: "Now anyone can see your listing",
        time: "2 hours ago",
    },
    {
        id: 2,
        image: Images.minion,
        isRead: true,
        title: "XYZ listing has been Rejected",
        subHeading: "No one can see your listing",
        time: "2 hours ago",
    },
    {
        id: 3,
        image: Images.spider,
        isRead: false,
        title: "Kylo Apps listing has been Approved",
        subHeading: "Now anyone can see your listing",
        time: "3 hours ago",
    },
    {
        id: 4,
        image: Images.companyIocn,
        isRead: true,
        title: "XYZ listing has been Rejected",
        subHeading: "No one can see your listing",
        time: "4 hours ago",
    },
    {
        id: 5,
        image: Images.minion,
        isRead: false,
        title: "Kylo Apps listing has been Approved",
        subHeading: "Now anyone can see your listing",
        time: "4 hours ago",
    },
    {
        id: 6,
        image: Images.spider,
        isRead: true,
        title: "XYZ listing has been Rejected",
        subHeading: "No one can see your listing",
        time: "4 hours ago",
    },
];
