import React, { useRef } from 'react'
import Banner from '../Home/Banner';
import { BannerImage } from '../../assets/banner';
import Service from '../Home/Service';
import "aos/dist/aos.css";
import "../../styles/home.css";
import { useEffect } from 'react';
import { useState } from 'react';
import Footer from '../../common/Footer';

const JoinUs = () => {
    const ref = useRef();
      const [scrollOff, setScrollOff] = useState(false);
        const [animation, setanimation] = useState(false);

        function handleIntersection(entries) {
          entries.forEach((entry) => {
            if (
              entry.isIntersecting &&
              entry.target.className === "banner_container container"
            ) {
              setanimation(true);
            } else if (
              entry.isIntersecting &&
              entry.target.className === "home-third-section"
            ) {
              setScrollOff(true);
            } else {
              setScrollOff(false);
              setanimation(false);
            }
          });
        }

      useEffect(() => {
        if (scrollOff) {
          const section = document.getElementsByTagName("section");
          for (let i = 0; i < section.length; i++) {
            section[i].style.scrollSnapAlign = "none";
          }
        } else {
          const section = document.getElementsByTagName("section");
          for (let i = 0; i < section.length; i++) {
            section[i].style.scrollSnapAlign = "start";
          }
        }
      }, [scrollOff]);
  return (
    <article
      ref={ref}
      className="home-wrapper scroll-container"
      style={{ height: "100vh" }}
      id="main11"
    >
      {/* <section className="home-top-section-pointOne home-top-section-pointOne2 s1">
        <Banner id="tech" title="Join Us" thirdOne Fill={0} />
        <div className="home_top_left_vector">
          <img src={BannerImage.RightVetorOne} alt="" />
        </div>
        <div className="home_top_left_vector_phone">
          <img src={BannerImage.RightVetorOnePhone} alt="" />
        </div>
        <div className="bigRound" />
        <div className="smallRound" />
        <div className="home_top_right_vector">
          <img src={BannerImage.LeftVetorOne} alt="" />
        </div>
        <div className="home_top_right_vector_phone">
          <img src={BannerImage.LeftVetorOnePhone} alt="" />
        </div>
      </section> */}

      <section className="home-second-section">
        <h5 className='joinus-header-text'>Join Us</h5>
        <Service third />
      </section>
        {/* <Footer /> */}
    </article>
  );
}

export default JoinUs;