import React, { useState, useEffect } from "react";
import { IoArrowBackSharp, IoChevronDownOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "../../../styles/wallet.css";
import { Table } from "react-bootstrap";
import Loading from "../../../common/Loading";
import { MyTransactions } from "../../../utils/apis";
import dateFormat from "dateformat";
import WalletModal from "./WalletModal";
const Wallet = () => {
    const navigate = useNavigate();
    const [modalTitle, setModalTitle] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const GetTransactions = async () => {
        setLoading(true);
        try {
            const { data } = await MyTransactions();
            console.log(data.result)
            setData(data.result);
            setLoading(false);
        } catch (error) {
            const { response } = error;
            console.log(response);
            setLoading(false);
        }
    };
    useEffect(() => {
        GetTransactions();
    }, []);
    const handleModal = (title) => {
        setModalTitle(title);
        setModalOpen(true);
    };

    return (
        <div style={{ minHeight: "100vh" }} className="wallet-main container">
            <h3 className="title">
                <IoArrowBackSharp onClick={() => navigate("/home")} size="24" />
                &nbsp; My Transactions
            </h3>
            {/* <div className="balance-box">
                <div className="d-flex justify-content-between">
                    <div className="balance-info">
                        <h3>₹ {data?.balance||0}</h3>
                        <h4>Total Balance</h4>
                    </div>
                    <div>
                        <div>
                            <button
                                onClick={() => handleModal("Add Money")}
                                type="button"
                                className="add-btn btn-ripple"
                            >
                                Add Money
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => handleModal("Withdraw")}
                                type="button"
                                className="withdraw-btn btn-ripple"
                            >
                                Withdraw
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="sub-heading">
                {/* <h4></h4> */}
                <h5>All Transaction Details</h5>
                <select name="" id="">
                    <option value="Last Week">Last Week</option>
                    <option value="Last Month">Last Month</option>
                    <option value="Last Year">Last Year</option>
                </select>
            </div>

            <Table className="history-box" hover responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Transactions</th>
                    </tr>
                </thead>
                {data?.map((order) => {
                    return (
                        <tbody>
                            <tr>
                                <td>
                                    {dateFormat(
                                        order?.updatedAt,
                                        "dS, mmmm yyyy"
                                    )}
                                </td>
                                <td style={{ color: "green" }}>
                                    ₹ {order?.total}
                                </td>
                                <td>
                                    Invested in {order?.listingId?.category}{" "}
                                    bonds of {order?.listingId?.companyName}
                                </td>
                            </tr>
                        </tbody>
                    );
                })}
            </Table>
            <WalletModal
                open={modalOpen}
                modalTitle={modalTitle}
                hide={() => setModalOpen(!modalOpen)}
            />

            <h6 onClick={() => handleModal("Download Transaction History")}>Download Transaction History</h6>
            {loading && <Loading />}
        </div>
    );
};

export default Wallet;
