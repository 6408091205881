import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import UserHeader from "../../../common/UserHeader";
import {
  AiOutlineArrowLeft,
  AiOutlineDelete,
  AiOutlineArrowRight,
  AiOutlineWallet,
} from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  BANK_TAB,
  DEMAT_TAB,
  KYC_TAB,
  NOMINEE_TAB,
  PERSONAL_TAB,
} from "./types";
import { storage } from "../../../utils/firebase";
import { UpdateProfile } from "../../../utils/apis";
import img1 from "../../../assets/pic1.png";
import "../../../styles/profile.css";
import { UserInfo } from "../../../Redux/exportAllAction";
import KYC from "./KYC";
import Loading from "../../../common/Loading";

function Profile() {
  const navigate = useNavigate();
  const [KYCState, setKYCState] = useState(0);
  const UploadInput = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [DematACState, setDematACState] = useState(0);
  const [update, setUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [NomineeState, setNomineeState] = useState(0);
  const [progress, setProgress] = useState("100%");
  const [NomineeData, setNomineeData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(PERSONAL_TAB);
  const [editDemit, setEditDemit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [input, setInput] = useState({
    name: "",
    image: "",
    phoneNumber: "",
  });
  const [ninput, setNInput] = useState({
    name: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
  });
  const [pancard, setPancard] = useState({
    name: "",
    number: "",
    img: "",
    dateOfBirth: "",
  });
  const [aadharcard, setAddharCard] = useState({
    residentialAddress: "",
    pincode: "", //number
    city: "",
    state: "",
    img: "",
    number: "",
    name: "",
  });
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    accountNumber: "", //number
    ifsc: "",
    branch: "",
    cancelledCheque: "",
  });
  const [demantInfo, setDemantInfo] = useState({
    dematId: "",
    dpId: "", //number
    depositoryParticipation: "",
    depository: "",
  });
  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };
  // demat info
  const handleDemitChange = (e) => {
    const { name } = e.target;
    setDemantInfo({ ...demantInfo, [name]: e.target.value });
  };

  const changeState = (name) => {
    if (name == "KYCState") {
      setKYCState(1);
    } else if (name == "DematACState") {
      setDematACState(1);
    } else {
      setNomineeState(1);
    }
  };

  const addNominee = (e) => {
    console.log(ninput);
    if (
      ninput.name &&
      ninput.phoneNumber &&
      ninput.dateOfBirth &&
      ninput.address
    ) {
      e.preventDefault();
      setNomineeData([...NomineeData, ninput]);
      setNInput({
        name: "",
        phoneNumber: "",
        dateOfBirth: "",
        address: "",
      });
    } else {
      toast.error("input field is required", ToastOptions);
    }
  };

  const RemoveNominee = (index) => {
    let data = [...NomineeData];
    data.splice(index, 1);
    setNomineeData(data);
  };
  function fileUploadHandler() {
    const file = UploadInput.current.files[0];
    if (!file) return;
    setLoader(true);
    const storageRef = ref(storage, `files/${file.name}`);
    setProgress("0%");
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = `${Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )}%`;
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInput({ ...input, image: downloadURL });
          setLoader(false);
        });
      }
    );
  }

  const updateDemitChanges = async () => {
    if (!editDemit) setEditDemit(true);
    else {
      if (
        demantInfo.dematId &&
        demantInfo.dpId &&
        demantInfo.depository &&
        demantInfo.depositoryParticipation
      ) {
        try {
          setEditDemit(false);
          const { data } = await UpdateProfile({
            demitInfo: demantInfo,
          });
          console.log("***", data);
          dispatch(UserInfo(data.result));
          toast.success("Profile updated", ToastOptions);
        } catch (error) {
          toast.error(error.message, ToastOptions);
        }
      } else {
        toast.error("Demat field can't be empty", ToastOptions);
      }
    }
  };
  const updateChanges = () => {
    if (!edit) setEdit(true);
    else {
      setLoader(true);
      if (input.name) {
        setEdit(false);
        updateProfile();
        toast.success("Profile updated", ToastOptions);
        setLoader(false);
      } else {
        toast.error("Name field can't be empty", ToastOptions);
      }
    }
  };
  async function updateProfile() {
    setLoader(true);
    try {
      const { data } = await UpdateProfile({
        name: input?.name,
        profile: input?.image,
        phoneNumber: input?.phoneNumber,
      });

      dispatch(UserInfo(data.result));
      setLoader(false);
    } catch (error) {
      toast.error(error.message, ToastOptions);
      // console.log(response);
      setLoader(false);
    }
  }

  const saveNominee = async () => {
    console.log(NomineeData);
    if (NomineeData.length === 0) {
      toast.warning("Nominee data cannot be empty", ToastOptions);
    } else {
      setUpdate(true);
      try {
        const { data } = await UpdateProfile({
          nominee: NomineeData,
        });
        toast.success("Saved successfully", ToastOptions);
        dispatch(UserInfo(data.result));
        setSelectedTab(PERSONAL_TAB);
        setUpdate(false);
      } catch (error) {
        const { response } = error;
        //console.log(response);
        setUpdate(false);
      }
    }
  };

  useEffect(() => {
    setInput({
      name: user?.name,
      image: user?.profile,
      phoneNumber: user?.phoneNumber,
    });
    let pancard = user.kycDetails?.pancard;
    let aadharCard = user.kycDetails?.aadharCard;
    let bankDetails = user.kycDetails?.bankDetails;
    let demitInfo = user.demitInfo;

    setPancard({
      name: pancard.name,
      number: pancard.number,
      img: pancard.img,
      dateOfBirth: pancard.dateOfBirth,
    });

    setAddharCard({
      residentialAddress: aadharCard.residentialAddress,
      pincode: aadharCard.pincode === 0 ? "" : aadharCard.pincode,
      city: aadharCard.city,
      state: aadharCard.state,
      img: aadharCard.img,
      number: aadharCard.number,
      name: aadharCard.name,
    });
    setBankDetails({
      accountHolderName: bankDetails.accountHolderName,
      accountNumber: bankDetails.accountNumber, //number
      ifsc: bankDetails.ifsc,
      branch: bankDetails.branch,
      cancelledCheque: bankDetails.cancelledCheque,
    });

    setDemantInfo({
      dematId: demitInfo?.dematId,
      dpId: demitInfo?.dpId,
      depository: demitInfo?.depository,
      depositoryParticipation: demitInfo?.depositoryParticipation,
    });
    setNomineeData(user?.nominee);
    if (user?.kycStatus !== null) {
      setKYCState(1);
    }
    if (user?.nominee?.length > 0) {
      setNomineeState(1);
    }
    if (user?.demitInfo?.dematId !== "") {
      setDematACState(1);
    }
  }, [user]);

  console.log(input);

  return (
    <>
      <section className="profile-wrapper container h-100">
        {/* Navigation */}

        <div className="profile-navigate pt-3">
          <div className="d-flex align-items-center">
            <AiOutlineArrowLeft style={{
              cursor: "pointer"
            }} onClick={() => navigate("/home")} className="me-5 " size="25" />
            <h3>My Profile</h3>
          </div>
          <nav className="d-flex justify-content-center ">
            <ul className="col-10 d-flex py-1 my-3 profile-navigate-list justify-content-between">
              <li>
                <a
                  onClick={() => {
                    if (selectedTab !== PERSONAL_TAB)
                      setSelectedTab(PERSONAL_TAB);
                  }}
                  className={`${selectedTab === PERSONAL_TAB &&
                    "profile-navigate-list-selected"
                    }`}
                >
                  Personal Information
                </a>{" "}
              </li>
              <li>
                <a
                  onClick={() => {
                    if (selectedTab !== KYC_TAB) setSelectedTab(KYC_TAB);
                  }}
                  className={`${selectedTab === KYC_TAB && "profile-navigate-list-selected"
                    }`}
                >
                  KYC
                </a>
              </li>
              {/* <li>
                                <a
                                    onClick={() => {
                                        if (selectedTab !== BANK_TAB)
                                            setSelectedTab(BANK_TAB);
                                    }}
                                    className={`${
                                        selectedTab === BANK_TAB &&
                                        "profile-navigate-list-selected"
                                    }`}
                                >
                                    Bank Details
                                </a>
                            </li> */}
              <li>
                <a
                  onClick={() => {
                    if (selectedTab !== DEMAT_TAB) setSelectedTab(DEMAT_TAB);
                  }}
                  className={`${selectedTab === DEMAT_TAB &&
                    "profile-navigate-list-selected"
                    }`}
                >
                  Demat A/C Details
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    if (selectedTab !== NOMINEE_TAB)
                      setSelectedTab(NOMINEE_TAB);
                  }}
                  className={`${selectedTab === NOMINEE_TAB &&
                    "profile-navigate-list-selected"
                    }`}
                >
                  Add/Change Nominee
                </a>
              </li>
            </ul>
          </nav>
        </div>

        {/* Personal Info */}

        {selectedTab === PERSONAL_TAB ? (
          <div className="profile-personal mb-5" id="personal-Info">
            {/* <h4 className="py-3">Personal Information</h4> */}
            <div className="profile-box p-5">
              <div
                className="profile-personal-left col-3 d-c-flex flex-column self-center"
                style={{ cursor: "pointer" }}
                onClick={() => UploadInput.current.click()}
              >
                {edit && (
                  <h6 style={{ color: "var(--primary)" }}>
                    {progress === "100%" ? "Click To Upload Image" : progress}
                  </h6>
                )}
                <img
                  src={input.image || user?.profile || img1}
                  alt="User-image"
                />
                <input
                  ref={UploadInput}
                  type="file"
                  hidden
                  disabled={edit ? false : true}
                  onChange={fileUploadHandler}
                />
                <h5>{user?.name}</h5>
                {/* <small>
                  <AiOutlineWallet /> {user?.amount}
                </small> */}
              </div>

              <div className="profile-personal-right col-9 ">
                <div className="d-flex mb-2 justify-content-end">
                  <span style={{ cursor: "pointer" }} onClick={updateChanges}>
                    {edit ? (
                      <button className="profile-save-btn">Update</button>
                    ) : (
                      <FiEdit2 title="edit" size="20" color="var(--primary)" />
                    )}
                  </span>
                </div>
                <form className="d-flex flex-wrap justify-content-between">
                  <div className="form-group col-5 mb-4">
                    <label htmlFor="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      disabled={edit ? false : true}
                      className="form-control"
                      aria-describedby="emailHelp"
                      value={input?.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-5">
                    <label htmlFor="exampleInputEmail1">Pan Number</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      disabled
                      value={user?.kycDetails?.pancard?.number}
                    />
                  </div>

                  <div className="form-group col-5">
                    <label htmlFor="exampleInputEmail1">Email </label>
                    <input
                      type="email"
                      className="form-control"
                      disabled
                      aria-describedby="emailHelp"
                      value={user?.email}
                    />
                  </div>

                  <div className="form-group col-5 mb-4">
                    <label htmlFor="exampleInputEmail1">KYC </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        user?.kycStatus === null
                          ? "Incomplete"
                          : user?.kycStatus === "rejected"
                            ? "Rejected ❌"
                            : user?.kycStatus === "approved"
                              ? "Approved ✅"
                              : "Under review"
                      }
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div className="form-group col-5 mb-4">
                    <label htmlFor="exampleInputEmail1">Phone Number </label>
                    <input
                      type="tel"
                      className="form-control"
                      min={0}
                      disabled
                      value={input?.phoneNumber}
                      name="phoneNumber"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div className="form-group col-5 mb-4">
                    <label htmlFor="exampleInputEmail1">Nominee Name </label>
                    <input
                      type="text"
                      disabled
                      value={user?.nominee[0]?.name}
                      className="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  {user?.reason !== "" && (
                    <div className="form-group col-12">
                      <label htmlFor="exampleInputEmail1">
                        Rejection Reason
                      </label>
                      <input
                        type="text"
                        disabled
                        value={user?.reason}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        ) : null}

        {/* KYC */}

        {selectedTab === KYC_TAB ? (
          <div className="profile-KYC mb-5" id="KYC">
            {/* <h4 className="pb-3">KYC</h4> */}
            <div className="profile-box profile-inner p-5 mb-5 d-c-flex flex-column">
              {KYCState == 0 ? (
                <>
                  <h3>Complete Your KYC</h3>
                  <h5>in less than 1 min!</h5>
                  <button
                    onClick={() => changeState("KYCState")}
                    className="common-btn"
                  >
                    Next <AiOutlineArrowRight />
                  </button>
                </>
              ) : (
                <>
                  <KYC
                    setSelectedTab={setSelectedTab}
                    pancard={pancard}
                    setPancard={setPancard}
                    aadharcard={aadharcard}
                    setAddharCard={setAddharCard}
                    bankDetails={bankDetails}
                    setBankDetails={setBankDetails}
                    setLoader={setLoader}
                  />
                </>
              )}
            </div>
          </div>
        ) : null}

        {/* Demant A/C Details  */}

        {selectedTab === DEMAT_TAB ? (
          <div className="profile-bank mb-5" id="demant-details">
            {/* <h4 className="py-3">Demat A/C Details</h4> */}
            <div className="profile-box profile-inner p-5 d-c-flex flex-column">
              {DematACState === 0 ? (
                <>
                  <h3>Complete Your Demant Account </h3>
                  <h5>in less than 1 min!</h5>
                  <button
                    onClick={() => changeState("DematACState")}
                    className="common-btn"
                  >
                    Next <AiOutlineArrowRight />
                  </button>
                </>
              ) : (
                <div className="w-100">
                  {!editDemit && (
                    <div className="d-c-flex mb-2 justify-content-end w-100">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={updateDemitChanges}
                      >
                        <FiEdit2
                          title="edit"
                          size="20"
                          color="var(--primary)"
                        />
                      </span>
                    </div>
                  )}

                  <form className="d-flex flex-wrap col-8 justify-content-between">
                    <div className="form-group col-5 mb-4">
                      <label htmlFor="exampleInputEmail1">Demat ID </label>
                      <input
                        type="text"
                        name="dematId"
                        disabled={editDemit ? false : true}
                        defaultValue={user?.demitInfo?.dematId}
                        onChange={handleDemitChange}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-group col-5">
                      <label htmlFor="exampleInputEmail1">Depository</label>
                      <input
                        type="text"
                        name="depository"
                        defaultValue={user?.demitInfo?.depository}
                        disabled={editDemit ? false : true}
                        onChange={handleDemitChange}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>

                    <div className="form-group col-5">
                      <label htmlFor="exampleInputEmail1">DP ID</label>
                      <input
                        type="text"
                        name="dpId"
                        defaultValue={user?.demitInfo?.dpId}
                        disabled={editDemit ? false : true}
                        onChange={handleDemitChange}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>

                    <div className="form-group col-5 mb-4">
                      <label htmlFor="exampleInputEmail1">
                        Depository Participation (DP)
                      </label>
                      <input
                        type="text"
                        defaultValue={user?.demitInfo?.depositoryParticipation}
                        disabled={editDemit ? false : true}
                        name="depositoryParticipation"
                        onChange={handleDemitChange}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </form>

                  {editDemit && (
                    <div className="d-c-flex mb-2 justify-content-end w-100">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={updateDemitChanges}
                      >
                        <button className="common-btn">Save Changes</button>
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {/* Add Nominee  */}

        {selectedTab === NOMINEE_TAB ? (
          <div className="profile-nominee mb-5" id="add-nominee">
            {/* <h4 className="py-3">Add/Change Nominee</h4> */}
            <div className="profile-box profile-inner p-5 d-c-flex flex-column">
              {NomineeState === 0 ? (
                <>
                  <h3>Complete Your Nominee Details</h3>
                  <h5>in less than 1 min!</h5>
                  <button
                    onClick={() => changeState("NomineeState")}
                    className="common-btn"
                  >
                    Next <AiOutlineArrowRight />
                  </button>
                </>
              ) : (
                <div className="profile-nominee w-100 ">
                  {NomineeData.length > 0 &&
                    NomineeData?.map((item, index) => {
                      return (
                        <form className="d-c-flex col-12 justify-content-between position-relative">
                          <div className="form-group  mb-4 me-3">
                            <label htmlFor="exampleInputEmail1">
                              Nominee {index + 1}{" "}
                            </label>
                            <input
                              type="text"
                              value={item.name}
                              readOnly
                              disabled
                              className="form-control"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="form-group   mb-4">
                            <label htmlFor="exampleInputEmail1">
                              Phone Number
                            </label>
                            <input
                              type="number"
                              readOnly
                              min={0}
                              disabled
                              value={item.phoneNumber}
                              className="form-control"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="form-group   mb-4">
                            <label htmlFor="exampleInputEmail1">DOB</label>
                            <input
                              type="date"
                              readOnly
                              disabled
                              value={item.dateOfBirth}
                              className="form-control"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="form-group   mb-4">
                            <label htmlFor="exampleInputEmail1">Address</label>
                            <input
                              type="text"
                              readOnly
                              disabled
                              value={item.address}
                              className="form-control"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: -30,
                              top: 0,
                              cursor: "pointer",
                            }}
                            title="remove nominee"
                            onClick={() => RemoveNominee(index)}
                          >
                            <AiOutlineDelete color="red" size="24" />
                          </div>
                        </form>
                      );
                    })}
                  <form className="d-c-flex col-12 justify-content-between">
                    <div className="form-group mb-4 me-3">
                      <label htmlFor="exampleInputEmail1">
                        Nominee {NomineeData.length + 1}{" "}
                      </label>
                      <input
                        type="text"
                        value={ninput.name}
                        onChange={(e) =>
                          setNInput({
                            ...ninput,
                            name: e.target.value,
                          })
                        }
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-group   mb-4">
                      <label htmlFor="exampleInputEmail1">Phone Number </label>
                      <input
                        type="number"
                        value={ninput.phoneNumber}
                        min={0}
                        onChange={(e) =>
                          setNInput({
                            ...ninput,
                            phoneNumber: e.target.value,
                          })
                        }
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-group   mb-4">
                      <label htmlFor="exampleInputEmail1">DOB </label>
                      <input
                        type="date"
                        value={ninput.dateOfBirth}
                        onChange={(e) =>
                          setNInput({
                            ...ninput,
                            dateOfBirth: e.target.value,
                          })
                        }
                        max={new Date().toISOString().split("T")[0]}
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-group   mb-4">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <input
                        type="text"
                        value={ninput.address}
                        onChange={(e) =>
                          setNInput({
                            ...ninput,
                            address: e.target.value,
                          })
                        }
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </form>
                  <button
                    onClick={addNominee}
                    style={{
                      background: "var(--primary)",
                      color: "#ffffff",
                      border: "none",
                    }}
                    className="btn btn-outline-theme-color"
                  >
                    + Add Nominee
                  </button>
                  <div className="d-flex w-100 justify-content-end">
                    <button
                      style={{
                        opacity: update ? 0.6 : 1,
                      }}
                      className="common-btn m-0"
                      onClick={saveNominee}
                      disabled={update}
                    >
                      {update ? "Loading..." : "Submit to get verified"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </section>
      {loader && <Loading />}
    </>
  );
}

export default Profile;
