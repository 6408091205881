import React from 'react';

const SingleVisualReturn = ({ item, index, sdate, edate, data, length }) => {


  const calculateDaysBetween = (startDate, endDate) => {

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    const diffDays = Math.floor((endMillis - startMillis) / oneDay);

    return diffDays;
  };



  const calculateInterest = (startDate, endDate) => {
    const sdate = new Date(startDate);
    const edate = new Date(endDate);
    let days = calculateDaysBetween(sdate, edate);
    // debugger
    const AnnuallyReturns = Number(data?.minimumInvestment) * (Number(data?.preTaxReturns) / 100)
    const dailyReturns = AnnuallyReturns / 365;
    const result = days * dailyReturns
    return result.toFixed(2)
  };



  // console.log(calculateDaysBetween(sdate, edate))
  return (
    <>
      {
        index < length - 1 ?
          <tr key={item}>
            <td>{index}</td>
            <td>{item}</td>
            <td>
              ₹{" "}
              {index < length ? index === length - 2 ? Number(calculateInterest(sdate, edate)) + Number(data?.minimumInvestment) : calculateInterest(sdate, edate) : "ok"}
            </td>
          </tr> : null
      }

    </>
  );
}

export default SingleVisualReturn;
