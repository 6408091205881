import React, { useState } from "react";
import "../styles/footer.css";
import { useNavigate } from "react-router-dom";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Twitter from "@mui/icons-material/Twitter";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Cookies from "js-cookie";
import CustomModal from "./CustomModal";

function Footer() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    const token = Cookies.get("uniCap");
    return (
      <>
        <footer
          className="footer footerMd"
          style={{
            background: token ? "var(--primary)" : "#1d4442",
            width: "100%",
          }}
        >
          {/* <main className="container-fluid d-flex py-4 container justify-content-between footerMd">
                    <div className="col-3 footer-first">
                        <h3>Unicap</h3>
                        <h6>
                            Tech platform to provide an access to universal
                            capital and private investments from across the
                            world for your growth.
                        </h6>
                    </div>
                    <div className="footer-mid">
                        <h4>Let's Do It</h4>
                        <p onClick={handleOpen}>Invest</p>
                        <p onClick={handleOpen}>Raise</p>
                        <p onClick={handleOpen}>Join Us</p>
                    </div>
                    <div className="footer-mid ">
                        <h4>Browse</h4>
                        <p onClick={handleOpen}>Trends</p>
                        <p onClick={handleOpen}>Recent Updates</p>
                        <p onClick={handleOpen}>Investment comparison</p>
                    </div>
                    <div className="footer-mid ">
                        <h4>Legal</h4>
                        <p onClick={handleOpen}>Privacy Policy</p>
                        <p onClick={handleOpen}>Terms of Service</p>
                        <p onClick={handleOpen}>FAQ/Guide</p>
                    </div>
                    <div className="footer-last">
                        <h4>Connect With Us</h4>
                        <div className="d-flex">
                            <p onClick={handleOpen}>
                                {" "}
                                <Facebook />{" "}
                            </p>
                            <p onClick={handleOpen}>
                                {" "}
                                <LinkedIn />{" "}
                            </p>
                            <p onClick={handleOpen}>
                                {" "}
                                <Instagram />{" "}
                            </p>
                            <p onClick={handleOpen}>
                                {" "}
                                <Twitter />{" "}
                            </p>
                        </div>
                    </div>
                </main>
                <div
                    className="footer-bottom"
                    style={{
                        color: token ? "var(--primary)" : "#1d4442",
                    }}
                >
                    <h5>© Unicap.com (A part of Sansara group)</h5>
                </div> */}
          <main className="container-fluid d-flex py-5 container flex-column flex-md-row footerPhone">
            <div className="col-12 col-md-4 footer-first">
              <h3>Unicap</h3>
              <h6>
                Tech platform to provide an access to universal capital and
                private investments from across the world for your growth.
              </h6>
            </div>
            <div className="col-12 col-md-4 row my-4 my-md-0">
              <div className="col-5 d-flex flex-column footer-mid firstMid">
                <h4>Let's Do It</h4>
                <p onClick={handleOpen}>Invest</p>
                <p onClick={handleOpen}>Raise</p>
                <p onClick={() => navigate("/join-us")}>Join Us</p>
              </div>
              <div className="col-7 footer-mid">
                <h4>Browse</h4>
                <p onClick={handleOpen}>Trends</p>
                <p onClick={handleOpen}>Recent Updates</p>
                <p onClick={handleOpen}>Investment comparison</p>
              </div>
            </div>
            <div className="col-12 col-md-4 row my-4 my-md-0 ms-0 ms-md-2">
              <div className="footer-mid col-6 ps-0 ps-md-3">
                <h4>Legal</h4>
                <p onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
                <p onClick={handleOpen}>Terms of Service</p>
                <p onClick={handleOpen}>FAQ/Guide</p>
              </div>
              <div className="footer-last col-6 ps-0 ps-md-3">
                <h4>Connect With Us</h4>
                <div className="d-flex">
                  <p onClick={handleOpen}>
                    {" "}
                    <Facebook />{" "}
                  </p>
                  <p onClick={handleOpen}>
                    {" "}
                    <LinkedIn />{" "}
                  </p>
                  <p onClick={handleOpen}>
                    {" "}
                    <Instagram />{" "}
                  </p>
                  <p onClick={handleOpen}>
                    {" "}
                    <Twitter />{" "}
                  </p>
                </div>
              </div>
            </div>
          </main>
          <div
            className="footer-bottom"
            style={{
              color: token ? "var(--primary)" : "#1d4442",
            }}
          >
            <h5>©theUnicap.com (The Sansara Group)</h5>
          </div>
        </footer>
        <CustomModal open={open} hide={handleClose}>
          <div className="connectModal">
            <div className="closeIcon">
              <HighlightOffIcon className="close" onClick={handleClose} />
            </div>
            <h2>Let’s Connect</h2>
            <p>
              We are working hard to create an amazing user experience for you.
              <br /> Be the first to know and become a part of our community.
            </p>
            <div className="connectModal_footer">
              <input placeholder="Enter email address here" type="text" />
              <button>Submit</button>
            </div>
          </div>
        </CustomModal>
      </>
    );
}

export default Footer;
