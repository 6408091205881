
import React from 'react';
import "./styles.css";

const PrivacyPolicy = () => {
  return (
    <div className='privacy_out'>
      <div className="container-fluid privacy_container">
        <div className="d-flex justify-content-between align-items-center pt-4">
          <h3 className="privacy_heading">Privacy Policy</h3>
          <h6>27/May/2023</h6>
        </div>
        <br />
        <p>
          We are committed to PROTECTING and SAFEGUARDING your privacy and your
          personal information. This Privacy Policy outlines how we collect,
          use, and safeguard your information when you use our Platform. By
          accessing or using our Platform, you agree to the terms of this
          Privacy Policy.
        </p>
        <br />
        <div>
          <h5>Information We Collect – Only what you share</h5>
          <p>
            1.1 Personal Information: We may collect personal information that
            you provide to us when you sign up for an account, such as your
            name, email address, phone number, and any other information you
            choose to provide. We may also collect information when you
            communicate with us, participate in surveys or promotions, or use
            certain features of the Platform.
          </p>
          <br />
          <p>
            1.2 Financial Information: To facilitate investment transactions on
            the Platform, we may collect financial information, such as bank
            account details, credit card information, or other payment
            information. We will only collect this information with your
            explicit consent and in compliance with applicable laws and
            regulations.
          </p>
          <br />
          <p>
            1.3 Usage Information: When you use our Platform, we automatically
            collect certain information about your interactions and usage
            patterns. This may include your IP address, device information,
            browser type, referring/exit pages, and other usage statistics. We
            may also use cookies and similar technologies to collect this
            information. You can control the use of cookies through your browser
            settings.
          </p>
        </div>
        <br />
        <div>
          <h5>How We Use Your Information – To Improve ourselves</h5>
          <p>
            2.1 Providing and Improving the Platform: We use the information we
            collect to provide, maintain, and improve the functionality and
            features of our Platform. This includes personalizing your
            experience, processing transactions, and ensuring the security of
            your account.
          </p>
          <br />
          <p>
            2.2 Communication: We may use your contact information to
            communicate with you about your account, respond to your inquiries,
            send administrative messages, and provide important updates or
            notifications regarding the Platform. We may also send you marketing
            or promotional communications if you have opted-in to receive them.
          </p>
          <br />
          <p>
            2.3 Legal Compliance: We may use your information to comply with
            applicable laws, regulations, legal processes, or enforceable
            governmental requests. This includes preventing fraud, protecting
            our rights and property, and ensuring the safety of our users.
          </p>
        </div>
        <br />
        <div>
          <h5>
            Information Sharing and Disclosure – Only if you would like us to
          </h5>
          <p>
            3.1 Service Providers: We may share your information with trusted
            third-party service providers who assist us in operating our
            Platform, conducting business activities, or providing services on
            our behalf. These service providers are obligated to maintain the
            confidentiality and security of your information and are prohibited
            from using it for any other purpose.
          </p>
          <br />
          <p>
            3.2 Legal Requirements: We may disclose your information if required
            to do so by law or if we believe in good faith that such disclosure
            is necessary to comply with legal obligations, protect our rights or
            property, investigate fraud, or ensure the safety of our users.
          </p>
          <br />
          <p>
            3.3 Business Transfers: In the event of a merger, acquisition, or
            sale of our assets, your information may be transferred to the
            acquiring entity or third party involved. We will notify you via
            email or prominent notice on our Platform of any change in ownership
            or use of your personal information.
          </p>
        </div>
        <br />
        <div>
          <h5>Data Security</h5>
          <p>
            We take robust security measures and encryption protocols to protect
            your personal information from unauthorized access, alteration,
            disclosure, or destruction. However, no method of transmission over
            the internet or electronic storage is 100% secure. While we strive
            to use commercially acceptable means to protect your information, we
            cannot guarantee its absolute security.
          </p>
        </div>
        <br />
        <div>
          <h5>Your Choices and Rights</h5>
          <p>
            5.1 Account Settings: You may review and update your account
            information by accessing your account settings on the Platform. You
            can also adjust your communication preferences to manage the types
            of messages you receive from us.
          </p>
          <br />
          <p className='last_item'>
            5.2 Cookies: Most web browsers are set to accept cookies by default.
            You can modify your browser settings to reject cookies or prompt you
            before accepting them. However, please note that certain
            features of the Platform
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy