import React from "react";

const CheckBox = ({ handleCheckbox, id, defaultCard }) => {
    console.log(id);
    return (
        <>
            <input
                onChange={(e) => handleCheckbox(e, id)}
                type="checkbox"
                checked={defaultCard === id}
                id={`checkbox-${id}`}
            />
            <label for={`checkbox-${id}`}></label>
        </>
    );
};

export default CheckBox;
