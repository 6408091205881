import React, { useState, useEffect } from "react";
import CategoryCard from "../../../common/CategoryCard";
import { GetMyListing, MyDashboard } from "../../../utils/apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
const MyListing = () => {
    const ToastOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    };

    const [data, setData] = useState([]);
    const [total, setTotal] = useState({ user: 0, listing: 0, amount: 0 });
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state?.user);
    const [select, setSelected] = useState("active");
    const GetListing = async () => {
        setLoading(true);
        try {
            const { data } = await GetMyListing(user._id, select);
            const d = await MyDashboard();
            setData(data.result);
            setTotal(d.data.result);
        } catch (error) {
            const { response } = error;
            console.log(response.data.message);
            toast.warning(response.data.message, ToastOptions);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        GetListing();
    }, [select]);
    console.log(total);
    return (
        <div className="category-main h-100vh ">
            <div className="container">
                <div className="offers-btn">
                    <span
                        className={select === "active" ? "active" : ""}
                        onClick={() => setSelected("active")}
                    >
                        Active Offers
                    </span>
                    <span
                        className={select === "previous" ? "active" : ""}
                        onClick={() => setSelected("previous")}
                    >
                        Previous Offers
                    </span>
                    <span
                        className={select === "drafted" ? "active" : ""}
                        onClick={() => setSelected("drafted")}
                    >
                        Drafted
                    </span>
                    <span
                        className={select === "pending" ? "active" : ""}
                        onClick={() => setSelected("pending")}
                    >
                        Pending
                    </span>
                    <span
                        className={select === "rejected" ? "active" : ""}
                        onClick={() => setSelected("rejected")}
                    >
                        Rejected
                    </span>
                </div>
                <div className="total-list">
                    <div className="listCard">
                        <p>{total.user}</p>
                        <h6> Total Users</h6>
                    </div>
                    <div className="listCard">
                        <p>{total.listing}</p>
                        <h6> Total Listings</h6>
                    </div>
                    <div className="listCard">
                        <p>₹ {total.amount}</p>
                        <h6>Total Amount</h6>
                    </div>
                </div>
            </div>
            {data.length > 0 ? (
                <div
                    className={`d-flex flex-wrap ${
                        data?.length === 1
                            ? "justify-content-start"
                            : "justify-content-evenly"
                    }`}
                >
                    {data.map((e, id) => (
                        <CategoryCard data={e} key={id} />
                    ))}
                </div>
            ) : (
                <div className="d-flex  justify-content-center my-5 h-full">
                    <h2 className="my-5">No offers present </h2>
                </div>
            )}
            {loading && <Loading />}
        </div>
    );
};

export default MyListing;
