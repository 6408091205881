import React, { useEffect } from "react";
import { useState } from "react";
import { IoCalendarClearOutline } from "react-icons/io5";

import cardlogo from "../../assets/cardLogo.png";
import { useNavigate } from "react-router-dom";
import { CreateOrder, allListings, getAllListing } from "../../utils/apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Images } from "../../assets";
import InvestNowCard from "./InvestNowCard";
import VisualizeReturn from "../UserSide/Finances/VisualizeReturn";

const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const OfferCard = () => {
    const [listings, setListings] = useState([]);
    const [open, setOpen] = useState(false);
    const [singleData, setSingleData] = useState({});
    const navigate = useNavigate();
    const [investLoading, setInvestLoading] = useState(false);

    const user = useSelector((state) => state.user);



    useEffect(() => {
        const fetchListing = async () => {
            const select = "active"
            const category = "Corporate_Bonds"
            try {
                const response = await allListings(select, category)
                setListings(response?.data?.result)
            } catch (err) {
                console.log(err);
            }
        }

        fetchListing()
    }, [])



    const investNow = async ({ data, amount }) => {
        setInvestLoading(true);
        if (user?.kycStatus === null || user?.kycStatus === "rejected" || user?.kycStatus === "pending") {
            setInvestLoading(false);
            return toast.info("Please Complete KYC Information First", ToastOptions);
        }
        try {
            const newdata = {
                total: amount * data?.faceValue,
                listingId: data?.id
            }
            const res = await CreateOrder(newdata)
            setInvestLoading(false);
            navigate("/payment-confirm", {
                state: {
                    heading: "Payment successful",
                    headingClass: "paymentSucess",
                    message:
                        "Congratulations! Your investment payment has been successfully processed.",
                    btnClass: "brandBgBtn",
                    btnTitle: "Invest more",
                    image: Images.PaymentAccept,
                },
            });

        } catch (error) {
            const { response } = error;
            console.log(error)
            setInvestLoading(false);
            toast.error(response?.data?.message, ToastOptions);
            // navigate("/payment-confirm", {
            //   state: {
            //     heading: "Payment failed",
            //     headingClass: "paymentFail",
            //     message:
            //       "Your payment was declined. Please try again or use a different payment method",
            //     btnClass: "paymentFailBtn",
            //     btnTitle: "Try again",
            //     image: Images.PaymentFail,
            //   },
            // });
        }
    }

    const handleOpenData = (data, preTAx) => {
        setSingleData({ ...data, preTAx: preTAx });
        setOpen(true);
    }


    return (
        <div className="container mb-0 mb-md-4">
            <h2
                className="text-left  pt-3 ms-5 heading-compare-offer"
                style={{
                    marginTop: "10vh",
                }}
            >
                Corporate Bonds
            </h2>
            {listings?.map((data, i) => (
                <main key={i} className="px-4" style={{ width: "90vw" }}>
                    <div className="d-flex p-4">
                        {/* left */}
                        <div style={{ width: "65%" }} className="me-5">
                            <div className="d-flex">
                                <img
                                    src={data?.companyLogo?.url}
                                    height={90}
                                    width={90}
                                    alt="logo"
                                />
                                <div className="flex-column ms-3">
                                    <h4>{data?.companyName}</h4>
                                    <p>
                                        {data?.companyDescription.split(".\n")[0]}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h5>About {data?.companyName}</h5>
                                <p>
                                    {data?.companyDescription}
                                    <span
                                        className="theme-color-color mx-1"
                                        onClick={() => {
                                            navigate(
                                                "/finance-detail/63cb4b94bab92965701068d8"
                                            );
                                        }}
                                    >
                                        Read More
                                    </span>
                                </p>
                            </div>
                        </div>

                        {/* right */}
                        <div className="w-50 d-flex align-items-end flex-column  ">
                            {/* <div
                                className=" p-3 w-75 home-modal-recommend-container "
                                style={{ position: "relative" }}
                            >
                                <span className="d-flex justify-content-between px-2">
                                    <p>Current Price</p>
                                    <strong>₹ 25,000 / Unit</strong>
                                </span>

                                <span className="d-flex justify-content-between px-2">
                                    <p>Total Unit Left</p>
                                    <strong>30000/ 50000</strong>
                                </span>

                                <hr />
                                <div className="d-flex flex-column align-items-center">
                                    <strong className="text-center w-100 mt-2">
                                        No. Of Unit
                                    </strong>
                                    <div className="d-flex justify-content-between w-100 mt-4">
                                        <button
                                            onClick={() => setvalue(value - 1)}
                                            className="modal-decrement-btn"
                                        >
                                            -
                                        </button>
                                        <button className="modal-increment-value-display">
                                            {value}
                                        </button>
                                        <button
                                            onClick={() => setvalue(value + 1)}
                                            className="modal-increment-btn"
                                        >
                                            +
                                        </button>
                                    </div>
                                    <p
                                        className="text-danger mt-3"
                                        style={{ fontSize: "0.8rem" }}
                                    >
                                        Minimum No of Units should be 30
                                    </p>
                                </div>

                                <hr />
                                <span className="d-flex justify-content-between px-2">
                                    <p>Current Price</p>
                                    <strong>₹ 25,000 / Unit</strong>
                                </span>

                                <span className="d-flex justify-content-between px-2">
                                    <p>Total Unit Left</p>
                                    <strong>30000/ 50000</strong>
                                </span>

                                <button className="investNow-btn">
                                    Invest Now
                                </button>
                            </div> */}
                            {/* <div
                                className=" p-3 w-100 home-modal-recommend-container "
                                style={{ position: "relative" }}
                            >
                                <span className="d-flex justify-content-between px-2">
                                    <p>Current Price</p>
                                    <strong>₹ {data?.faceValue} / Unit</strong>
                                </span>

                                <span className="d-flex justify-content-between px-2">
                    <p>Total Unit Left</p>
                    <strong>30000/ 50000</strong>
                  </span>

                                <hr />
                                <div className="d-flex flex-column align-items-center">
                                    <strong className="text-center w-100 mt-2">
                                        No. Of Unit
                                    </strong>
                                    <div className="d-flex justify-content-between w-100 my-4">
                                        <button
                                            disabled={amount === minUnit}
                                            title={amount === minUnit && "Minimum Unit Reached"}
                                            onClick={() => setAmount(amount - 1)}
                                            className="modal-decrement-btn"
                                        >
                                            <span>-</span>
                                        </button>
                                        <button className="modal-increment-value-display">
                                            {handleMinAmmount(data.minimumInvestment, data.faceValue)}
                                        </button>
                                        <button
                                            onClick={() => setAmount(amount + 1)}
                                            className="modal-increment-btn"
                                        >
                                            +
                                        </button>
                                    </div>
                                    {amount < 30 && (
                                        <p
                                            className="text-danger mb-3"
                                            style={{ fontSize: "0.8rem" }}
                                        >
                                            Minimum No of Units should be 30
                                        </p>
                                    )}
                                </div>

                                <hr />
                                <span className="d-flex justify-content-between px-2">
                                    <p>Amount Payable</p>
                                    <strong>₹ {handlePayable(amount, data?.faceValue)} </strong>
                                </span>

                                <span className="d-flex justify-content-between px-2">
                                    <p>Pre-Tax Returns</p>
                                    <strong>₹ {handleTaxReturns(data)}</strong>
                                </span>
                                <div
                                    className="d-flex justify-content-center mt-2 "
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setOpen(true)}
                                >
                                    <h3 className="py-3">
                                        <IoCalendarClearOutline size="22" />
                                        &nbsp; Visualise Returns
                                    </h3>
                                </div>

                                <button
                                    onClick={investNow}
                                    className="investNow-btn"
                                >
                                    Invest Now
                                </button>
                            </div> */}
                            <InvestNowCard investNow={investNow} handleOpenData={handleOpenData} data={data} />
                        </div>
                    </div>
                </main>
            ))}
            <VisualizeReturn
                open={open}
                setOpen={setOpen}
                data={singleData}
                Time={singleData?.timeToMaturity ? singleData?.timeToMaturity : 36}
                // Total={handlePayable(amount, data?.faceValue)}
                Total={singleData?.preTAx}
                date={singleData.dateOfIssue}
            />
        </div>
    );
};

export default OfferCard;
