import React from "react";
import Modal from "react-bootstrap/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { Images } from "../../../assets";
import { useMemo } from "react";

const AddMoneyOptions = [
    {
        id: 1,
        image: Images.GooglePay,
    },
    {
        id: 2,
        image: Images.Phonepe,
    },
    {
        id: 3,
        image: Images.Paytm,
    },
    {
        id: 4,
        image: Images.Amazon,
    },
];

const WalletModal = ({ open, hide, modalTitle }) => {
    const [values, setValues] = useState("");
    const [downloadHistory, setDownloadHistory] = useState({
        from: "",
        to: "",
    });

    const handleChange = (e) => {
        setValues(e.target.value);
    };

    const handleDownloadHistory = (e) => {
        setDownloadHistory({
            ...downloadHistory,
            [e.target.name]: e.target.value,
        });
    };

    const handleActiveAmount = (amount) => {
        if (values === amount) {
            return "active";
        } else {
            return "";
        }
    };
    useMemo(() => {
        setValues("");
        setDownloadHistory({
            from: "",
            to: "",
        });
    }, [open]);

    const handleDisabled = () => {
        if (modalTitle === "Download Transaction History") {
            return downloadHistory.from === "" || downloadHistory.to === ""
                ? true
                : false;
        } else {
            return values === "" ? true : false;
        }
    };

    console.log(handleDisabled());

    return (
        <Modal
            show={open}
            onHide={hide}
            size="md"
            centered
            className="wallet-modal"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <div className="wallet-modal-container">
                <div className="wallet-modal-close-icon">
                    <HighlightOffIcon className="close" onClick={hide} />
                </div>
                <div className="wallet-modal-title">
                    <h6>
                        {modalTitle === "Withdraw"
                            ? "Withdraw Money"
                            : modalTitle}
                    </h6>
                </div>
                <div className="wallet-modal-contnet-container">
                    {modalTitle === "Download Transaction History" ? (
                        <Form className="wallet-form-transaction-history">
                            <Form.Group
                                className="wallet-form-transaction-item"
                                controlId="formBasicName"
                            >
                                <Form.Label>From</Form.Label>
                                <TextField
                                    id="outlined-password-input"
                                    name="from"
                                    onChange={handleDownloadHistory}
                                    value={downloadHistory.from}
                                    size="small"
                                    type="date"
                                    autoComplete="current-password"
                                />
                            </Form.Group>
                            <Form.Group className="wallet-form-transaction-item">
                                <Form.Label>To</Form.Label>
                                <TextField
                                    id="outlined-password-input"
                                    name="to"
                                    onChange={handleDownloadHistory}
                                    value={downloadHistory.to}
                                    size="small"
                                    type="date"
                                    autoComplete="current-password"
                                />
                            </Form.Group>
                        </Form>
                    ) : (
                        <Form.Group
                            className="wallet-form-group"
                            controlId="formBasicName"
                        >
                            <TextField
                                id="outlined-password-input"
                                name="name"
                                onChange={handleChange}
                                value={values}
                                size="small"
                                label="Enter amount"
                                type="text"
                                autoComplete="current-password"
                            />
                        </Form.Group>
                    )}
                    {modalTitle === "Withdraw" && (
                        <div className="wallet-modal-Withdraw-container">
                            <div className="wallet-modal-Withdraw-content">
                                <div className="wallet-modal-Withdraw-btn">
                                    <button
                                        className={handleActiveAmount("5000")}
                                        onClick={() => setValues("5000")}
                                    >
                                        ₹ 5,000
                                    </button>
                                    <button
                                        className={handleActiveAmount("10000")}
                                        onClick={() => setValues("10000")}
                                    >
                                        ₹ 10,000
                                    </button>
                                </div>
                                <div className="wallet-modal-Withdraw-btn">
                                    <button
                                        className={handleActiveAmount("20000")}
                                        onClick={() => setValues("20000")}
                                    >
                                        ₹ 20,000
                                    </button>
                                    <button
                                        className={handleActiveAmount("40000")}
                                        onClick={() => setValues("40000")}
                                    >
                                        ₹ 40,000
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {modalTitle === "Add Money" && (
                        <div className="wallet-modal-addMoney-container">
                            <div className="wallet-modal-addMoney-content">
                                <hr />
                                <h6>Add with</h6>
                                <hr />
                            </div>
                            <div className="wallet-modal-addMoney-btn">
                                {AddMoneyOptions.map((item) => (
                                    <img
                                        key={item.id}
                                        src={item.image}
                                        alt=""
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="wallet-modal-btn">
                        <button
                            className={
                                handleDisabled() && "wallet-modal-btn-desabled"
                            }
                            disabled={handleDisabled()}
                        >
                            continue
                        </button>
                    </div>
                    {modalTitle === "Add Money" && (
                        <p>*Complete your KYC to add money</p>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default WalletModal;
