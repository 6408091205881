import { ROLE } from "../action/action";

const initialState = { role: "" };

const userRole = (state = initialState, action) => {
  switch (action.type) {
    case ROLE:
      return action.payload;

    default:
      return state;
  }
};

export default userRole;
