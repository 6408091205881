import React from "react";
import "../../../styles/comparison.css";
import { IoArrowBackSharp, IoChevronDownOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ComparisonData, ComparisonHeadings } from "./Index";
import { BannerImage } from "../../../assets/banner";
import { useLocation } from "react-router-dom";

const InvestmentComparison = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    return (
        <div className="container comparison_container">
            <h3 className="title">
                <IoArrowBackSharp
                    onClick={() =>
                        navigate("/choose-company", {
                            state: state,
                        })
                    }
                    size="24"
                />
                &nbsp; Investment comparison
            </h3>
            <div className="comparison_table_container">
                <div className="comparison_table">
                    <div className="comparison_table_main">
                        <div className="comparison_table_header">
                            <div className="comparison_table_header_item">
                                {/* <img src={BannerImage.navi} alt="" /> */}
                            </div>
                        </div>
                        <div className="comparison_table_content">
                            <div className="comparison_table_content_item main_Heading">
                                <h6>{ComparisonHeadings.MinimumInvestment}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.Currentprice}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.TotalInvestment}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.PreTaxReturn}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.YieldToMaturity}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.TimeToMaturity}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.CouponRate}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.InterestPayment}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.TransactionType}</h6>
                            </div>
                            <div className="comparison_table_content_item main_Heading ">
                                <h6>{ComparisonHeadings.Rating}</h6>
                            </div>
                        </div>
                    </div>
                    {state.map((item) => (
                        <div className="comparison_table_main" key={item.id}>
                            <div className="comparison_table_header">
                                <div className="comparison_table_header_item">
                                    <img src={item.image} alt="" />
                                    <h6>{item.name}</h6>
                                </div>
                            </div>
                            <div className="comparison_table_content">
                                <div className="comparison_table_content_item">
                                    <h6>{item.MinimumInvestment}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.Currentprice}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.TotalInvestment}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.PreTaxReturn}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.YieldToMaturity}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.TimeToMaturity}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.CouponRate}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.InterestPayment}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.TransactionType}</h6>
                                </div>
                                <div className="comparison_table_content_item">
                                    <h6>{item.Rating}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InvestmentComparison;
