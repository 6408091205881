import { combineReducers } from "redux";
import userReducer from "./reducer/userReducer";
import userRole from "./reducer/roleReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "role"],
};

const rootReducer = combineReducers({
  user: userReducer,
  role: userRole,
});

export default persistReducer(persistConfig, rootReducer);
