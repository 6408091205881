import { useState } from "react";
import "../../styles/signin.css";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Register, SocialLogin } from "../../utils/apis";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { UserInfo, UserRole } from "../../Redux/exportAllAction";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  FiUser,
  FiLock,
  FiEye,
  FiEyeOff,
  FiPhoneCall,
  FiKey,
  FiLoader,
  FiX,
  FiCheckCircle,
} from "react-icons/fi";
import { MdAlternateEmail } from "react-icons/md";
// import { FcGoogle } from "react-icons/fc";
import Logo from "../../assets/logo.png";
import Vector from "../../assets/signup.png";
import googleIcon from "../../../src/assets/googleicon.png";
// import Message from "../../assets/message.png";
import RaiserSignup from "./RaiserSignup";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../utils/firebase";
const Signup = ({ setIsSignUp, handleClose }) => {
  const [show, setShow] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const countryCode = "+91";
  const [input, setInput] = useState({
    email: "",
    password: "",
    name: "",
    phoneNumber: countryCode,
    code: "",
  });
  const [loading, setLoading] = useState(false);
  const [otpVerify, setVerify] = useState(false);
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const handleChange = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };
  const navigate = useNavigate();
  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );
    }
  };

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      const { displayName, email } = user;
      console.log(displayName, email);
      const { data } = await SocialLogin({
        name: displayName,
        email: email,
      });
      console.log(data);
      dispatch(UserInfo(data?.data?.user));
      dispatch(UserRole(data?.role));
      Cookies.set("uniCap", data?.token);
      console.log(data?.token);
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };

  const RequestOTP = (e) => {
    console.log(input.phoneNumber.length);
    e.preventDefault();
    if (input.phoneNumber.length >= 12) {
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, input?.phoneNumber, appVerifier)
        .then((confirmationResult) => {
          toast.success("OTP Sent Successfully", ToastOptions);
          window.confirmationResult = confirmationResult;
        })

        .catch((error) => {
          console.log(error);
          toast.error("OTP not sent!");
        });
    } else toast.warning("Phone number must be of 10 digit", ToastOptions);
  };
  const verifyOTP = (e) => {
    let no = e.target.value;
    setInput({ ...input, code: no });
    if (no.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(no)
        .then((result) => {
          // const user = result.user;
          setVerify(true);
          setExpandForm(false);
          window.recaptchaVerifier = "";
          // console.log(user);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if (!otpVerify) {
      RequestOTP(e);
    }
    console.log(input);
    if (otpVerify) {
      if (input.email && input.password && input.name && input.phoneNumber) {
        setLoading(true);
        try {
          const { data } = await Register(input);

          data && setLoading(false);
          dispatch(UserInfo(data.data.user));
          dispatch(UserRole(data.role));
          Cookies.set("uniCap", data?.token);
          navigate("/home");
        } catch (error) {
          const { response } = error;
          console.log(response?.data);
          toast.error(response?.data?.message, ToastOptions);
          setLoading(false);
        }
      } else {
        toast.error("input field is required", ToastOptions);
      }
    } else {
      expandForm && toast.warning("Please verify Phone Nmber", ToastOptions);
    }
  };

  return (
    <div className="signin-main">
      <div className="my-1 modalLogo" onClick={() => navigate("/")}>
        <h1>
          <span>uni</span> cap
        </h1>
        <div className="closeIcon">
          <HighlightOffIcon className="close" onClick={handleClose} />
        </div>
      </div>

      {/* <h4>Welcome</h4> */}
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          <img src={Vector} alt="vector" width="90%" />
        </div>
        <div className="col-lg-6 col-md-12">
          <h3>Sign Up</h3>
          <small
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 2.5em",
            }}
          >
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Sign Up as Raiser Account"
              checked={check}
              value={check}
              onChange={() => (check ? setCheck(false) : setCheck(true))}
            />
            <span onClick={() => setIsSignUp(false)} className="login-text">
              Login
            </span>
          </small>
          {check ? (
            <RaiserSignup />
          ) : (
            <>
              <Form className="form-main" onSubmit={submitForm}>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1">
                    <FiUser size="20" color="#c4c4c4" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Full Name"
                    type="text"
                    aria-label="Full Name"
                    name="name"
                    value={input.name}
                    onChange={handleChange}
                    className="basic-addon2"
                    required
                  />
                </InputGroup>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1">
                    <MdAlternateEmail size="20" color="#c4c4c4" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    aria-label="Email"
                    name="email"
                    value={input.email}
                    onChange={handleChange}
                    className="basic-addon2"
                    required
                  />
                </InputGroup>
                <InputGroup className="mb-0">
                  <InputGroup.Text id="basic-addon1">
                    <FiLock size="20" color="#c4c4c4" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Password"
                    aria-label="password"
                    type={show ? "text" : "password"}
                    aria-describedby="basic-addon1"
                    name="password"
                    value={input.password}
                    onChange={handleChange}
                    required
                    id="basic-addon3"
                  />
                  <InputGroup.Text
                    className="basic-addon2"
                    onClick={() => (show ? setShow(false) : setShow(true))}
                  >
                    {show ? (
                      <FiEyeOff
                        size="20"
                        color="#c4c4c4"
                        title="hide"
                        cursor="pointer"
                      />
                    ) : (
                      <FiEye
                        size="20"
                        color="#c4c4c4"
                        title="show"
                        cursor="pointer"
                      />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {/* <small
                                    className="text-black mb-3"
                                    style={{ float: "left" }}
                                >
                                    Must Be At Least 8 Characters.
                                </small> */}
                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    className={otpVerify ? "disabled" : ""}
                  >
                    <FiPhoneCall size="20" color="#c4c4c4" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Phone Number"
                    type="tel"
                    aria-label="Phone Number"
                    name="phoneNumber"
                    disabled={otpVerify}
                    value={input.phoneNumber}
                    style={{
                      backgroundColor: otpVerify ? "#bababa82" : "#fff",
                    }}
                    onChange={handleChange}
                    className={
                      otpVerify ? "basic-addon2 disabled" : "basic-addon2 "
                    }
                    required
                  />
                  <InputGroup.Text
                    title={otpVerify ? "Phone Number Verified" : "Send OTP"}
                    id="basic-addon2"
                    className={otpVerify ? "disabled" : ""}
                    style={{
                      cursor: otpVerify ? "default" : "pointer",
                    }}
                  >
                    {otpVerify ? (
                      <FiCheckCircle size="24" color="green" />
                    ) : (
                      <div
                        onClick={(e) => RequestOTP(e)}
                        style={{ color: "green" }}
                      >
                        Send OTP
                        {/* <MdSendToMobile size="24" color="green" /> */}
                      </div>
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {expandForm && (
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1">
                      <FiKey size="20" color="#c4c4c4" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Code"
                      type="Number"
                      aria-label="code"
                      name="code"
                      onChange={verifyOTP}
                      maxLength="6"
                      minLength="6"
                      value={input.code}
                      className="basic-addon2"
                      required
                    />
                    <InputGroup.Text id="basic-addon2">
                      {!input.code ? (
                        <FiLoader size="20" color="var(--primary)" />
                      ) : otpVerify ? (
                        <FiCheckCircle size="24" color="green" />
                      ) : (
                        <FiX size="24" color="red" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                )}

                <InputGroup className="">
                  <button
                    type="submit"
                    disabled={loading || expandForm ? true : false}
                    className="mt-2 login-btn btn-ripple"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp; Loading...
                      </>
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                </InputGroup>
                <button
                  onClick={signUpWithGoogle}
                  className="google-signin-button mt-2 login-btn btn-ripple"
                >
                  <img
                    className="google-icon"
                    src={googleIcon}
                    alt="google icon"
                  />{" "}
                  Signup with Google
                </button>
              </Form>
            </>
          )}
        </div>
      </div>

      <div id="recaptcha-container"></div>
    </div>
  );
};

export default Signup;
