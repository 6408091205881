import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: "https://unicap-yhef.onrender.com",
  // baseURL: "http://localhost:2222",
});
// https://localhost:8080
// https://unicap.vercel.app
API.interceptors.request.use((req) => {
  if (Cookies.get("uniCap")) {
    req.headers["Authorization"] = `Bearer ${Cookies.get("uniCap")}`;
  }
  return req;
});

export const SocialLogin = async (data) => {
  const response = await API.post("/api/socialLogin", data);
  return response;
};

export const SocialRaiserLogin = async (data) => {
  const response = await API.post("/api/raiser/socialLogin", data);
    return response;
};

export const Login = (data) => API.post("/api/login", data);
export const RaiserLogin = (data) => API.post("/api/raiser/login", data);
export const Register = (data) => API.post("/api/register", data);
export const RaiserRegister = (data) => API.post("api/raiser/register", data);
export const ForgotPasswordReq = (data) =>
  API.post("/api/forgotPassword", data);
export const ForgotPasswordRiserReq = (data) =>
  API.post("/api/raiser/forgotPassword", data);
export const VerifyCode = (data) => API.post("/api/verifyCode", data);
export const VerifyCodeRiser = (data) =>
  API.post("/api/raiser/verifyCode", data);
export const ResetPassword = (data) => API.post("/api/resetPassword", data);
export const ResetPasswordRiser = (data) =>
  API.post("/api/raiser/resetPassword", data);
export const UpdateProfile = (data) => API.post("/api/updateProfile", data);
export const UpdateRaiserProfile = (data) =>
  API.post("/api/raiser/updateProfile", data);
export const RaiserPostListing = (data) =>
  API.post("/api/listing/createListing", data);
export const RaiserEditListing = (data, e) =>
  API.put(`/api/listing/editListing/${e}`, data);
export const GetMyListing = (e, select) =>
  API.get(`/api/listing/getListingByRaiserId/${e}/${select}`);
export const GetFinanceDetails = (e) =>
  API.get(`/api/listing/getListingById/${e}`);
export const getAllListing = (select, cat) =>
  API.get(`/api/listing/getAllListings?select=${select}&category=${cat}`);
export const allListings = (select, cat) =>
  API.get(`/api/listing/allListings?select=${select}&category=${cat}`);
export const searchListing = (search) =>
  API.get(`/api/searchListing?search=${search}`);
export const CreateOrder = (data) => API.post(`/api/payment/createOrder`, data);
export const MakePayment = (data) => API.post(`/api/payment/makePayment`, data);
export const AllOrders = () => API.get(`/api/payment/allOrders`);
export const OrderByID = (id) => API.get(`/api/payment/orderByListingId?${id}`);
export const MyTransactions = () => API.get(`/api/payment/allTransaction`);
export const MyDashboard = () => API.get(`/api/raiser/dashboard`);
export const UserNotifications = () =>
  API.get(`/api/notifications?pageNumber=1`);
export const RaiserNotifications = () =>
  API.get(`/api/raiser/notifications?pageNumber=1`);
export const AddSurvry = (data) => API.post("/api/survey", data);
export const quickApply = (data) => API.post("/api/apply", data);
