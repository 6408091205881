import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import demo from "../assets/profile.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../styles/header.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import {
    RaiserNotifications,
    searchListing,
    UpdateProfile,
    UpdateRaiserProfile,
    UserNotifications,
} from "../utils/apis";
import Popover from "@mui/material/Popover";
import { FaUserAlt } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { UserInfo, UserRole } from "../Redux/exportAllAction";
import { toast } from "react-toastify";
import { RaiserData, UserData } from ".";

const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};
export const getTimeDifference = (dateString) => {
    const now = new Date();
    const createdAt = new Date(dateString);
    const diffInMilliseconds = now.getTime() - createdAt.getTime();
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;

    if (diffInDays >= 1) {
        return Math.floor(diffInDays) + " days ago";
    } else if (diffInHours >= 1) {
        return Math.floor(diffInHours) + " hours ago";
    } else if (diffInMinutes >= 1) {
        return Math.floor(diffInMinutes) + " min ago";
    } else {
        return "Just now";
    }
};

const UserNav = ({ handleClick2, show, id }) => {
    const location = useLocation();
    return (
        <>
            <Link
                className={location.pathname === "/home" ? "active" : ""}
                to={"/home"}
            >
                Home
            </Link>
            <Link
                className={
                    location.pathname === "/my-portfolio" ? "active" : ""
                }
                to={"/my-portfolio"}
            >
                My Portfolio
            </Link>
            <Link
                className={location.pathname === "/my-transactions" ? "active" : ""}
                to={"/my-transactions"}
            >
                My Transactions
            </Link>
            <p className="notification-ui">
                <IoMdNotificationsOutline
                    aria-describedby={id}
                    onClick={handleClick2}
                    size="26"
                    fill={show ? "var(--primary)" : "#808080"}
                />
            </p>
        </>
    );
};

const RaiserNav = ({ handleClick2, show, id }) => {
    const location = useLocation();

    return (
        <>
            {/* <Link
      className={location.pathname === "/home" ? "active" : ""}
      to={"/home"}
    >
      Home
    </Link> */}
            <Link
                className={location.pathname === "/my-listing" ? "active" : ""}
                to={"/my-listing"}
            >
                My Listings
            </Link>
            <Link
                className={
                    location.pathname === "/post-listing" ? "active" : ""
                }
                to={"/post-listing"}
            >
                Post A New Listing
            </Link>
            <p className="notification-ui">
                <IoMdNotificationsOutline
                    aria-describedby={id}
                    onClick={handleClick2}
                    size="26"
                    fill={show ? "var(--primary)" : "#808080"}
                />
            </p>
        </>
    );
};

const UserHeader = () => {
    const [notifications, setNotifications] = useState(UserData);
    const dispatch = useDispatch();
    const Role = useSelector((state) => state.role);
    const { name, color, profile, companyName } = useSelector(
        (state) => state?.user
    );
    const [notificationLoading, setNotificationLoading] = useState(false);
    const [theme, setTheme] = useState(color);
    const [input, setInput] = useState("");
    const [search, setSearch] = useState([]);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const id = open2 ? "simple-popover" : undefined;

    const handleLogout = () => {
        Cookies.remove("uniCap");
        toast.success("Logout Successful", ToastOptions);
        localStorage.clear();
        dispatch(UserRole(""));
        dispatch(UserInfo(""));
        navigate("/");
    };

    async function updateProfile() {
        let API = Role === "raiser" ? UpdateRaiserProfile : UpdateProfile;
        try {
            const { data } = await API({ color: theme });

            dispatch(UserInfo(data.result));
        } catch (error) {
            const { response } = error;
            console.log(response);
        }
    }
    async function searchResult() {
        try {
            const { data } = await searchListing(input);
            setSearch(data.result);
            console.log(data.result);
        } catch (error) {
            const { response } = error;
            console.log(response);
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty("--primary", theme);
        updateProfile();
    }, [theme]);
    useEffect(() => {
        if (input) {
            searchResult();
        }
    }, [input]);

    const GoTo = (data) => {
        navigate(`/finance-detail/${data._id}`);
        setInput("");
    };

    const LoadNotification = async () => {
        setNotificationLoading(true);
        try {
            const APIcall =
                Role === "user" ? UserNotifications : RaiserNotifications;
            const { data } = await APIcall();
            setNotifications(data?.notification);
            setNotificationLoading(false);
        } catch (error) {
            const { response } = error;
            console.log(response);
            setNotificationLoading(false);
        }
    };

    useEffect(() => {
        LoadNotification();
    }, []);

    const handleNotificationRead = () => {
        if (Role === "user") {
            const newData = UserData.map((data) => {
                return { ...data, isRead: true };
            });
            setNotifications(newData);
        } else {
            const newData = RaiserData.map((data) => {
                return { ...data, isRead: true };
            });
            setNotifications(newData);
        }
    };

    return (
      <>
        <header className="user-header-wrapper  container-fluid ">
          <div className="align-items-end user-header d-flex justify-content-between container  py-3">
            <Link to={"/"}>
              <h1>
                <span>uni</span> cap
              </h1>
            </Link>
            <div className="search-bar">
              <IoSearchOutline size="26" color="#808080" />
              <input
                type="text"
                placeholder="Search For Anything..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              {input && (
                <div className="result">
                  {search.length > 0 ? (
                    search?.map((data) => {
                      return (
                        <div
                          key={data._id}
                          className="d-flex my-3"
                          onClick={() => GoTo(data)}
                        >
                          <img
                            src={data?.companyLogo?.url}
                            width="40px"
                            alt=""
                          />{" "}
                          <h4>{data.companyName} </h4>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <h5>No Listing Found with this input !! </h5>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="align-items-end user-header d-flex justify-content-end container">
              {Role === "raiser" ? (
                <RaiserNav handleClick2={handleClick2} show={open2} />
              ) : (
                <UserNav handleClick2={handleClick2} show={open2} />
              )}
              <div className="d-flex">
                <h4 onClick={handleClick} style={{ cursor: "pointer" }}>
                  {Role === "raiser" ? companyName : name}
                </h4>
                <span className="profile-img">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={profile || demo}
                    alt="profile"
                  />
                </span>
              </div>
            </div>
          </div>
        </header>

        {/* popover */}
        <Popover
          open={open}
          style={{ marginTop: "15px", padding: "20px" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          placement="bottom-end"
        >
          <ul className="m-0 popper">
            <Link
              className="text-black"
              style={{ textDecoration: "none" }}
              to={Role === "raiser" ? "/raiser-profile" : "/profile"}
            >
              <li onClick={() => setAnchorEl(null)}>
                <FaUserAlt size="20" /> Profile
              </li>
            </Link>
            <li onClick={handleLogout} style={{ cursor: "pointer" }}>
              <BiLogOutCircle size="20" /> Logout
            </li>
            <hr />
            <li className="color-box my-2">
              <span
                className={theme === "#ffa500" ? "box-1 active" : "box-1"}
                onClick={() => setTheme("#ffa500")}
              />
              <span
                className={theme === "#ff0000" ? "box-2 active" : "box-2"}
                onClick={() => setTheme("#ff0000")}
              />
              <span
                className={theme === "#1d4442" ? "box-3 active" : "box-3"}
                onClick={() => setTheme("#1d4442")}
              />
              <span
                className={theme === "#625AFF" ? "box-4 active" : "box-4"}
                onClick={() => setTheme("#625AFF")}
              />
              <span
                className={theme === "#ee82ee" ? "box-5 active" : "box-5"}
                onClick={() => setTheme("#ee82ee")}
              />
            </li>
          </ul>
        </Popover>
        <Popover
          open={open2}
          style={{ marginTop: "15px", padding: "20px" }}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          placement="bottom-end"
        >
          <div className="notification-ui-container">
            <div
              className={` notification-ui_dd 
                        `}
            >
              <div className="notification-ui_dd-header">
                <h6>Notifications</h6>
                {/* <p onClick={handleNotificationRead}>
                                Mark all as read
                            </p> */}
              </div>
              <div className="notification-ui_dd-content">
                {notificationLoading ? (
                  <p className={`notification-list`}>
                    <div className="notification-list-deatils">
                      {/* <img src={item.image} alt="" /> */}
                      <div>Loading...</div>
                    </div>
                  </p>
                ) : (
                  notifications?.map((item, index) => (
                    <p
                      key={item.id}
                      className={`notification-list ${
                        item.isRead && "notification-read"
                      }`}
                    >
                      <div className="notification-list-deatils">
                        {/* <img src={item.image} alt="" /> */}
                        <div>
                          <h6>{item.title}</h6>
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <p>{getTimeDifference(item.createdAt)}</p>
                    </p>
                  ))
                )}
              </div>
            </div>
          </div>
        </Popover>
      </>
    );
};

export default UserHeader;
