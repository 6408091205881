import React from "react";
import Logo from "../../../assets/cardLogo.png";
import { IoArrowBackSharp, IoAddSharp, IoRemoveSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "../../../styles/finance.css";
const AboutCompany = () => {
  const navigate = useNavigate();
  return (
    <div className="category-main mt-2 container">
      <div className="row">
        <div className="col-lg col-md-6 ">
          <div className="row">
            {/* <div className="col-lg-auto">
              <span
                style={{ marginRight: "1rem" }}
                onClick={() => navigate("/home")}
              >
                <IoArrowBackSharp size="28" />
              </span>
              <img src={Logo} alt="logo" />
            </div> */}
            {/* <div className="col">
              <h3>Navi</h3>
              <p>
                The Navi lending will provide instant loans of up to ₹5 lakh
                with Repayment tenures of up to 36 months.
              </p>
            </div> */}
          </div>
          <h3 className="mt-5">About Us</h3>
          <p>
            Navi is an Indian financial services company founded by Sachin
            Bansaland Ankit Agarwal in 2018. Navi operates in the space of
            digital Loans, home loans, mutual funds, health insurance and
            micro-loans. Its have $100 million revenue with $10 million profit.
            In December 2018, Sachin Bansal, after his exit from Flipkart, along
            with IIT-Delhi batch mate Ankit Agarwal, founded BACQ Acquisitions
            Private Limited which was later renamed as Navi Technologies Private
            Limited. The company has its headquarters in
          </p>
          <h3 className="mt-5">Industry</h3>
          <p>
            Navi is an Indian financial services company founded by Sachin
            Bansaland Ankit Agarwal in 2018. Navi operates in the space of
            digital Loans, home loans, mutual funds, health insurance and
            micro-loans. Its have $100 million revenue with $10 million profit.
            In December 2018, Sachin Bansal, after his exit from Flipkart, along
            with IIT-Delhi batch mate Ankit Agarwal, founded BACQ Acquisitions
            Private Limited which was later renamed as Navi Technologies Private
            Limited. The company has its headquarters in
          </p>
          <h3 className="mt-5">Unit economics Businesss Model</h3>
          <p>
            These senior secured and A rated fixed income bonds are issued by
            Navi Finserv Limited (“the Issuer”), a fully owned subsidiary of
            Navi Technologies (“Navi”) - founded by Sachin Bansal, Flipkart’s
            Co-Founder and ex-CEO. Navi is a digital platform that provides
            financial products and services including personal loans, home
            loans, health insurance and mutual funds.
          </p>

          <div className="finance-details-features">
            <div className="ccard-points">
              <div>
                <p>Minimum Investment</p>
                <h3>₹ 25,000</h3>
              </div>

              <div>
                <p>Face Value</p>
                <h3>₹ 2000</h3>
              </div>
              <div>
                <p>Pre-Tax YTM</p>
                <h3>8.45%</h3>
              </div>
              <div>
                <p>Date of Issue</p>
                <h3>8 Mar’ 22 </h3>
              </div>
            </div>
            <div className="ccard-points">
              <div>
                <p>Maturity Date</p>
                <h3>8 Mar’ 23</h3>
              </div>
              <div>
                <p>ISIN</p>
                <h3>INF98765432</h3>
              </div>
              <div>
                <p>Transaction Type</p>
                <h3>Secondary</h3>
              </div>
              <div>
                <p>Coupon Rate</p>
                <h3>8.45%</h3>
              </div>
            </div>
          </div>
          <h3 className="mt-5">Investment & Returns</h3>
          <p>
            Navi is an Indian financial services company founded by Sachin
            Bansaland Ankit Agarwal in 2018. Navi operates in the space of
            digital Loans, home loans, mutual funds, health insurance and
            micro-loans. Its have $100 million revenue with $10 million profit.
            In December 2018, Sachin Bansal, after his exit from Flipkart, along
            with IIT-Delhi batch mate Ankit Agarwal, founded BACQ Acquisitions
            Private Limited which was later renamed as Navi Technologies Private
            Limited. The company has its headquarters in
          </p>
          <h3 className="mt-5">Disclosures & Risks</h3>
          <h4>About the Sellers</h4>
          <p>
            This Bond purchase transaction is brought to you by Grip sourced
            from Oxyzo Financial Services Private Limited (“Oxyzo”). Oxyzo, the
            unicorn lending arm of Ofbusiness, is a leading lender to 3,000+
            SMEs across India, disbursing INR 4,000+ Cr per annum, and has INR
            2,000+ Cr as AUM. Oxyzo had subscribed to the Bonds during the
            primary issuance and will now be selling these Bonds directly to
            you.
          </p>
          <h4>Exit Options</h4>
          <p>
            This Bond purchase transaction is brought to you by Grip sourced
            from Oxyzo Financial Services Private Limited (“Oxyzo”). Oxyzo, the
            unicorn lending arm of Ofbusiness, is a leading lender to 3,000+
            SMEs across India, disbursing INR 4,000+ Cr per annum, and has INR
            2,000+ Cr as AUM. Oxyzo had subscribed to the Bonds during the
            primary issuance and will now be selling these Bonds directly to
            you.
          </p>
          <h4>Risk Factors</h4>
          <h6>1. Issuer/Credit Risk:</h6>
          <p>
            The bond is rated IND A/Stable by India Ratings (Fitch)- expressing
            that debt security carries a low risk of default. Although it is a
            capital-protected product, the issuer may not be able to pay back
            due to extreme circumstances such as bankruptcy.
          </p>
          <h6>2. Liquidity Risk:</h6>
          <p>
            This product is listed on exchanges but might not be actively
            traded. For an exit prior to the maturity date, you may have to find
            an alternate buyer.
          </p>
          <h6>3. Interest Rate Risk:</h6>
          <p>
            There is an inverse relationship between interest rates and the
            price of a bond. If interest rates increase, the price of a bond may
            fall, and you may have to incur losses if you plan to sell the
            instrument before the maturity date.
          </p>
          <h4>FAQs</h4>
          <h6>What are bonds?</h6>
          <p>
            Bonds are debt investment instruments with a fixed rate of return
            and maturity period. Bonds are issued by entities to raise capital
            in the form of loans that are used to achieve business objectives
            like starting a new project or scaling existing businesses For every
            bond issue, fixed interest payments (coupons) are made regularly on
            pre-specified dates. The principal loan amount (face value per unit
            of bond) is paid back on the pre-specified maturity date
          </p>

          <h6>What is the coupon rate?</h6>
          <p>
            The coupon rate is the interest rate paid on a bond by its Issuer
            for the term of the instrument. Once set at the issuance date, a
            bond's coupon rate remains unchanged and holders of the bond receive
            fixed interest payments at a predetermined frequency. For example: A
            bond is issued with face value of INR 1,000 and coupon rate of 12%.
            The bondholder will get 12% of INR 1000 = INR 120 annually. If the
            coupon is paid on a monthly basis, the bond holder will get INR 10
            per month.
          </p>

          <h6>What is Yield to Maturity?</h6>
          <p>
            Yield to maturity (YTM) is the total return anticipated on a bond if
            the bond is held until it matures. Yield to maturity is considered a
            long-term bond yield but is expressed as an annual rate. It takes
            into account all the interest payments and repayment of the original
            principal.
          </p>

          <h6>
            What is the difference between coupon rate and Yield to Maturity
            (YTM)?
          </h6>
          <p>
            The difference between coupon rate and yield arises because the
            market price of a bond is different from the face value. Since
            coupon payments are calculated on the face value, the coupon rate is
            different from the yield
            <br />
            The relation between price, coupon rate and yield is given below-
            <br />
            1. If coupon rate {`>`} YTM, the bond will sell above its par value
            (Premium)
            <br />
            2. If coupon Rate {`<`} YTM, the bond will sell below par value
            (Discount)
            <br />
            3. If coupon rate = YT, the bond will sell at the face value
          </p>
          <h6>What is accrued interest?</h6>
          <p>
            Accrued interest is the amount of bond interest that has accumulated
            since the last time a bond interest payment was made. The interest
            has been earned by the bondholder, but because coupons are only paid
            at set intervals the investor has not gained the money yet. If the
            present bondholder sells his bond, he must get the interest until
            the date of the sale For example, assume you receive an INR 1,000
            coupon on the 1st of every month. On the 15th of the month, you
            decide to sell the bond. Since you held the bond for 15 days,
            equivalent coupon amount, in this case INR 500 is earned by you.
            Hence, when you sell the bond, you would sell it at INR 500 over the
            clean price of the bond.
          </p>

          <h6>
            Can I withdraw my funds before the maturity period of the bond?
          </h6>
          <p>
            Bonds are tradable instruments. This means that there is no lock-in
            on your bond investment. If you want to sell the instrument before
            maturity, you can do so in the secondary market at the market price
            (market price may vary from par-value)
          </p>

          <h6>What is the effect of taxation on my bond investment?</h6>
          <p>
            The interest earned from the bonds is taxed as per marginal slab
            rate, and the maximum slab rate is 30 %. Appreciation of the bond
            price is considered as capital gain and taxed accordingly. If the
            bonds are held for the long term (more than 12 months for listed
            bonds), the capital gain tax will be 10 %. Short-term capital gain
            tax can be 5% to 30%.
          </p>

          <h6>Is it compulsory to do KYC for bond investment?</h6>
          <p>
            Yes, SEBI has mandated KYC requirements for purchase of the bonds to
            prevent money laundering activities.
          </p>

          <h6>
            Is it okay if I transfer the funds from someone else’s account?
          </h6>
          <p>
            No. It is mandated by SEBI to transfer funds from the bank account
            in the name of the bond applicant.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
