import React from "react";

import "../../../styles/payment.css";
import { Images } from "../../../assets";
import CustomButton from "../../../common/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentConfirm = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="payment-confirm-container">
                <div className="payment-confirm-option">
                    <div className="payment-confirm-content">
                        <h2 className={state.headingClass}>{state.heading}</h2>
                        <p>{state.message}</p>
                        <CustomButton
                            handleClick={
                                state.heading === "Payment successful"
                                    ? () => navigate("/home")
                                    : () => navigate("/home")
                            }
                            title={state.btnTitle}
                            btnClass={state.btnClass}
                        />
                    </div>
                    <div className="payment-confirm-image">
                        <img src={state.image} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentConfirm;
