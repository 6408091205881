import { BannerImage } from "../../../assets/banner";

export const ComparisonData = [
    {
        id: 1,
        image: BannerImage.navi,
        name: "NAVI",
        MinimumInvestment: "₹10,000",
        Currentprice: "₹18,830",
        TotalInvestment: "₹5,30,900",
        PreTaxReturn: "₹67,000",
        YieldToMaturity: "10.25%",
        TimeToMaturity: "12 months",
        CouponRate: "9.26%",
        InterestPayment: "Monthly",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 2,
        image: BannerImage.ETO,
        name: "ETO",
        MinimumInvestment: "₹15,000",
        Currentprice: "₹30,990",
        TotalInvestment: "₹9,00,000",
        PreTaxReturn: "₹92,820",
        YieldToMaturity: "9.02%",
        TimeToMaturity: "06 months",
        CouponRate: "4.83%",
        InterestPayment: "Yearly",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 3,
        image: BannerImage.HDFCBank,
        name: "HDFC Bank",
        MinimumInvestment: "₹20,000",
        Currentprice: "₹2,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 4,
        image: BannerImage.Intel,
        name: "Intel",
        MinimumInvestment: "₹50,000",
        Currentprice: "₹3,40,000",
        TotalInvestment: "₹5,93,000",
        PreTaxReturn: "₹63,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 5,
        image: BannerImage.Four,
        name: "Navi Bank",
        MinimumInvestment: "₹40,000",
        Currentprice: "₹4,40,000",
        TotalInvestment: "₹5,93,000",
        PreTaxReturn: "₹65,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 6,
        image: BannerImage.AmazonLogo,
        name: "Amazon",
        MinimumInvestment: "₹70,000",
        Currentprice: "₹8,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹82,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 7,
        image: BannerImage.Second,
        name: "What Bank",
        MinimumInvestment: "₹90,000",
        Currentprice: "₹6,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 8,
        image: BannerImage.six,
        name: "Yes Bank",
        MinimumInvestment: "₹90,000",
        Currentprice: "₹3,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 9,
        image: BannerImage.Yes,
        name: "ICIC Bank",
        MinimumInvestment: "₹10,000",
        Currentprice: "₹10,40,000",
        TotalInvestment: "₹5,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 10,
        image: BannerImage.unilever,
        name: "Unilever",
        MinimumInvestment: "₹60,000",
        Currentprice: "₹7,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 11,
        image: BannerImage.No,
        name: "Company Bank",
        MinimumInvestment: "₹20,000",
        Currentprice: "₹2,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
    {
        id: 12,
        image: BannerImage.HDFCBank,
        name: "HDFC Bank",
        MinimumInvestment: "₹20,000",
        Currentprice: "₹2,40,000",
        TotalInvestment: "₹4,93,000",
        PreTaxReturn: "₹62,782",
        YieldToMaturity: "8.82%",
        TimeToMaturity: "04 months",
        CouponRate: "8.52%",
        InterestPayment: "Quartely",
        TransactionType: "Secondary",
        Rating: "A",
        description:
            "Navi is the latest venture by Sachin Bansal (formerly co-founder of Flipkart, India’s largest e-commerce player.",
    },
];

export const ComparisonHeadings = {
    MinimumInvestment: "Minimum Investment",
    Currentprice: "Current price",
    TotalInvestment: "Total Investment",
    PreTaxReturn: "Pre-tax Return",
    YieldToMaturity: "Yield to Maturity",
    TimeToMaturity: "Time to Maturity",
    CouponRate: "Coupon Rate",
    InterestPayment: "Interest Payment",
    TransactionType: "Transaction Type",
    Rating: "Rating",
};
