import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./common/Header";
import Home from "./components/Home/Home";
import Signup from "./components/Auth/Signup";
import RaiserSignup from "./components/Auth/RaiserSignup";
import FinanceTypes from "./components/UserSide/Finances/FinanceTypes";
import FinanceDetail from "./components/UserSide/Finances/FinanceDetail";
import UserHeader from "./common/UserHeader";
import Footer from "./common/Footer";
import Wallet from "./components/UserSide/Wallet/Wallet";
import Profile from "./components/UserSide/Profile/Profile";
import Portfolio from "./components/UserSide/Portfolio/Portfolio";
import Cookies from "js-cookie";
import AboutCompany from "./components/RaiserSide/AboutCompany/AboutCompany";
import PostListing from "./components/RaiserSide/PostListing/PostListing";
import RProfile from "./components/RaiserSide/Profile/RProfile";
import MyListing from "./components/RaiserSide/MyListing/MyListing";
import EditListing from "./components/RaiserSide/PostListing/EditListing";
import OfferCard from "./components/OfferCard/OfferCard";
import { useEffect } from "react";
import InvestmentComparison from "./components/UserSide/InvestmentComparison/InvestmentComparison";
import ComparisonCompanyes from "./components/UserSide/InvestmentComparison/ComparisonCompanyes";
import PaymentGetway from "./components/UserSide/Payment/PaymentGetway";
import PaymentConfirm from "./components/UserSide/Payment/PaymentConfirm";
import JoinUs from "./components/FooterPages/JoinUs";
import PrivacyPolicy from "./components/FooterPages/PrivacyPolicy";
function App() {
    const location = useLocation();
    const token = Cookies.get("uniCap");
    const NavBar = token ? UserHeader : Header;

    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
    }, []);

    return (
        <div className="app-main">
            {location.pathname !== "/register" &&
                location.pathname !== "/raiser-register" &&
                location.pathname !== "/" && <NavBar />}
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="*" exact element={<Home />} />
                {/* <Route path="/raiser-register" exact element={<RaiserSignup />} />
        <Route path="/register" exact element={<Signup />} /> */}
                {/* UserSide */}
                <Route path="/home" exact element={<FinanceTypes />} />
                <Route path="/offers" exact element={<OfferCard />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route
                    path="/finance-detail/:id"
                    exact
                    element={<FinanceDetail />}
                />
                <Route path="/my-transactions" exact element={<Wallet />} />
                <Route path="/payment-getway" exact element={<PaymentGetway />} />
                <Route path="/payment-confirm" exact element={<PaymentConfirm />} />
                <Route path="/my-portfolio" exact element={<Portfolio />} />
                <Route path="/payment-confirm" exact element={<PaymentConfirm />} />
                <Route
                    path="/choose-company"
                    exact
                    element={<ComparisonCompanyes />}
                />
                <Route
                    path="/comparison"
                    exact
                    element={<InvestmentComparison />}
                />
                {/* RaiserSide */}
                <Route path="/about-company" exact element={<AboutCompany />} />
                <Route path="/post-listing" exact element={<PostListing />} />
                <Route
                    path="/edit-listing/:id"
                    exact
                    element={<EditListing />}
                />
                <Route path="/raiser-profile" exact element={<RProfile />} />
                <Route path="/my-listing" exact element={<MyListing />} />
                <Route path="/join-us" exact element={<JoinUs />} />
                <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            </Routes>
            {(location.pathname !== "/" && location.pathname !== "/join-us") && <Footer />}
        </div>
    );
}

export default App;
