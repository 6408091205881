import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrJ88XkwoEM9KevFfXmIZwBuuVWs7NHN8",
  authDomain: "unicap-b92d2.firebaseapp.com",
  projectId: "unicap-b92d2",
  storageBucket: "unicap-b92d2.appspot.com",
  messagingSenderId: "888220951558",
  appId: "1:888220951558:web:ee6d11c1477334cfd70f87",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export default app;
// export const db = getFirestore(app);

// export const googleAuthProvider = new GoogleAuthProvider();
// export const facebookAuthProvider = new FacebookAuthProvider();
// export const twitterAuthProvider = new TwitterAuthProvider();

export const auth = getAuth();

// const analytics = getAnalytics(app);
