import React, { useState } from "react";
import { BannerImage } from "../../assets/banner";
import "../../styles/service.css";
import exportIcon from "../../assets/dowload.svg";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../utils/firebase";
import { quickApply } from "../../utils/apis";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const Service = ({ first, second, third }) => {

  const [resume, setResume] = useState("");
  const [progress, setProgress] = useState("")
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",
    position: "",
    resume: "",
  });

    const handleSubmit = async(e) => {
      setLoader(true);
        e.preventDefault();
        const applyData = {
          ...data,
          resume: resume
        }
        try {
          const res = await quickApply(applyData);
          toast.success("Applied Successfully", ToastOptions);
          setData({
            name: "",
            number: "",
            email: "",
            position: "",
            resume: "",
          });
          setProgress("");
          setLoader(false);
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
    }

    const handleChange = (e) => {
      setData((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
    }


      function fileUploadHandler(e) {
        const file = e.target.files[0];
        if (!file) return;
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setProgress("0%");
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = `${Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )}%`;
            setProgress(prog);
          },
          (error) => console.log(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setResume(downloadURL)
            });
          }
        );
      }
    return (
      <div
        className={`service_container ${
          second && "flex-column flex-md-row-reverse"
        }`}
      >
        {first && (
          <>
            <div className="service-content align-items-center align-items-md-start justify-content-start">
              <h3 className="text-start">
                Curated by us,
                <br /> Tailor made for YOU
              </h3>
              <div className="service-content-card">
                <h6>Why</h6>
                <div className="service_icon">
                  <img src={BannerImage.Search} alt="" />
                </div>
                <p>
                  Create generational wealth, so together we could create a
                  profound impact on our community
                </p>
              </div>
            </div>
            <div className="service-image left-image justify-content-end align-items-end">
              <img src={BannerImage.FinanceLeft} alt="" />
            </div>
          </>
        )}
        {second && (
          <>
            <div className="service-content align-items-center  align-items-md-end  secondCard">
              <h3 className="text-end">
                Curated by us,
                <br /> Tailor made for YOU
              </h3>
              <div className="service-content-card">
                <h6>What</h6>
                <div className="service_icon">
                  <img src={BannerImage.question} alt="" />
                </div>
                <p>
                  Provide access to universal funds and alternate stable private
                  investments which are otherwise only accessible to a few.
                </p>
              </div>
            </div>
            <div className="service-image right-image justify-content-start align-items-start">
              <img src={BannerImage.FinanceRight} alt="" />
            </div>
          </>
        )}
        {third && (
          <>
            <div className="service-content align-items-center align-items-md-start justify-content-start" style={{height: "60%"}}>
              
              <form
                onSubmit={handleSubmit}
                className="d-flex flex-column align-items-start w-100 p-2"
                action=""
              >
                <label htmlFor="full_name" className="mb-3 service_quick">
                  Quick Apply
                </label>
                <input
                  className="w-100 service_input"
                  type="text"
                  placeholder="Full Name *"
                  required
                  name="name"
                  onChange={handleChange}
                  value={data?.name}
                />
                <input
                  className="w-100 service_input"
                  type="text"
                  placeholder="Email Address *"
                  required
                  name="email"
                  onChange={handleChange}
                  value={data?.email}
                />
                <input
                  className="w-100 service_input"
                  type="text"
                  placeholder="Phone Number *"
                  required
                  name="number"
                  onChange={handleChange}
                  value={data?.number}
                />
                <select
                  className="w-100 service_input2"
                  name="position"
                  id="position"
                  required
                  onChange={handleChange}
                  value={data?.position}
                >
                  <option className="service_select" value="">
                    Position applying for *
                  </option>
                  <option className="service_select" value="SDE 1">
                    SDE 1
                  </option>
                </select>
                <div className="upload-btn-wrapper w-100 h-65">
                  <button className="btn-2 d-flex align-items-center">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      Upload Resume * {progress}
                    </div>
                    <div className="downLoadButton d-flex align-items-center justify-content-center">
                      <img src={exportIcon} alt="download" />
                    </div>
                  </button>
                  <input
                    type="file"
                    onChange={fileUploadHandler}
                    name="resume"
                    required
                    value={data.resume}
                  />
                </div>

                <button type="submit" className="service_btn">
                  {loader ? (
                    <CircularProgress size="12px" style={{ color: "white" }} />
                  ) : (
                    "Send to Us"
                  )}
                </button>
              </form>
            </div>
            <div className="service-image left-image justify-content-end align-items-end">
              <img src={BannerImage.FinanceLeft} alt="" />
            </div>
          </>
        )}
      </div>
    );
};

export default Service;
