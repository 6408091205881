import axios from "axios";

const API = axios.create({
  // baseURL: "https://cors-fix-kylo.onrender.com/https://eve.idfy.com/v3/tasks",
  baseURL: "https://sandbox.surepass.io/api/v1/ocr",
});

API.interceptors.request.use((req) => {
  // req.headers["Content-Type"] = "application/json";
  req.headers["Content-Type"] = "application/x-www-form-urlencoded";
  // req.headers["account-id"] = process.env.REACT_APP_OCR_ACCOUNT_ID;
  // req.headers["api-key"] = process.env.REACT_APP_OCR_API_KEY;
  req.headers["Authorization"] = `Bearer ${process.env.REACT_APP_OCR_TOKEN_KEY}`;
  return req;
});

// export const ChequeOCR = (data) => API.post("/sync/extract/ind_cheque", data);
export const ChequeOCR = (data) => API.post("/cheque", data);
// export const PanOCR = (data) => API.post("/sync/extract/ind_pan", data);
export const PanOCR = (data) => API.post("/pan", data)
// export const AddharOCR = (data) => API.post("/sync/extract/ind_aadhaar", data);
export const AddharOCR = (data) => API.post("/aadhaar", data);
