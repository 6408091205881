import { Modal } from "react-bootstrap";

const CustomModal = ({ children, open, hide }) => {
    return (
        <Modal centered show={open} onHide={hide} size="lg" style={{zIndex: 99999999}} >
            {children}
        </Modal>
    );
};

export default CustomModal;
