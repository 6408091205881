import React, { useEffect, useState } from "react";
import Logo from "../../../assets/cardLogo.png";
import { IoArrowBackSharp, IoCalendarClearOutline, IoAddSharp, IoRemoveSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import cardlogo from "../../../assets/cardLogo.png";
import "../../../styles/finance.css";
import {
  CreateOrder,
  GetFinanceDetails,
  // MakePayment,
} from "../../../utils/apis";
import Loading from "../../../common/Loading";
// import Payment from "../../../utils/Payment/Payment";
import { toast } from "react-toastify";
import VisualizeReturn from "./VisualizeReturn";
import { useSelector } from "react-redux";
import { Images } from "../../../assets";


const FinanceDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [minUnit, setMinUnit] = useState(1)
  const Role = useSelector((state) => state.role);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(30);
  const [investLoading, setInvestLoading] = useState(false)
  const [returns, setReturn] = useState(0);
  // const [orderId, setOrderId] = useState("");
  const [open, setOpen] = useState(false);
  const id = useParams();


  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const GetListing = async () => {
    setLoading(true);
    try {
      const { data } = await GetFinanceDetails(id?.id);
      console.log(data.result)
      const totalUnit = Math.ceil(data?.result?.minimumInvestment / data?.result?.faceValue)
      setMinUnit(totalUnit)
      setAmount(totalUnit)
      setData(data.result);
      setLoading(false);
    } catch (error) {
      const { response } = error;
      console.log(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    GetListing();
  }, []);

  const investNow = async () => {
    setInvestLoading(true);
    if (user?.kycStatus === null || user?.kycStatus === "rejected" || user?.kycStatus === "pending") {
      setInvestLoading(false);
      return toast.info("Please Complete KYC Information First", ToastOptions);
    }
    try {
      const newdata = {
        total: amount * data?.faceValue,
        listingId: id.id
      }
      const res = await CreateOrder(newdata)
      setInvestLoading(false);
      navigate("/payment-confirm", {
        state: {
          heading: "Payment successful",
          headingClass: "paymentSucess",
          message:
            "Congratulations! Your investment payment has been successfully processed.",
          btnClass: "brandBgBtn",
          btnTitle: "Invest more",
          image: Images.PaymentAccept,
        },
      });

    } catch (error) {
      const { response } = error;
      console.log(error)
      setInvestLoading(false);
      toast.error(response?.data?.message, ToastOptions);
      // navigate("/payment-confirm", {
      //   state: {
      //     heading: "Payment failed",
      //     headingClass: "paymentFail",
      //     message:
      //       "Your payment was declined. Please try again or use a different payment method",
      //     btnClass: "paymentFailBtn",
      //     btnTitle: "Try again",
      //     image: Images.PaymentFail,
      //   },
      // });
    }
  }

  useEffect(() => {
    taxReturns();
  }, [amount]);

  const GetOrderId = async (id, amount) => {
    let listing = {
      listingId: id,
      total: amount,
    };
    try {
      const { data } = await CreateOrder(listing);
      console.log(data);
      toast.success(data.message, ToastOptions);
      navigate("/my-portfolio");
      // setOrderId(data.result._id);

      // Payment(data.result._id);
      //   MakePaymentId(data.result._id);
    } catch (error) {
      const { response } = error;
      console.log(response);
    }
  };
  // const MakePaymentId = async (id) => {
  //   try {
  //     const { data } = await MakePayment({ orderId: id });
  //     console.log(data);
  //     //  Payment(data.result._id);
  //   } catch (error) {
  //     const { response } = error;
  //     console.log(response);
  //   }
  // };

  const handleInvest = (id, amount) => {
    if (amount >= data?.minimumInvestment) {
      GetOrderId(id, Number(amount));
    } else {
      toast.error("Amount Cannot Be Less Than Minimum Amount", ToastOptions);
    }
  };


  const handlePayable = (Amount, faceValue) => {
    return Amount * faceValue
  }

  const taxReturns = () => {
    const nt = data?.timeToMaturity / 12;
    const investedamount = handlePayable(amount, data?.faceValue);
    const rn = data?.preTaxReturns / 100;
    const one = 1 + rn;
    const value = Math.pow(one, nt);
    const total = investedamount * value;
    console.log(total);
    return total
    // Returns=investedamount(1+(pretaxreturn/100))**month/12
  };

  function calculateInvestmentPeriod(startDate, endDate) {
    const diffInMs = endDate?.getTime() - startDate?.getTime();
    const msInYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
    const investmentPeriod = diffInMs / msInYear;
    const roundedInvestmentPeriod = Math.round(investmentPeriod * 100) / 100;
    return roundedInvestmentPeriod
  }

  const calculateFutureValue = (investmentAmount, interestRate, investmentPeriod) => {
    console.log(investmentAmount, interestRate, investmentPeriod)
    const futureValue = investmentAmount * Math.pow(1 + interestRate, investmentPeriod);
    const roundedFutureValue = Math.round(futureValue * 100) / 100;
    return roundedFutureValue.toLocaleString();
  }

  const handleTaxReturns = () => {
    const investmentAmount = handlePayable(amount, data?.faceValue);
    const interestRate = data?.preTaxReturns / 100
    const investmentPeriod = calculateInvestmentPeriod(new Date(data?.dateOfIssue), new Date(data?.maturityDate));
    const futureValue = calculateFutureValue(investmentAmount, interestRate, investmentPeriod)
    return futureValue
  }

  return (
    <>
      <div className="category-main container">
        {/* <div className="header-main">
          <a
            href="#details"
            className={location.hash === "#details" ? "active" : ""}
          >
            Assets Details
          </a>
          <a
            href="#About"
            className={location.hash === "#About" ? "active" : ""}
          >
            About the Sellers
          </a>
          <a href="#Risk" className={location.hash === "#Risk" ? "active" : ""}>
            Risk Factors
          </a>
          <a href="#FAQs" className={location.hash === "#FAQs" ? "active" : ""}>
            Common Question
          </a>
        </div> */}

        <div className="row position-relative">
          <div className="col-lg col-md-6 ">
            <div className="row">
              <div className="col-lg-auto">
                <span
                  title="go back"
                  style={{
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      Role === "user"
                        ? "/home"
                        : Role === "raiser"
                          ? "my-listing"
                          : "/offers"
                    )
                  }
                >
                  <IoArrowBackSharp size="28" />
                </span>
                <img
                  src={data?.companyLogo?.url || cardlogo}
                  alt="logo"
                  width="80px"
                  style={{ borderRadius: "4px" }}
                />
              </div>
              <div className="col">
                {data?.reason && (
                  <small style={{ color: "red" }}>
                    Reject Reason : {data?.reason}
                  </small>
                )}
                <h3>{data?.companyName}</h3>
                <p>{data?.companyDescription?.split(".")[0] + "."}</p>
              </div>
            </div>
            <h3 className="mt-3" id="details">
              About {data?.companyName}
            </h3>
            <p>{data?.aboutSeller}</p>
            <h3 className="mt-4">Assets Details</h3>
            <p>{data?.assetsDetail}</p>
            <h3 className="mt-4">Passive</h3>
            <p>{data?.passive}</p>

            <div className="finance-details-features">
              <div className="ccard-points">
                <div>
                  <p>Minimum Investment</p>
                  <h3>₹ {data?.minimumInvestment}</h3>
                </div>

                <div>
                  <p>Face Value</p>
                  <h3>₹ {data?.faceValue}</h3>
                </div>
                <div>
                  <p>Pre Tax Returns</p>
                  <h3>{data?.preTaxReturns}%</h3>
                </div>

                <div>
                  <p>Coupon Rate</p>
                  <h3>{data?.couponRate}%</h3>
                </div>
              </div>
              <div className="ccard-points">
                <div>
                  <p>Date of Issue</p>
                  <h3>{dateFormat(data?.dateOfIssue, "dd mmm yyyy")} </h3>
                </div>
                <div>
                  <p>Maturity Date</p>
                  <h3>{dateFormat(data?.maturityDate, "dd mmm yyyy")}</h3>
                </div>
                <div>
                  <p>ISIN</p>
                  <h3>{data?.ISIN}</h3>
                </div>
                <div>
                  <p>Transaction Type</p>
                  <h3>{data?.transactionType}</h3>
                </div>
              </div>
              <div className="ccard-points">
                <div>
                  <p>Company Preference</p>
                  <h3>{data?.companyPreference}</h3>
                </div>
                <div>
                  <p>Opportunity</p>
                  <h3>{data?.opportunity}</h3>
                </div>

                <div>
                  <p>Defence</p>
                  <h3>{data?.defence}</h3>
                </div>
                <div>
                  <p>Time To Maturity</p>
                  <h3>{data?.timeToMaturity} months</h3>
                </div>
              </div>
              <div className="ccard-points">
                <div>
                  <p>TotalInvestment</p>
                  <h3>{data?.totalInvestment}</h3>
                </div>
                <div>
                  <p>Time-Line</p>
                  <h3>{data?.timeLine}</h3>
                </div>
                <div>
                  <p>Maximum Return</p>
                  <h3>{data?.maximumReturn}</h3>
                </div>
                <div id="About">
                  <p>Market</p>
                  <h3>{data?.market}</h3>
                </div>
              </div>
              {Role === "raiser" && (
                <div className="ccard-points">
                  <div>
                    <p>Admin Commission(%)</p>
                    <h3>{data?.commision}</h3>
                  </div>
                </div>
              )}
            </div>

            <h3 className="mt-5">Disclosures & Risks</h3>
            <h4>About the Sellers</h4>
            <p>{data?.aboutSeller}</p>
            <h4 id="Risk">Exit Options</h4>
            <p>{data?.exitOptions}</p>
            <h4 id="FAQs">Risk Factors</h4>
            <p>{data?.riskFactors}</p>

            <h4>FAQs</h4>

            <p>{data?.FAQs}</p>

            <h4>Additional Info</h4>

            <p>{data.comments}</p>
          </div>
          {Role !== "raiser" && (
            <div className="col-lg-4 col-md-6">
              {/* <div className="invest-cart">
                <div className="d-flex justify-content-center">
                  <h3>I want to invest</h3>
                </div>
                <div className="d-flex justify-content-center">
                  <span className="rupess-input">₹</span>
                  <input
                    type="number"
                    placeholder="type amount"
                    className="input-amount"
                    value={amount}
                    onChange={(e) =>
                      setAmount(e.target.value)
                    }
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <p
                    className="amount"
                    onClick={() =>
                      setAmount(data?.minimumInvestment)
                    }
                  >
                    ₹ {data?.minimumInvestment} (min)
                  </p>
                  <p
                    className="amount"
                    onClick={() => setAmount(100000)}
                  >
                    ₹ 1,00,000
                  </p>
                  <p
                    className="amount"
                    onClick={() => setAmount(200000)}
                  >
                    ₹ 2,00,000
                  </p>
                </div>
                <small className="text-nowrap">
                  Don't worry about loosing intrest till deal
                  is fully complete
                </small>
                <hr />

                <h6 className="text-center">No of Unit</h6>
                <div className="add-cart-units">
                  <span>
                    <IoRemoveSharp />
                  </span>
                  <h4>50</h4>
                  <span>
                    <IoAddSharp />
                  </span>
                </div>
                <small>Minimum No of Units should be 30</small>

                <div className="d-flex justify-content-between">
                  <p>Pre-Tax Returns</p>
                  <h3>
                    ₹ {returns ? returns.toFixed(1) : 0}
                  </h3>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Tax Applicable </p>
                  <h3>{`${data?.preTaxReturns
                    ? data?.preTaxReturns
                    : 0
                    }%`}</h3>
                </div>

                <div
                  className="d-flex justify-content-center mt-2 "
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                >
                  <h3>
                    <IoCalendarClearOutline size="22" />
                    &nbsp; Visualise Returns
                  </h3>
                </div>
                <div className="invest-btn">
                  <button
                    className="login-btn btn-ripple"
                    onClick={() =>
                      handleInvest(data._id, amount)
                    }
                    style={{
                      background: "var(--theme-color)",
                    }}
                  >
                    Invest Now
                  </button>
                </div>
              </div> */}
              <div className="finaceDetailsInvestCard">
                <div
                  className=" p-3 w-100 home-modal-recommend-container "
                  style={{ position: "relative" }}
                >
                  <span className="d-flex justify-content-between px-2">
                    <p>Current Price</p>
                    <strong>₹ {data?.faceValue} / {data?.category === "Startup Equity" ? "Sq/ft" : "Unit"} </strong>
                  </span>

                  {/* <span className="d-flex justify-content-between px-2">
                    <p>Total Unit Left</p>
                    <strong>30000/ 50000</strong>
                  </span> */}

                  <hr />
                  <div className="d-flex flex-column align-items-center">
                    <strong className="text-center w-100 mt-2">
                      No. Of {data?.category === "Startup Equity" ? "Sq/ft" : "Unit"}
                    </strong>
                    <div className="d-flex justify-content-between w-100 my-4">
                      <button
                        disabled={amount === minUnit}
                        title={amount === minUnit && "Minimum Unit Reached"}
                        onClick={() => setAmount(amount - 1)}
                        className="modal-decrement-btn"
                      >
                        <span>-</span>
                      </button>
                      <button className="modal-increment-value-display">
                        {amount}
                      </button>
                      <button
                        onClick={() => setAmount(amount + 1)}
                        className="modal-increment-btn"
                      >
                        +
                      </button>
                    </div>
                    {amount < 30 && (
                      <p
                        className="text-danger mb-3"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Minimum No of Units should be 30
                      </p>
                    )}
                  </div>

                  <hr />
                  <span className="d-flex justify-content-between px-2">
                    <p>Amount Payable</p>
                    <strong>₹ {handlePayable(amount, data?.faceValue)} </strong>
                  </span>

                  {
                    data?.category !== "Startup Equity" &&
                    <span className="d-flex justify-content-between px-2">
                      <p>Pre-Tax Returns</p>
                      <strong>₹ {handleTaxReturns()}</strong>
                    </span>
                  }

                  {
                    data?.category !== "Startup Equity" &&
                    <div
                      className="d-flex justify-content-center mt-2 "
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpen(true)}
                    >
                      <h3 className="py-3">
                        <IoCalendarClearOutline size="22" />
                        &nbsp; Visualise Returns
                      </h3>
                    </div>
                  }

                  <button
                    disabled={investLoading}
                    onClick={investNow}
                    className="investNow-btn"
                  >
                    {investLoading ? "Loading..." : "Invest Now"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && <Loading />}
      <VisualizeReturn
        open={open}
        setOpen={setOpen}
        Time={data?.timeToMaturity ? data?.timeToMaturity : 36}
        // Total={handlePayable(amount, data?.faceValue)}
        Total={handleTaxReturns()}
        data={data}
        date={data.dateOfIssue}
        interest={data?.monthlyReturns ? data?.monthlyReturns : null}
      />
    </>
  );
};

export default FinanceDetail;
