import { USER_INFO, ROLE, UPDATE_INFO } from "../action/action";

export const UserInfo = (data) => ({
  type: USER_INFO,
  payload: data,
});
export const UserRole = (data) => ({
  type: ROLE,
  payload: data,
});
export const updateUser = (data) => (
  {
    type: UPDATE_INFO,
    payload: data
  }
)