import { useState } from "react";
import "../../styles/signin.css";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RaiserRegister, SocialRaiserLogin } from "../../utils/apis";
import { useDispatch } from "react-redux";
import { UserInfo, UserRole } from "../../Redux/exportAllAction";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  FiUser,
  FiLock,
  FiEye,
  FiEyeOff,
  FiLink,
  FiSmartphone,
  FiChevronDown,
  FiPhoneCall,
  FiKey,
  FiLoader,
  FiX,
  FiCheckCircle,
} from "react-icons/fi";
import { MdAlternateEmail } from "react-icons/md";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../utils/firebase";
import googleIcon from "../../../src/assets/googleicon.png";
// import Logo from "../../assets/logo.png";
// import Vector from "../../assets/signin.png";
// import Message from "../../assets/message.png";
const ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const RaiserSignup = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const countryCode = "+91";
  const [input, setInput] = useState({
    email: "",
    password: "",
    name: "",
    companyName: "",
    companyWebsite: "",
    fundingType: "",
    phoneNumber: countryCode,
    code: "",
  });
  const [otpVerify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };
  const navigate = useNavigate();
  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );
    }
  };

  // raiser sigup with google auth

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      const { displayName, email } = user;
      console.log(displayName, email);
      const { data } = await SocialRaiserLogin({
        name: displayName,
        email: email,
      });
      console.log(data);
      dispatch(UserInfo(data?.data?.user));
      dispatch(UserRole(data?.role));
      Cookies.set("uniCap", data?.token);
      console.log(data?.token);
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };

  const RequestOTP = (e) => {
    console.log(input.phoneNumber.length >= 10);
    e.preventDefault();
    if (input.phoneNumber.length >= 10) {
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, input?.phoneNumber, appVerifier)
        .then((confirmationResult) => {
          toast.success("OTP Sent Successfully", ToastOptions);
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error);
        });
    } else toast.warning("Phone number must be of 10 digit", ToastOptions);
  };
  const verifyOTP = (e) => {
    let no = e.target.value;
    setInput({ ...input, code: no });
    if (no.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(no)
        .then((result) => {
          // const user = result.user;
          setVerify(true);
          setExpandForm(false);
          window.recaptchaVerifier = "";
          // console.log(user);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if (!otpVerify) {
      RequestOTP(e);
    }
    if (otpVerify) {
      if (input.email && input.password && input.name && input.companyName) {
        setLoading(true);
        try {
          const { data } = await RaiserRegister(input);
          data && setLoading(false);
          dispatch(UserInfo(data.data.user));
          dispatch(UserRole(data.role));
          Cookies.set("uniCap", data?.token);
          navigate("/my-listing");
        } catch (error) {
          const { response } = error;
          console.log(response?.data);
          toast.error(response?.data?.message, ToastOptions);
          setLoading(false);
        }
      } else {
        toast.error("input field is required", ToastOptions);
      }
    } else {
      expandForm && toast.warning("Please verify Phone Nmber", ToastOptions);
    }
  };
  console.log(input);
  return (
    <div className="">
      <Form className="form-main" onSubmit={submitForm}>
        <InputGroup className="">
          <InputGroup.Text id="basic-addon1">
            <FiUser size="20" color="#c4c4c4" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Full Name"
            type="text"
            aria-label="Full Name"
            name="name"
            value={input.name}
            onChange={handleChange}
            className="basic-addon2"
            required
          />
        </InputGroup>
        <InputGroup className="">
          <InputGroup.Text id="basic-addon1">
            <MdAlternateEmail size="20" color="#c4c4c4" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Email"
            type="email"
            aria-label="Email"
            name="email"
            value={input.email}
            onChange={handleChange}
            className="basic-addon2"
            required
          />
        </InputGroup>
        <InputGroup className=" mb-0">
          <InputGroup.Text id="basic-addon1">
            <FiLock size="20" color="#c4c4c4" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Password"
            aria-label="password"
            type={show ? "text" : "password"}
            aria-describedby="basic-addon1"
            name="password"
            value={input.password}
            onChange={handleChange}
            required
            id="basic-addon3"
          />
          <InputGroup.Text
            className="basic-addon2"
            onClick={() => (show ? setShow(false) : setShow(true))}
          >
            {show ? (
              <FiEyeOff
                size="20"
                color="#c4c4c4"
                title="hide"
                cursor="pointer"
              />
            ) : (
              <FiEye size="20" color="#c4c4c4" title="show" cursor="pointer" />
            )}
          </InputGroup.Text>
        </InputGroup>
        <InputGroup className="">
          <InputGroup.Text
            id="basic-addon1"
            className={otpVerify ? "disabled" : ""}
          >
            <FiPhoneCall size="20" color="#c4c4c4" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Phone Number"
            type="tel"
            aria-label="Phone Number"
            name="phoneNumber"
            disabled={otpVerify}
            value={input.phoneNumber}
            style={{
              backgroundColor: otpVerify ? "#bababa82" : "#fff",
            }}
            onChange={handleChange}
            className={otpVerify ? "basic-addon2 disabled" : "basic-addon2 "}
            required
          />
          <InputGroup.Text
            title={otpVerify ? "Phone Number Verified" : "Send OTP"}
            id="basic-addon2"
            className={otpVerify ? "disabled" : ""}
            style={{
              cursor: otpVerify ? "default" : "pointer",
            }}
          >
            {otpVerify ? (
              <FiCheckCircle size="24" color="green" />
            ) : (
              <div onClick={(e) => RequestOTP(e)} style={{ color: "green" }}>
                Send OTP
                {/* <MdSendToMobile size="24" color="green" /> */}
              </div>
            )}
          </InputGroup.Text>
        </InputGroup>
        {expandForm && (
          <InputGroup className="">
            <InputGroup.Text id="basic-addon1">
              <FiKey size="20" color="#c4c4c4" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Code"
              type="number"
              aria-label="code"
              name="code"
              onChange={verifyOTP}
              maxLength="6"
              minLength="6"
              value={input.code}
              className="basic-addon2"
              required
            />
            <InputGroup.Text id="basic-addon2">
              {!input.code ? (
                <FiLoader size="20" color="var(--primary)" />
              ) : otpVerify ? (
                <FiCheckCircle size="24" color="green" />
              ) : (
                <FiX size="24" color="red" />
              )}
            </InputGroup.Text>
          </InputGroup>
        )}

        <div className="row">
          <InputGroup className=" col">
            <InputGroup.Text id="basic-addon1">
              <FiSmartphone size="20" color="#c4c4c4" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Company Name"
              type="text"
              aria-label="companyName"
              name="companyName"
              value={input.companyName}
              onChange={handleChange}
              className="basic-addon2"
              required
            />
          </InputGroup>
          <InputGroup className="col">
            <InputGroup.Text id="basic-addon1">
              <FiLink size="20" color="#c4c4c4" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Website"
              type="url"
              aria-label="companyWebsite"
              name="companyWebsite"
              value={input.companyWebsite}
              onChange={handleChange}
              className="basic-addon2"
              required
            />
          </InputGroup>
        </div>
        <InputGroup className="">
          <InputGroup.Text id="basic-addon1">
            <FiChevronDown size="20" color="#c4c4c4" />
          </InputGroup.Text>
          <Form.Select
            // defaultValue={"default"}
            className="basic-addon2"
            size="lg"
            required
            onChange={(e) =>
              setInput({ ...input, fundingType: e.target.value })
            }
          >
            <option value="" selected disabled>
              Which type of finance you are looking for ?
            </option>
            <option value="Lease financing for movable fixed assets">
              Lease financing for movable fixed assets
            </option>
            <option value="Inventory financing or working captial gap financing">
              Inventory financing or working captial gap financing
            </option>
            <option value="Debt/Loan">Debt/Loan</option>
            <option value="Equity Capital">Equity Capital</option>
          </Form.Select>
          {/* <Form.Control
                placeholder="Email"
                type="email"
                aria-label="Email"
                name="email"
                value={input.email}
                onChange={handleChange}
                className="basic-addon2"
                required
              /> */}
        </InputGroup>
        <InputGroup className="">
          <button
            type="submit"
            disabled={loading || expandForm ? true : false}
            className="login-btn btn-ripple"
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </>
            ) : (
              "Sign Up"
            )}
          </button>
        </InputGroup>
          <button
            onClick={signUpWithGoogle}
            className="google-signin-button mt-2 login-btn btn-ripple"
          >
            <img className="google-icon" src={googleIcon} alt="google icon" />{" "}
            Signup with Google
          </button>
      </Form>
      {/* </div> */}
      {/* </div> */}
      {/* <div className="m-box">
        <img src={Message} alt="box" width="100%" />
      </div> */}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default RaiserSignup;
