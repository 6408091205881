import React from "react";
import Logo from "../assets/cardLogo.png";
// import { ProgressBar } from "react-bootstrap";
import { IoChevronForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const CategoryCard = ({ data }) => {
    const { _id } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const calcPercentage = () => {
        const Total = 100 / data?.totalInvestment;
        const percent = data?.currentInvestedAmount * Total;
        return isNaN(percent) ? 0 : percent;
    };


    console.log(calcPercentage())
    return (
        <div className="ccard-main">
            <div className="row">
                <div className="col-lg-2">
                    <img
                        src={data?.companyLogo?.url || Logo}
                        alt="logo"
                        width="80px"
                        style={{ borderRadius: "4px" }}
                    />
                </div>
                <div className="col-lg">
                    {data?.reason && (
                        <small style={{ color: "red" }}>
                            Reject Reason : {data?.reason}
                        </small>
                    )}
                    <h3>{data?.companyName}</h3>
                    <p>{data?.companyDescription?.slice(0, 200)}...</p>
                </div>
                <div className="col-lg-3">
                    <h5>{data?.category}</h5>
                    <div className="progress-main">
                        <div className="progress-bg">
                            <div>
                                <span>
                                    <CircularProgressbar
                                        value={calcPercentage()}
                                        text={`${calcPercentage().toFixed(2)}%`}
                                        styles={buildStyles({
                                            textColor: "var(--primary)",
                                            pathColor: "var(--primary)",
                                            trailColor: "#e3e6e8",
                                        })}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <h6>{data.currentInvestedAmount}&nbsp;</h6>
                            <h4>/ {data.totalInvestment}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="ccard-points">
                <div style={{ width: "33%", textAlign: "center" }}>
                    <p>Face Value</p>
                    <h3 style={{ fontSize: "14px" }}>{data?.faceValue}</h3>
                </div>

                <div style={{ width: "33%", textAlign: "center" }}>
                    <p>Time to maturity </p>
                    <h3 style={{ fontSize: "14px" }}>
                        {data?.timeToMaturity} months
                    </h3>
                </div>
                <div style={{ width: "33%", textAlign: "center" }}>
                    <p>Min Investment</p>
                    <h3 style={{ fontSize: "14px" }}>
                        {data?.minimumInvestment}
                    </h3>
                </div>
            </div>
            <div className="ccard-points">
                <div>
                    <p>Coupon Rate</p>
                    <h3>{data?.couponRate}%</h3>
                </div>

                <div>
                    <p>Pre Tax Returns</p>
                    <h3>{data?.preTaxReturns}</h3>
                </div>
                <div>
                    <p>ISIN</p>
                    <h3>{data?.ISIN}</h3>
                </div>
            </div>
            {/* 
      <ProgressBar now={80} />
      <div className="progress-text">
        <span>
          <small>1.5 Cr</small> / 2 Cr
        </span>
        <span>75%</span>
      </div> */}
            <div
                className="more-details"
                title={
                    data.raiserId === _id && data.isDrafted
                        ? "Edit Draft"
                        : "Open Detail"
                }
                onClick={() =>
                    data.raiserId === _id && data.isDrafted
                        ? navigate(`/edit-listing/${data?._id}`)
                        : navigate(`/finance-detail/${data?._id}`)
                }
            >
                <hr />
                {data.raiserId === _id && data.isDrafted ? (
                    <>Edit Details</>
                ) : (
                    <>
                        More Details <IoChevronForwardOutline />
                    </>
                )}
            </div>
        </div>
    );
};

export default CategoryCard;
