import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import "../../../styles/payment.css";
import { CardInfo } from ".";
import { useState } from "react";
import CheckBox from "./CheckBox";
import CustomButton from "../../../common/CustomButton";
import { Images } from "../../../assets";

const PaymentGetway = () => {
    const [defaultCard, setDefaultCard] = useState(1);
    const navigate = useNavigate();
    const handleCheckbox = (e, id) => {
        setDefaultCard(id);
    };

    const handlePayment = () => {
        if (defaultCard === 5) {
            navigate("/payment-confirm", {
                state: {
                    heading: "Payment failed",
                    headingClass: "paymentFail",
                    message:
                        "Your payment was declined. Please try again or use a different payment method",
                    btnClass: "paymentFailBtn",
                    btnTitle: "Try again",
                    image: Images.PaymentFail,
                },
            });
        } else {
            navigate("/payment-confirm", {
                state: {
                    heading: "Payment successful",
                    headingClass: "paymentSucess",
                    message:
                        "Congratulations! Your investment payment has been successfully processed.",
                    btnClass: "brandBgBtn",
                    btnTitle: "Invest more",
                    image: Images.PaymentAccept,
                },
            });
        }
    };

    return (
        <div className="container">
            <div className="payment-getway-container">
                <div className="payment-getway-title">
                    <BiArrowBack onClick={() => navigate("/home")} />
                    <h3>Choose payment option</h3>
                </div>
                <div className="payment-getway-card-container">
                    {CardInfo.map((card) => (
                        <div
                            key={card.id}
                            className={`payment-getway-card ${
                                card.id === defaultCard && "cardActive"
                            }`}
                        >
                            <div className="payment-getway-card-header">
                                <img src={card.image} alt="" />
                                <div className="payment-getway-card-info">
                                    <h6>{card.name}</h6>
                                    <p>Expiry {card.expiry}</p>
                                    <div className="payment-getway-card-action">
                                        <p
                                            onClick={(e) =>
                                                handleCheckbox(e, card.id)
                                            }
                                        >
                                            Set as default
                                        </p>
                                        <p>Edit</p>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-getway-checkbox">
                                <CheckBox
                                    handleCheckbox={handleCheckbox}
                                    id={card.id}
                                    defaultCard={defaultCard}
                                />
                            </div>
                        </div>
                    ))}
                    <div className="payment-getway-btn">
                        <div>
                            <CustomButton
                                title={"Discard"}
                                // handleClick={() => handlePayment(false)}
                                btnClass="whiteBgBtn me-3"
                            />
                            <CustomButton
                                handleClick={handlePayment}
                                title={"Proceed to Pay"}
                                btnClass="brandBgBtn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentGetway;
