import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FiLock, FiEye, FiEyeOff } from "react-icons/fi";

import { Images } from "../../assets";
import "../../styles/forgotpassword.css";
import { toast } from "react-toastify";
import {
    ForgotPasswordReq,
    ForgotPasswordRiserReq,
    ResetPassword,
    ResetPasswordRiser,
    VerifyCode,
    VerifyCodeRiser,
} from "../../utils/apis";

const ForgotPassword = ({
    setForgotPassword,
    forgotPassword,
    handleClose,
    setForgotscreen,
}) => {
    const [show, setShow] = useState(false);
    const [counter, setCounter] = useState(30);
    const [isOtpSend, setIsOtpSend] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [inputData, setInputData] = useState({
        email: "",
        code: "",
        password: "",
        confirmPassword: "",
    });
    const navigate = useNavigate();
    const handleChange = (e, type) => {
        console.log(e);
        if (type === "code") {
            setInputData({ ...inputData, [type]: e });
        } else if (type === "password") {
            setInputData({ ...inputData, [e.target.name]: e.target.value });
        } else {
            setInputData({ ...inputData, [type]: e.target.value });
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (forgotPassword.forgotScreen) {
            if (!inputData.email) {
                showToast("error", "Please Enter Your Email");
                setLoading(false);
                return;
            }
            try {
                const data = {
                    email: inputData?.email,
                };

                let res;

                if (check) {
                    res = await ForgotPasswordRiserReq(data);
                } else {
                    res = await ForgotPasswordReq(data);
                }

                if (res.status === 200) {
                    showToast("success", "OTP Sent To Your Email");
                    setInterval(() => {
                        setCounter((counter) => counter - 1);
                    }, 1000);
                    clearInterval(counter === 0);
                    setIsOtpSend(true);
                    setLoading(false);
                    setForgotPassword({
                        ...forgotPassword,
                        forgotScreen: false,
                        otpScreen: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                showToast("error", error?.response?.data.message);
            }
        }
        if (forgotPassword.otpScreen) {
            if (!inputData.code || inputData?.code?.length < 4) {
                setLoading(false);
                showToast("error", "Please Enter OTP Code");
                return;
            }
            try {
                const data = {
                    email: inputData?.email,
                    code: Number(inputData?.code),
                };
                let res;

                if (check) {
                    res = await VerifyCodeRiser(data);
                } else {
                    res = await VerifyCode(data);
                }

                if (res.status === 200) {
                    setLoading(false);
                    clearInterval(counter === 0);
                    showToast("success", "OTP Verified");
                    setForgotPassword({
                        ...forgotPassword,
                        otpScreen: false,
                        resetScreen: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                showToast("error", error?.response?.data.message);
            }
        }
        if (forgotPassword.resetScreen) {
            if (!inputData.password) {
                showToast("error", "Password Enter Password");
                setLoading(false);
                return;
            }
            if (inputData.password !== inputData.confirmPassword) {
                showToast("error", "Password Not Matched");
                setLoading(false);
                return;
            }
            try {
                const data = {
                    email: inputData?.email,
                    password: inputData?.password,
                };

                let res;
                if (check) {
                    res = await ResetPasswordRiser(data);
                } else {
                    res = await ResetPassword(data);
                }

                if (res.status === 200) {
                    setLoading(false);
                    showToast("success", "Password Reset Successful");
                    setForgotPassword({
                        ...forgotPassword,
                        resetScreen: false,
                        successScreen: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                showToast("error", error?.response?.data.message);
            }
        }
        if (forgotPassword.successScreen) {
            setTimeout(() => {
                setLoading(false);
                setForgotPassword({
                    ...forgotPassword,
                    resetScreen: false,
                    successScreen: false,
                });
                setForgotscreen(false);
            }, 1000);
        }
    };

    const resendOtp = () => {
        showToast("success", "OTP sent to your email");
        setIsOtpSend(true);
        setInterval(() => {
            setCounter((counter) => counter - 1);
        }, 1000);
    };

    const showToast = (type, message) => {
        if (type === "success") {
            toast.success(message);
        }
        if (type === "error") {
            toast.error(message);
        }
    };

    return (
        <>
            <div className="forgotmain-main">
                <div className="my-1 modalLogo" onClick={() => navigate("/")}>
                    <h1>
                        <span>uni</span> cap
                    </h1>
                    <div className="closeIcon">
                        <HighlightOffIcon
                            className="close"
                            onClick={handleClose}
                        />
                    </div>
                </div>
                {forgotPassword.forgotScreen && (
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img
                                src={Images?.ForgotPassword}
                                alt="vector"
                                width="90%"
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <h3>Reset password</h3>
                            <p>
                                To proceed with the password reset, we need to
                                verify your email address
                            </p>
                            <Form className="form-main" onSubmit={submitForm}>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdOutlineAlternateEmail
                                            size="20"
                                            color="#c4c4c4"
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Email"
                                        type="text"
                                        aria-label="email"
                                        // disabled={loading ? true : false}
                                        name="email"
                                        onChange={(e) =>
                                            handleChange(e, "email")
                                        }
                                        className="basic-addon2"
                                        required
                                    />
                                </InputGroup>
                                <InputGroup className="my-4">
                                    <button
                                        type="submit"
                                        disabled={loading ? true : false}
                                        className="login-btn btn-ripple"
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        ) : (
                                            "Verify Email"
                                        )}
                                    </button>
                                </InputGroup>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Reset as Fund-Raiser"
                                    checked={check}
                                    value={check}
                                    onChange={() =>
                                        check ? setCheck(false) : setCheck(true)
                                    }
                                />
                                <p onClick={() => {
                                    setForgotPassword({
                                        ...forgotPassword,
                                        resetScreen: false,
                                        successScreen: false,
                                    });
                                    setForgotscreen(false);
                                }}>
                                    Back to login
                                </p>
                            </Form>
                        </div>
                    </div>
                )}
                {forgotPassword.otpScreen && (
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img src={Images?.OTP} alt="vector" width="90%" />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <h3>OTP Verification</h3>
                            <p>
                                Please enter the OTP sent to your email address
                            </p>
                            <Form className="form-main" onSubmit={submitForm}>
                                <OTPInput
                                    className="otp-form"
                                    onChange={(e) => handleChange(e, "code")}
                                    autoComplete={false}
                                    value={inputData?.code}
                                    autoFocus
                                    OTPLength={4}
                                    otpType="number"
                                    disabled={false}
                                />
                                <InputGroup className="my-4">
                                    <button
                                        type="submit"
                                        disabled={loading ? true : false}
                                        className="login-btn btn-ripple"
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        ) : (
                                            "Continue"
                                        )}
                                    </button>
                                </InputGroup>
                                {counter >= 0 && isOtpSend ? (
                                    <span>00:{counter} Sec</span>
                                ) : (
                                    <span
                                        onClick={resendOtp}
                                        className="resend-otp"
                                    >
                                        Resend OTP
                                    </span>
                                )}
                            </Form>
                        </div>
                    </div>
                )}
                {forgotPassword.resetScreen && (
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img
                                src={Images?.ResetPassword}
                                alt="vector"
                                width="90%"
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <h3>Reset password</h3>
                            <Form className="form-main" onSubmit={submitForm}>
                                <InputGroup className="mb-0">
                                    <InputGroup.Text id="basic-addon1">
                                        <FiLock size="20" color="#c4c4c4" />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Password"
                                        aria-label="Password"
                                        type={show ? "text" : "password"}
                                        aria-describedby="basic-addon1"
                                        name="password"
                                        disabled={loading ? true : false}
                                        value={inputData.password}
                                        onChange={(e) =>
                                            handleChange(e, "password")
                                        }
                                        required
                                        id="basic-addon3"
                                    />
                                    <InputGroup.Text
                                        className="basic-addon2"
                                        onClick={() => setShow(!show)}
                                    >
                                        {show ? (
                                            <FiEyeOff
                                                size="20"
                                                color="#c4c4c4"
                                                title="hide"
                                                cursor="pointer"
                                            />
                                        ) : (
                                            <FiEye
                                                size="20"
                                                color="#c4c4c4"
                                                title="show"
                                                cursor="pointer"
                                            />
                                        )}
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-0">
                                    <InputGroup.Text id="basic-addon1">
                                        <FiLock size="20" color="#c4c4c4" />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Confirm Password"
                                        aria-label="Password"
                                        type={showConfirm ? "text" : "password"}
                                        aria-describedby="basic-addon1"
                                        name="confirmPassword"
                                        disabled={loading ? true : false}
                                        value={inputData.confirmPassword}
                                        onChange={(e) =>
                                            handleChange(e, "password")
                                        }
                                        required
                                        id="basic-addon3"
                                    />
                                    <InputGroup.Text
                                        className="basic-addon2"
                                        onClick={() =>
                                            setShowConfirm(!showConfirm)
                                        }
                                    >
                                        {showConfirm ? (
                                            <FiEyeOff
                                                size="20"
                                                color="#c4c4c4"
                                                title="hide"
                                                cursor="pointer"
                                            />
                                        ) : (
                                            <FiEye
                                                size="20"
                                                color="#c4c4c4"
                                                title="show"
                                                cursor="pointer"
                                            />
                                        )}
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="my-4">
                                    <button
                                        type="submit"
                                        disabled={loading ? true : false}
                                        className="login-btn btn-ripple"
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        ) : (
                                            "Continue"
                                        )}
                                    </button>
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                )}
                {forgotPassword.successScreen && (
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img
                                src={Images?.successful}
                                alt="vector"
                                width="90%"
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <h3>Password Reset Successful</h3>
                            <p>
                                Now you can use your new password to login to
                                your account
                            </p>
                            <Form className="form-main" onSubmit={submitForm}>
                                <InputGroup className="my-4">
                                    <button
                                        type="submit"
                                        disabled={loading ? true : false}
                                        className="login-btn btn-ripple"
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        ) : (
                                            "Login"
                                        )}
                                    </button>
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ForgotPassword;
