import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { AiOutlineArrowDown } from "react-icons/ai";
import "../../styles/home.css";
import v1 from "../../assets/v1.png";
import v2 from "../../assets/v2.png";
import v3 from "../../assets/v3.png";
import pic1 from "../../assets/pic2.png";
import pic2 from "../../assets/pic1.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import UniqueSlider from "./UniqueSlider";
import WhatUnicaps from "./WhatUnicaps";
import Backdrop from "@mui/material/Backdrop";
import Modal from "react-bootstrap/Modal";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";
import HomeModal from "./HomeModal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../common/UserHeader";
import Header from "../../common/Header";
import SouthIcon from "@mui/icons-material/South";
import Footer from "../../common/Footer";
import { useIntersection } from "../../utils/Viewport";
import Banner from "./Banner";
import { BannerImage } from "../../assets/banner";
import Service from "./Service";
import "../../styles/customModal.css";
import { AiOutlineClose } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  borderRadius: "20px",
};

const HomeTitle = [
  {
    id: 1,
    title: `"Investing  puts money to work. The only reason to save money is to invest it." -`,
    name: "Grant Cardone",
  },
  {
    id: 2,
    title: `"How many millionaires do you know who have become wealthy by investing in savings accounts? I rest my case.” –`,
    name: "Robert G. Allen",
  },
  {
    id: 3,
    title: `"When it Comes to investing , we want out money to grow with the highest rates of return, and the lowest risk possible.While there are no shortcuts to getting rich, there are smart ways to go about it." -`,
    name: "Phil Town",
  },
];

function Home() {
  const [openlogin, setOpenlogin] = useState(false);
  const [isloginOpen, setIsloginOpen] = useState(true);
  const [scrollOff, setScrollOff] = useState(false);
  const [scrollTop, setscrollTop] = useState(0);
  const [count, setCount] = useState(0);
  const [Questionare, setQuestionare] = useState(1);
  
  const [Fill, setFill] = useState(0);
  const [popupModal, setpopupModal] = useState(true);
  const [homeTitle, sethomeTitle] = useState(HomeTitle[1]);
  const [animation, setanimation] = useState(false);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);

  const shouldShowModal = () => {
    const modalShown = localStorage.getItem("modalShown");
    return modalShown === null || modalShown === "false";
  }
  const [showModal, setshowModal] = useState(shouldShowModal());

  const handleClose = () => {
    localStorage.setItem("modalShown", true);
    setOpen(false);
  };

  const ref = useRef();
  const Role = useSelector((state) => state.role);
  const token = Cookies.get("uniCap");
  const onscroll = () => {
    const winScroll = document.getElementById("main11").scrollTop;
    const height =
      document.getElementById("main11").scrollHeight -
      document.getElementById("main11").clientHeight;
    //slider
    if (winScroll > 642 && winScroll < 1240) {
      const randomNumber = Math.floor(Math.random() * 3);
      sethomeTitle(HomeTitle[randomNumber]);
      setscrollTop(0);
    } else if (winScroll > 1242 && winScroll < 1800) {
      const randomNumber = Math.floor(Math.random() * 3);
      sethomeTitle(HomeTitle[randomNumber]);
      setscrollTop(25);
    }
  };

  const inView = useIntersection(ref, "-20px");

  useEffect(() => {
    if (token) {
      Role === "user" ? navigate("/home") : navigate("/my-listing");
    }
    document.getElementById("main11").addEventListener("scroll", onscroll);

    return () => window.removeEventListener("scroll", onscroll);
  }, []);

  useEffect(() => {
    let val = JSON.parse(localStorage.getItem("modalopen"));
    AOS.init();
    if (val == null && showModal == true) {
    } else if (val == true) {
      clearTimeout();
    }
  }, []);

  const NavBar = token ? UserHeader : Header;

  const sectionRef = useRef(null);
  const thirdRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(sectionRef.current);
    observer.observe(thirdRef.current);
    return () => observer.disconnect();
  }, []);

  function handleIntersection(entries) {
    entries.forEach((entry) => {
      if (
        entry.isIntersecting &&
        entry.target.className === "banner_container container"
      ) {
        setanimation(true);
      } else if (
        entry.isIntersecting &&
        entry.target.className === "home-third-section"
      ) {
        setshowModal(true);
        setScrollOff(true);
      } else {
        setshowModal(false);
        setScrollOff(false);
        setanimation(false);
      }
    });
  }

  useEffect(() => {
    if (showModal && count === 0) {
      setTimeout(() => {
        handleOpen();
        setCount(count + 1);
      }, 5000);
    }
  }, [showModal, isloginOpen, count]);

  useEffect(() => {
    if (scrollOff) {
      const section = document.getElementsByTagName("section");
      for (let i = 0; i < section.length; i++) {
        section[i].style.scrollSnapAlign = "none";
      }
    } else {
      const section = document.getElementsByTagName("section");
      for (let i = 0; i < section.length; i++) {
        section[i].style.scrollSnapAlign = "start";
      }
    }
  }, [scrollOff]);

  const handleModalwidth = (question) => {
    switch (question) {
      case 10:
      case 11:
      case 12:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <article ref={ref} className="home-wrapper scroll-container" id="main11">
        <NavBar
          setOpenlogin={setOpenlogin}
          openlogin={openlogin}
          style={{ width: "97%", zIndex: 99999 }}
        />
        <section className="home-top-section-pointOne s1">
          <Banner
            id="tech"
            title={homeTitle.title}
            authorName={homeTitle.name}
            firstOne
            Fill={Fill}
          />
          <div className="home_top_left_vector">
            <img src={BannerImage.RightVetorOne} alt="" />
          </div>
          <div className="home_top_left_vector_phone">
            <img src={BannerImage.RightVetorOnePhone} alt="" />
          </div>
          <div className="bigRound" />
          <div className="smallRound" />
          <div className="home_top_right_vector">
            <img src={BannerImage.LeftVetorOne} alt="" />
          </div>
          <div className="home_top_right_vector_phone">
            <img src={BannerImage.LeftVetorOnePhone} alt="" />
          </div>
        </section>
        <section className="home-top-section s1" id="tech">
          <Banner
            refs={sectionRef}
            animation={animation}
            id="tech"
            second
            Fill={Fill}
          />
          <div className={`home_top_left_vector_two`}>
            <img src={animation && BannerImage.RightVetorTwo} alt="" />
          </div>
          <div className={`home_top_left_vector_two_phone`}>
            <img src={BannerImage.RightVetorTwoPhone} alt="" />
          </div>
          <div className={`bigRoundTwo ${animation && "imageAnimation"}`} />
          <div className={`smallRoundTwo ${animation && "imageAnimation"}`} />
          <div className={`home_top_right_vector_two`}>
            <img src={animation && BannerImage.LeftVetorTwo} alt="" />
          </div>
          <div className="home_top_right_vector_two_phone">
            <img src={BannerImage.LeftVetorOnePhone} alt="" />
          </div>
        </section>
        <section ref={ref} className="home-second-section">
          <Service first />
        </section>
        <section className="home-second-section">
          <Service second />
        </section>
        <section ref={thirdRef} className="home-third-section">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 works-content my-5 my-md-0">
                  <h3>HOW UNICAP WORKS?</h3>
                  {/* <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                </div>
                <div className="home-whyUnicap-card-container col-12 col-md-6 gap-3 ">
                  <div className="home-whyUnicap-card">
                    <h5>Invest</h5>
                    <div className="home_two_icon">
                      <img src={BannerImage.Iconomi} alt="" />
                    </div>
                    <p>Explore</p>
                    <SouthIcon />
                    <p>KYC</p>
                    <SouthIcon />
                    <p>Grow</p>
                    <button onClick={() => setOpenlogin(true)}>
                      <AiOutlineArrowRight size="20" />
                    </button>
                  </div>
                  <div className="home-whyUnicap-card">
                    <h5>Raise</h5>
                    <div className="home_two_icon">
                      <img src={BannerImage.Hand} alt="" />
                    </div>
                    <p>Due Diligence</p>
                    <SouthIcon />
                    <p>Commitment</p>
                    <SouthIcon />
                    <p>Funding</p>
                    <button onClick={() => setOpenlogin(true)}>
                      <AiOutlineArrowRight size="20" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </article>

      <Modal
        style={{ zIndex: 999999999, backdropFilter: "blur(5px)" }}
        // aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        // open={open}
        // dialogClassName="modal-90w"
        centered
        className={`
        modal-container
         ${handleModalwidth(Questionare)
            ? "home-modal-main-container2 form-bg-img"
            : "home-modal-main-container form-bg-img"
          } `}
        show={open}
        // fullscreen="fullscreen"
        // onHide={() => {
        //   handleClose();
        //   setpopupModal(false);
        // }}
        // closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //     timeout: 500,
        // }}
        backdropClassName="home-modal-backdrop"
      >
        {/* <Fade in={open}> */}
        {/* <div className="home-modal-container"> */}
        <AiOutlineClose
          size={30}
          className="close_icons"
          onClick={() => {
            handleClose();
            setpopupModal(false);
          }}
        />
        <HomeModal setQuestionare={setQuestionare} Questionare={Questionare} />

        {/* </div> */}
        {/* </Fade> */}
      </Modal>
    </>
  );
}

export default Home;
