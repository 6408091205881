import React, { useEffect, useState } from 'react'

import { IoCalendarClearOutline } from "react-icons/io5";

const InvestNowCard = ({ data, handleOpenData, investNow }) => {
    const [amount, setAmount] = useState(30)
    const [minUnit, setMinUnit] = useState()

    useEffect(() => {
        const total = Math.ceil(data?.minimumInvestment / data?.faceValue)
        setAmount(total)
        setAmount(total)
    }, [data])

    const handlePayable = (Amount, faceValue) => {
        return Amount * faceValue
    }

    function calculateInvestmentPeriod(startDate, endDate) {
        const diffInMs = endDate?.getTime() - startDate?.getTime();
        const msInYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
        const investmentPeriod = diffInMs / msInYear;
        const roundedInvestmentPeriod = Math.round(investmentPeriod * 100) / 100;
        return roundedInvestmentPeriod
    }

    const calculateFutureValue = (investmentAmount, interestRate, investmentPeriod) => {
        console.log(investmentAmount, interestRate, investmentPeriod)
        const futureValue = investmentAmount * Math.pow(1 + interestRate, investmentPeriod);
        const roundedFutureValue = Math.round(futureValue * 100) / 100;
        return roundedFutureValue.toLocaleString();
    }

    const handleTaxReturns = (data) => {
        const investmentAmount = handlePayable(amount, data?.faceValue);
        const interestRate = data?.preTaxReturns / 100
        const investmentPeriod = calculateInvestmentPeriod(new Date(data?.dateOfIssue), new Date(data?.maturityDate));
        const futureValue = calculateFutureValue(investmentAmount, interestRate, investmentPeriod)
        return futureValue
    }




    return (
        <div
            className=" p-3 w-100 home-modal-recommend-container "
            style={{ position: "relative" }}
        >
            <span className="d-flex justify-content-between px-2">
                <p>Current Price</p>
                <strong>₹ {data?.faceValue} / Unit</strong>
            </span>

            {/* <span className="d-flex justify-content-between px-2">
                    <p>Total Unit Left</p>
                    <strong>30000/ 50000</strong>
                  </span> */}

            <hr />
            <div className="d-flex flex-column align-items-center">
                <strong className="text-center w-100 mt-2">
                    No. Of Unit
                </strong>
                <div className="d-flex justify-content-between w-100 my-4">
                    <button
                        disabled={amount === minUnit}
                        title={amount === minUnit && "Minimum Unit Reached"}
                        onClick={() => setAmount(amount - 1)}
                        className="modal-decrement-btn"
                    >
                        <span>-</span>
                    </button>
                    <button className="modal-increment-value-display">
                        {amount}
                    </button>
                    <button
                        onClick={() => setAmount(amount + 1)}
                        className="modal-increment-btn"
                    >
                        +
                    </button>
                </div>
                {amount < 30 && (
                    <p
                        className="text-danger mb-3"
                        style={{ fontSize: "0.8rem" }}
                    >
                        Minimum No of Units should be 30
                    </p>
                )}
            </div>

            <hr />
            <span className="d-flex justify-content-between px-2">
                <p>Amount Payable</p>
                <strong>₹ {handlePayable(amount, data?.faceValue)} </strong>
            </span>

            <span className="d-flex justify-content-between px-2">
                <p>Pre-Tax Returns</p>
                <strong>₹ {handleTaxReturns(data)}</strong>
            </span>
            <div
                className="d-flex justify-content-center mt-2 "
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenData(data, handleTaxReturns(data))}
            >
                <h5 className="py-3">
                    <IoCalendarClearOutline size="22" />
                    &nbsp; Visualise Returns
                </h5>
            </div>

            <button
                onClick={() => investNow(data, amount)}
                className="investNow-btn"
            >
                Invest Now
            </button>
        </div>
    )
}

export default InvestNowCard