import LeftVetorOne from "./left-one.png";
import LeftVetorOnePhone from "./left-one-phone.png";
import LeftVetorTwo from "./landingpage2.gif";
import RightVetorOne from "./right-one.png";
import RightVetorOnePhone from "./right-one-phone.png";
import RightVetorTwo from "./landin_Page.gif";
import RightVetorTwoPhone from "./right-two-phone.png";
import FinanceLeft from "./Finance-amico left.png";
import FinanceRight from "./Finance-amico right.png";
import Hand from "./hand.png";
import Iconomi from "./iconomi.png";
import Search from "./search.png";
import question from "./question.png";
import works from "./works.png";
import closeIcon from "../closeIcon.png";
import ETO from "../eto.png";
import navi from "../cardLogo.png";
import HDFCBank from "../HDFC Bank.png";
import Intel from "../intel.png";
import Four from "../four.png";
import AmazonLogo from "../amzonLogo.png";
import Second from "../second.png";
import six from "../six.png";
import unilever from "../uniliver.png";
import Yes from "../yes.png";
import No from "../no.png";

export const BannerImage = {
    LeftVetorOne,
    LeftVetorOnePhone,
    LeftVetorTwo,
    RightVetorOne,
    RightVetorTwoPhone,
    RightVetorOnePhone,
    RightVetorTwo,
    Hand,
    Iconomi,
    FinanceLeft,
    FinanceRight,
    Search,
    question,
    works,
    closeIcon,
    navi,
    ETO,
    HDFCBank,
    Intel,
    Four,
    AmazonLogo,
    Second,
    six,
    unilever,
    Yes,
    No
};
