import React, { useState, useRef, useMemo } from "react";
import Logo from "../../../assets/upload.png";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../utils/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Modal, Table, Button } from "react-bootstrap";
import { RaiserPostListing } from "../../../utils/apis";
import Loading from "../../../common/Loading";
import { TagsInput } from "react-tag-input-component";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import moment from "moment/moment";

const ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const PostListing = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  // pre calculated return interest
  const [interestPeriodInput, setInterestPeriodInput] = useState("");
  const [annualReturnsInput, setAnnualReturnsInput] = useState("");
  const [returnProfile, setReturnProfile] = useState([]);

  const [modalData, setModalData] = useState([]);
  const [interest, setInterest] = useState(0);
  const [input, setInput] = useState({
    companyLogo: "",
    companyName: "",
    companyTagLine: "", // maximum 20
    companyDescription: "",
    assetsDetail: "",
    category: "Fixed Returns",
    industry: "",
    unitEconomicsBuisnessModel: "",
    minimumInvestment: "",
    faceValue: "20",
    preTaxReturns: "",
    dateOfIssue: "",
    maturityDate: "",
    isDrafted: false,
    ISIN: "", //should be of length 12
    transactionType: "UPI",
    couponRate: "23",
    aboutSeller: "",
    interestPeriod: "",
    closingDate: "",
    totalReturn: "",
    riskFactors: "",
    exitOptions: "",
    FAQs: "",
    isDrafted: false,
    maximumReturn: "Market Linked",
    companyPreference: "Startups",
    opportunity: "Equity",
    passive: "Private",
    defence: "Preleased rental",
    market: "Worldwide",
    timeLine: "<12months",
    totalInvestment: "",
    currentInvestedAmount: "0",
    comments: "",
    timeToMaturity: "",
    tags: [],
    monthlyReturns: [],
  });

  // calculate return profile

  const calculateReturnProfile = () => {
    if (!interestPeriodInput || !annualReturnsInput) {
      toast.error(
        "Please enter interest period and annual returns",
        ToastOptions
      );
      return;
    }

    const interestPeriodInMonths = parseInt(interestPeriodInput);

    const monthlyReturn = parseInt(annualReturnsInput) / 12;

    const returnProfileData = [];

    for (let i = 1; i <= interestPeriodInMonths; i++) {
      returnProfileData.push({
        month: i,
        returns: monthlyReturn.toFixed(2),
      });
    }

    setReturnProfile(returnProfileData);
  };

  const [progress, setProgress] = useState("100%");
  function fileUploadHandler() {
    const file = inputRef.current.files[0];
    if (!file) return;
    setLoading(true);
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = `${Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )}%`;
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let URL = { url: downloadURL };
          setInput({ ...input, companyLogo: URL });
          setLoading(false);
        });
      }
    );
  }
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input?.companyLogo.url?.length === 0 || input?.companyLogo.length === 0)
      return toast.error("Please upload a logo", ToastOptions);

    if (input?.timeToMaturity === 0)
      return toast.error("Maturity Date can't less then 6 Month", ToastOptions);

    const listData = {
      ...input,
      tags: tags,
    };

    try {
      const { data } = await RaiserPostListing(listData);
      console.log(data.message);
      toast.success(data.message, ToastOptions);
      navigate("/my-listing");
    } catch (error) {
      const { response } = error;
      toast.error(response.data.message, ToastOptions);
    }
  };
  const handleTimeToMaturity = () => {
    const dateOfIssue = new Date(input?.dateOfIssue);
    const maturityDate = new Date(input?.maturityDate);
    const totalMonths =
      (maturityDate.getFullYear() - dateOfIssue.getFullYear()) * 12 +
      (maturityDate.getMonth() - dateOfIssue.getMonth());
    setInput({ ...input, timeToMaturity: totalMonths });
    return totalMonths;
  };

  const handleOpen = () => {
    setOpen(true);
    const data = getDates(input.dateOfIssue, input.maturityDate);
    let arrayData = [];
    data?.forEach((item, index) => {
      arrayData.push({
        index: index,
        date: item,
        amount: 0,
      });
    });
    setModalData(arrayData);
    setInput((prev) => {
      return {
        ...prev,
        monthlyReturns: arrayData,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
    let itemData = [];
    modalData?.forEach((item) => {
      itemData.push({
        amount: Number(item?.amount),
      });
    });
    setInput((prev) => {
      return {
        ...prev,
        monthlyReturns: itemData,
      };
    });
  };

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "months");
    }
    return dateArray;
  }

  const handleSaveInterest = (index) => {
    let interestData = {
      ...modalData[index],
      amount: Number(interest),
    };
    let modalDataItems = modalData;
    modalDataItems.forEach((element, index2) => {
      if (index === index2) {
        modalDataItems[index] = interestData;
      }
    });
    let inputData = [];
    modalDataItems.forEach((itemData) => {
      inputData.push({
        amount: Number(itemData?.amount),
      });
    });
    setModalData(modalDataItems);
    setInterest(0);
  };

  useMemo(() => {
    if (input?.dateOfIssue && input?.maturityDate) {
      handleTimeToMaturity();
    }
  }, [input?.dateOfIssue, input?.maturityDate]);

  console.log(input);
  return (
    <>
      <Form
        className="category-main mt-5 p-3 container"
        onSubmit={handleSubmit}
      >
        <h6 className="listing-heading">Company </h6>
        <div className="d-flex mb-3">
          <div onClick={() => inputRef.current.click()}>
            <div className="u-logocompany">
              <img src={input?.companyLogo?.url || Logo} width="100px" />
              <input
                type="file"
                hidden
                accept="image/*"
                ref={inputRef}
                onChange={fileUploadHandler}
              />
            </div>
            <button className="upload-raiserlogo" type="button">
              {progress !== "100%"
                ? `Uploading ${progress}`
                : "Click To Upload"}
            </button>
          </div>
          <div className="d-flex w-100 gap-4">
            <Form.Group className="w-100">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                required
                value={input.companyName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="w-100">
              <p className="d-flex mb-0 justify-content-between">
                <Form.Label>Company Tagline </Form.Label>
                <small>Max 20 Character*</small>
              </p>
              <Form.Control
                type="text"
                name="companyTagLine"
                maxLength="20"
                required
                value={input.companyTagLine}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>

        <Form.Group as={Col} className="mb-4" controlId="formGridCity">
          <Form.Label>Company Preference</Form.Label>
          <Form.Select
            defaultValue="Choose..."
            name="companyPreference"
            value={input.companyPreference}
            onChange={handleChange}
          >
            <option>None</option>
            <option>Startups</option>
            <option>Listed</option>
            <option>Mature Company</option>
            <option>Any other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Company Description </Form.Label>
            <small>Max 1000 Character*</small>
          </p>

          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="companyDescription"
            value={input.companyDescription}
            onChange={handleChange}
          />
        </Form.Group>

        <h6 className="listing-heading mt-5">Assets </h6>

        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Assets Detail </Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="assetsDetail"
            value={input.assetsDetail}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Category</Form.Label>
          <Form.Select
            defaultValue="Choose..."
            name="category"
            value={input.category}
            onChange={handleChange}
          >
            <option>None</option>
            <option value="Fixed Returns">Fixed Returns</option>
            <option value="Rentals">Rentals</option>
            {/* <option value="Pre Leased"> Pre Leased</option> */}
            <option value="Startup Equity">Startup Equity</option>
            {/* <option>Commercial Property</option> */}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Industry </Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="industry"
            value={input.industry}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Unit economics Businesss Model</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="unitEconomicsBuisnessModel"
            value={input.assetsDetail}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Exit Strategy</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="exitOptions"
            value={input.exitOptions}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Risk Factors</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="riskFactors"
            value={input.riskFactors}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>FAQs</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="FAQs"
            value={input.FAQs}
            onChange={handleChange}
          />
        </Form.Group>

        <h6 className="listing-heading mt-5">Investments </h6>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Minimum Investment</Form.Label>
            <Form.Control
              type="number"
              required
              name="minimumInvestment"
              value={input.minimumInvestment}
              onChange={handleChange}
            />
          </Form.Group>

          {/* <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Face Value</Form.Label>
            <Form.Control
              type="number"
              required
              name="faceValue"
              value={input.faceValue}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Pre Tax Returns (%)</Form.Label>
            <Form.Control
              type="number"
              required
              name="preTaxReturns"
              value={input.preTaxReturns}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Total Return </Form.Label>
            <Form.Control
              type="number"
              required
              name="totalReturn"
              value={input.totalReturn}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Date Of Issue</Form.Label>
            <Form.Control
              type="date"
              required
              name="dateOfIssue"
              value={input.dateOfIssue}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Maturity Date</Form.Label>
            <Form.Control
              type="date"
              required
              name="maturityDate"
              value={input.maturityDate}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Round Closing Date</Form.Label>
            <Form.Control
              type="date"
              required
              name="closingDate"
              value={input.closingDate}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formGridCity" as={Col}>
            <Form.Label>Interest Period</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="interestPeriod"
              value={input.interestPeriod}
              onChange={handleChange}
            >
              <option>None</option>
              <option value="Monthly">Monthly</option>
              <option value="Quaterly">Quaterly</option>
              <option value="Annually">Annually</option>
              {/* <option>Commercial Property</option> */}
            </Form.Select>
          </Form.Group>
          {/* <Form.Group
            as={Col}
            controlId="formGridCity"
            className="d-flex flex-column"
          >
            <Form.Label>Monthly Interest</Form.Label>
            <button
              className="upload-raiserlogo"
              style={{ height: "38px", margin: 0 }}
              type="button"
              onClick={handleOpen}
            >
              Add Now
            </button>
          </Form.Group> */}

          <Form.Group as={Col} controlId="formGridCity">
            <p className="d-flex mb-0 justify-content-between">
              <Form.Label>ISIN</Form.Label>
              <small>Max 12 Character*</small>
            </p>
            <Form.Control
              type="text"
              required
              minLength="12"
              maxLength="12"
              name="ISIN"
              value={input.ISIN}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Transaction Type</Form.Label>
            <Form.Control
              type="text"
              required
              name="transactionType"
              value={input.transactionType}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Coupon Rate</Form.Label>
            <Form.Control
              type="number"
              required
              name="couponRate"
              value={input.couponRate}
              onChange={handleChange}
            />
          </Form.Group> */}
        </Row>

        {/* <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>About the Sellers</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            required
            maxLength="1000"
            name="aboutSeller"
            value={input.aboutSeller}
            onChange={handleChange}
          />
        </Form.Group>
        <Row className="mb-4">


          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Opportunity</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="opportunity"
              value={input.opportunity}
              onChange={handleChange}
            >
              <option>Equity</option>
              <option>Startup</option>
              <option>Crypto</option>
              <option>Collectibles</option>
              <option>Invoice</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Passive</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="passive"
              value={input.passive}
              onChange={handleChange}
            >
              <option>Private</option>
              <option>Bonds</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Defence</Form.Label>
            <Form.Select
              defaultValue="preleased rental"
              name="defence"
              value={input.defence}
              onChange={handleChange}
            >
              <option>Preleased rental</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>
              Time To Maturity{" "}
              <small style={{ fontSize: "11px" }}>(in months)</small>
            </Form.Label>
            <Form.Control
              type="number"
              disabled
              name="timeToMaturity"
              value={input?.timeToMaturity}
            // onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} className="mb-4">
            <Form.Label>Market</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="market"
              value={input.market}
              onChange={handleChange}
            >
              <option>Worldwide</option>
              <option>Domestic</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>TimeLine</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="timeLine"
              value={input.timeLine}
              onChange={handleChange}
            >
              <option>{`<12months`}</option>
              <option>1-3years</option>
              <option>+3years</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>Maximum Return</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              name="maximumReturn"
              value={input.maximumReturn}
              onChange={handleChange}
            >
              <option>Market Linked</option>
              <option>Fixed Return</option>
              <option>Combination</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>Total Investment</Form.Label>
            <Form.Control
              type="number"
              name="totalInvestment"
              value={input.totalInvestment}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Row> */}

        {/* <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Additional Info</Form.Label>
            <small>Max 1000 Character*</small>
          </p>
          <Form.Control
            as="textarea"
            rows={3}
            maxLength="1000"
            required
            name="comments"
            value={input.comments}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <p className="d-flex mb-0 justify-content-between">
            <Form.Label>Add Tags</Form.Label>
          </p>
          <TagsInput value={tags} onChange={setTags} />
        </Form.Group> */}

        <Form.Group className="mb-4">
          <Form.Label>Interest Period (in months)</Form.Label>
          <Form.Control
            type="number"
            required
            name="interestPeriodInput"
            value={interestPeriodInput}
            onChange={(e) => setInterestPeriodInput(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Annual Returns (%)</Form.Label>
          <Form.Control
            type="number"
            required
            name="annualReturnsInput"
            value={annualReturnsInput}
            onChange={(e) => setAnnualReturnsInput(e.target.value)}
          />
        </Form.Group>

        <div className="d-flex justify-content-center">
          <button
            className="common-btn mx-4"
            type="button"
            onClick={calculateReturnProfile}
          >
            Calculate Return Profile
          </button>
        </div>

        {/* Display the return profile */}
        {returnProfile.length > 0 && (
          <div className="mt-4">
            <h6>Return Profile</h6>
            <Table hover responsive className="text-center">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Monthly Returns</th>
                </tr>
              </thead>
              <tbody>
                {returnProfile.map((item) => (
                  <tr key={item.month}>
                    <td>{item.month}</td>
                    <td>{item.returns}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex w-100 justify-content-center">
          <button
            className="common-btn mx-4"
            type="submit"
            style={{ opacity: 0.8 }}
            onClick={() => setInput({ ...input, isDrafted: true })}
          >
            Save as Draft
          </button>
          <button
            className="common-btn mx-4"
            type="submit"
            onClick={() => setInput({ ...input, isDrafted: false })}
          >
            Submit to get verified
          </button>
        </div>
      </Form>
      {loading && <Loading />}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>
            <div className="visualize-title">
              <span>
                <HiOutlineCurrencyRupee size="22" />
              </span>
              Monthly Returns
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="visualize-body">
            <Table hover responsive className="text-center">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th> Tentative Date</th>
                  <th>Amount*</th>
                </tr>
              </thead>
              <tbody>
                {modalData?.map((item, index) => {
                  return (
                    <tr key={item}>
                      <td>{index + 1}</td>
                      <td>{item?.date}</td>
                      <td>
                        <input
                          onBlur={() => handleSaveInterest(index)}
                          defaultValue={item?.amount}
                          onChange={(e) => setInterest(e.target.value)}
                          style={{
                            outline: "none",
                            width: "50px",
                            border: "none",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                          type="text"
                        />
                      </td>
                    </tr>
                    //dateFormat(, "dd mmm yyyy");
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              style={{ width: "8rem" }}
              variant="primary"
              className="login-btn btn-ripple"
              onClick={handleClose}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PostListing;
