import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CiBank } from "react-icons/ci";
import { CiWallet } from "react-icons/ci";
import { CiHome } from "react-icons/ci";

// local imports
import { getAllListing } from "../../../utils/apis";
import CategoryCard from "../../../common/CategoryCard";
import HomeModal from "../../Home/HomeModal";
import FinanceModal from "./FinanceModal";
import Loading from "../../../common/Loading";
import "../../../styles/finance.css";
import "../../../styles/customModal.css";

const FinanceTypes = () => {
  const navigate = useNavigate();
  const { isAnswered } = useSelector((state) => state?.user);
  const data2 = useSelector((state) => state);
  const { role } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [offer, setOffers] = useState("active");
  const [types, setTypes] = useState("Industry");
  const [category, setCategory] = useState("Fixed_Returns");
  const [data, setData] = useState([]);
  const [Questionare, setQuestionare] = useState(1);
  const [popupModal, setpopupModal] = useState(true);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    localStorage.setItem("modalopen", "true"); // Set the flag in local storage
    setOpen(false);
  };

  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const GetListing = async () => {
    setLoading(true);
    try {
      const { data } = await getAllListing(offer, category);
      setData(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { response } = error;
      console.log(response.data);
      toast.error(response.data.message, ToastOptions);
    }
  };

  useEffect(() => {
    GetListing();
  }, [offer, category]);

  useEffect(() => {
    const hasModalBeenOpened = localStorage.getItem("modalopen");
    if (!hasModalBeenOpened && !isAnswered && role === "user") {
      handleOpen();
    }
  }, []);

  // handle modal width
  const handleModalwidth = (question) => {
    console.log(question);
    switch (question) {
      case 10:
      case 11:
      case 12:
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="finance-main container">
      <div className="finance-bg mb-4">
        {/* types btn */}
        <div className="select-btn">
          <span
            className={types === "Industry" ? "span-active" : ""}
            onClick={() => setTypes("Industry")}
          >
            Industry
          </span>
          <span
            className={types === "Risk" ? "span-active" : ""}
            onClick={() => setTypes("Risk")}
          >
            Risk
          </span>
          <span
            className={types === "Type Of Return" ? "span-active" : ""}
            onClick={() => setTypes("Type Of Return")}
          >
            Type Of Return
          </span>
          <span
            className={types === "Time Frame" ? "span-active" : ""}
            onClick={() => setTypes("Time Frame")}
          >
            Time Frame
          </span>
          <span
            className={types === "Type of Investment" ? "span-active" : ""}
            onClick={() => setTypes("Type of Investment")}
          >
            Type of Investment
          </span>
        </div>
        {/* category btn */}
        <div className="category-select-btn">
          <span
            className={category === "Fixed_Returns" ? "active" : ""}
            onClick={() => setCategory("Fixed_Returns")}
          >
            <CiBank />
            Fixed Returns
          </span>
          <span
            className={category === "Rentals" ? "active" : ""}
            onClick={() => setCategory("Rentals")}
          >
            <CiHome />
            Rentals
          </span>
          {/* <span
              className={category === "Inventories" ? "active" : ""}
              onClick={() => setCategory("Inventories")}
            >
              Inventories
            </span> */}
          <span
            className={category === "Startup_Equity" ? "active" : ""}
            onClick={() => setCategory("Startup_Equity")}
          >
            <CiWallet />
            Startup Equity
          </span>
          {/* <span
              className={category === "Commercial_Property" ? "active" : ""}
              onClick={() => setCategory("Commercial_Property")}
            >
              Commercial Property
            </span> */}
        </div>
      </div>
      {/* Corporate bonds Card*/}
      <div className="category-main">
        <div className="d-flex justify-content-between container">
          <h3>{types}</h3>
          <div className="offers-btn2">
            <select className="offers-select-btn">
              <option value="active">Active Offers</option>
              <option value="previous">Previous Offers</option>
              <option value="curated">Curated Offers</option>
            </select>
            <div className="category-compare-section">
              <button
                onClick={() => navigate("/choose-company")}
                type="button"
                className="add-btn btn-ripple"
              >
                Compare Investment
              </button>
            </div>
          </div>
        </div>

        {data.length > 0 ? (
          <div
            className={`d-flex flex-wrap${
              data.length >= 1
                ? "justify-content-start"
                : "justify-content-evenly"
            }`}
          >
            {data.map((e, id) => (
              <CategoryCard data={e} key={id} />
            ))}
          </div>
        ) : (
          <div className="d-flex  justify-content-center my-5 h-full">
            <h2 className="my-5">No offers present </h2>
          </div>
        )}
      </div>

      {/* <Modal
                style={{ zIndex: 999999999 }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => {
                    handleClose();
                    setpopupModal(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="home-modal-container">
                        <FinanceModal handleClose={handleClose} />
                    </div>
                </Fade>
            </Modal> */}
      <Modal
        style={{ zIndex: 999999999, backdropFilter: "blur(5px)" }}
        centered
        className={
          handleModalwidth(Questionare)
            ? "home-modal-main-container2 form-bg-img"
            : "home-modal-main-container form-bg-img"
        }
        show={open}
        backdropClassName="home-modal-backdrop"
      >
        <>
          <AiOutlineClose
            size={30}
            className="close_icons"
            onClick={() => {
              handleClose();
              setpopupModal(false);
            }}
          />
          <HomeModal
            setQuestionare={setQuestionare}
            Questionare={Questionare}
          />
        </>
      </Modal>

      {loading && <Loading />}
    </div>
  );
};

export default FinanceTypes;
