import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

import { ComparisonData } from "./Index";
import { toast } from "react-toastify";
import { useMemo } from "react";

const ComparisonCompanyes = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            if (selectedItems.length > 2) {
                toast.info("Select only 3 Companies", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
        }
    };

    const handleError = () => {
        if (selectedItems.length < 3) {
            toast.info("Select 3 Companies", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (selectedItems.length > 3) {
            toast.info("Select only 3 Companies", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    useMemo(() => {
        if (state) {
            setSelectedItems(state);
        }
    }, [state]);

    return (
        <div className="container my-5">
            <div className="comparison-company-container">
                <div className="comparison-company-header container">
                    <div className="comparison-company-header-info">
                        <BiArrowBack onClick={() => navigate("/home")} />
                        <h6>Select 3 companies for comparison</h6>
                    </div>
                    <div className="comparison-company-compareBtn">
                        <button
                            title={
                                selectedItems.length < 3 && "Select 3 Companies"
                            }
                            onClick={
                                selectedItems.length < 3 ||
                                selectedItems.length > 3
                                    ? () => handleError()
                                    : () =>
                                          navigate("/comparison", {
                                              state: selectedItems,
                                          })
                            }
                            type="button"
                            className="add-btn btn-ripple"
                        >
                            Compare
                        </button>
                    </div>
                </div>
            </div>
            <div className="comparison-companys-container container">
                <div className="comparison-companys row">
                    {ComparisonData.map((item) => (
                        <div
                            key={item.id}
                            className="comparison-companys-item col-12 col-md-4 col-lg-3"
                        >
                            <div className="comparison-companys-item-info">
                                <div className="comparison-companys-item-info-img">
                                    <img src={item.image} alt="" />
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            handleCheckboxChange(e, item)
                                        }
                                        checked={selectedItems.some(
                                            (i) => i.id === item.id
                                        )}
                                    />
                                </div>
                                <div className="comparison-companys-item-about">
                                    <h6>{item.name}</h6>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ComparisonCompanyes;
