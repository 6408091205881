import "../styles/footer.css";

const CustomButton = ({ title, btnClass, handleClick }) => {
    return (
        <button onClick={handleClick} className={`custome-button ${btnClass}`}>
            {title}
        </button>
    );
};

export default CustomButton;
