import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ReactComponent as Img } from "../../../assets/slider1.svg";

import "../../../styles/profile.css";

function KYCSlider({ scrollVal }) {
    return (
        <>
            <ProgressBar
                percent={scrollVal}
                filledBackground=" var(--primary)"
                style={{ height: "3px" }}
            >
                <Step transition="scale">
                    {({ accomplished }) => (
                        <div className="d-flex flex-column align-items-center progress-step-text text-nowrap">
                            <h6>Step 1</h6>
                            <Img
                                fill={
                                    accomplished ? " var(--primary)" : "#bbbbbb"
                                }
                            />

                            <h6>Upload Documents</h6>
                        </div>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <div className="d-flex flex-column align-items-center progress-step-text">
                            <h6>Step 2</h6>
                            <Img
                                fill={
                                    accomplished ? " var(--primary)" : "#bbbbbb"
                                }
                            />

                            <h6>PAN Card</h6>
                        </div>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <>
                            <div className="d-flex flex-column align-items-center progress-step-text">
                                <h6>Step 3</h6>
                                <Img
                                    fill={
                                        accomplished
                                            ? " var(--primary)"
                                            : "#bbbbbb"
                                    }
                                />

                                <h6>Aadhar Card</h6>
                            </div>
                        </>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                        <>
                            <div className="d-flex flex-column align-items-center progress-step-text">
                                <h6>Step 4</h6>
                                <Img
                                    fill={
                                        accomplished
                                            ? " var(--primary)"
                                            : "#bbbbbb"
                                    }
                                />

                                <h6>Bank Details</h6>
                            </div>
                        </>
                    )}
                </Step>
            </ProgressBar>
        </>
    );
}

export default KYCSlider;
