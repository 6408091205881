import React, { useEffect, useState } from "react";
import cardlogo from "../../assets/cardLogo.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CustomeSelect from "../../common/CustomeSelect";
import { ModalQuestion } from "../../constant";
import Select from "react-select";
import * as yup from "yup";
import { AddSurvry, CreateOrder, allListings } from "../../utils/apis";
import { toast } from "react-toastify";
import InvestNowCard from "../OfferCard/InvestNowCard";
import { Images } from "../../assets";
import { useSelector } from "react-redux";
import VisualizeReturn from "../UserSide/Finances/VisualizeReturn";
import logo from "../../assets/logo.png";
import bg1 from "../../assets/homemodal/image (4).png";
import bg2 from "../../assets/homemodal/image (5).png";
import bg3 from "../../assets/homemodal/image (6).png";

function HomeModal({ Questionare, setQuestionare }) {
  const [investLoading, setInvestLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const user = useSelector((state) => state.user);
  const [listings, setListings] = useState([]);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({
    q1: [],
    q2: [],
    q3: [],
    q4: [],
    q5: [],
    q6: [],
    q7: [],
  });

  const handleSelectChange = (questionId, selected) => {
    if (selectedOptions[questionId]?.includes(selected)) {
      setSelectedOptions((prev) => {
        return {
          ...prev,
          [questionId]: selectedOptions[questionId]?.includes(selected)
            ? selectedOptions[questionId]?.filter((item) => item !== selected)
            : [...selectedOptions[questionId], selected],
        };
      });
    } else {
      setSelectedOptions((prev) => {
        return {
          ...prev,
          [questionId]:
            selectedOptions[questionId]?.length < 2
              ? selectedOptions[questionId]?.includes(selected)
                ? selectedOptions[questionId]?.filter(
                    (item) => item !== selected
                  )
                : [...selectedOptions[questionId], selected]
              : selectedOptions[questionId],
        };
      });
    }
  };

  //Validate Form
  const schema = yup.object().shape({
    whatsappNumber: yup.number().required().positive().integer(),
    email: yup.string().email().required(),
  });
  const ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [isWhatsappNumberValid, setIsWhatsappNumberValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleWhatsappNumberKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === 101 || charCode === 69) {
      // prevent "e" or "E" key press
      event.preventDefault();
    }
  };

  const handleWhatsappNumberChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 10) {
      // use the length property to check the number of characters
      setIsWhatsappNumberValid(false);
      return "Whatsapp number must be 10 digits or less"; // return an error message
    }
    setWhatsappNumber(inputValue);

    schema
      .validate({ whatsappNumber: inputValue, email }, { abortEarly: false })
      .then(() => setIsWhatsappNumberValid(true))
      .catch(() => setIsWhatsappNumberValid(false));
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    schema
      .validate({ whatsappNumber, email: inputValue }, { abortEarly: false })
      .then(() => setIsEmailValid(true))
      .catch(() => setIsEmailValid(false));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (String(whatsappNumber).length !== 10) {
      setErrors({ whatsappNumber: "Number must be length of 10 digits" });
    } else {
      try {
        await schema.validate({ whatsappNumber, email }, { abortEarly: false });
        // Validation passed, submit the form here
        setSubmit(true);
        const newdata = {
          q1: selectedOptions.q1,
          q2: selectedOptions.q2,
          q3: selectedOptions.q3,
          q4: selectedOptions.q4,
          q5: selectedOptions.q5,
          q6: selectedOptions.q6,
          q7: selectedOptions.q7,
          number: whatsappNumber,
          email: email,
        };
        const res = await AddSurvry(newdata);
        setSubmit(false);
        handleToastNotification("success", "Response Submitted");

        setQuestionare(Questionare + 2);
      } catch (err) {
        setErrors(
          err.inner.reduce((acc, curr) => {
            acc[curr.path] = curr.message;
            return acc;
          }, {})
        );
        setSubmit(false);
        handleToastNotification("error", "Try Again");
      }
      if (isWhatsappNumberValid && isEmailValid) {
        setQuestionare(Questionare + 1);
      }
    }
  };
  const firstquestion = () => {
    if (selectedOptions.q1.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const secondquestion = () => {
    if (selectedOptions.q2.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const thirdquestion = () => {
    if (selectedOptions.q3.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const fourthquestion = () => {
    if (selectedOptions.q4.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const fifthquestion = () => {
    if (selectedOptions.q5.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const sixthquestion = () => {
    if (selectedOptions.q6.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const seventhquestion = () => {
    if (selectedOptions.q7.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      handleToastNotification("warning");
    }
  };
  const eightquestion = () => {
    if (selectedOptions.q8.length != 0) {
      setQuestionare(Questionare + 1);
    } else {
      alert("Please select a maximum of 2 options for all questions.");
    }
  };

  const handleToastNotification = (
    type,
    message = "Please select a maximum of 2 options for all questions."
  ) => {
    if (type === "warning") return toast.warning(message, ToastOptions);
    if (type === "success") return toast.success(message, ToastOptions);
    if (type === "error") return toast.error(message, ToastOptions);
  };

  useEffect(() => {
    const fetchListing = async () => {
      const select = "active";
      const category = "Corporate_Bonds";
      try {
        const response = await allListings(select, category);
        setListings(response?.data?.result);
      } catch (err) {
        console.log(err);
      }
    };

    fetchListing();
  }, []);

  const investNow = async ({ data, amount }) => {
    setInvestLoading(true);
    if (
      user?.kycStatus === null ||
      user?.kycStatus === "rejected" ||
      user?.kycStatus === "pending"
    ) {
      setInvestLoading(false);
      return toast.info("Please Complete KYC Information First", ToastOptions);
    }
    try {
      const newdata = {
        total: amount * data?.faceValue,
        listingId: data?.id,
      };
      const res = await CreateOrder(newdata);
      setInvestLoading(false);
      navigate("/payment-confirm", {
        state: {
          heading: "Payment successful",
          headingClass: "paymentSucess",
          message:
            "Congratulations! Your investment payment has been successfully processed.",
          btnClass: "brandBgBtn",
          btnTitle: "Invest more",
          image: Images.PaymentAccept,
        },
      });
    } catch (error) {
      const { response } = error;
      console.log(error);
      setInvestLoading(false);
      toast.error(response?.data?.message, ToastOptions);
      // navigate("/payment-confirm", {
      //   state: {
      //     heading: "Payment failed",
      //     headingClass: "paymentFail",
      //     message:
      //       "Your payment was declined. Please try again or use a different payment method",
      //     btnClass: "paymentFailBtn",
      //     btnTitle: "Try again",
      //     image: Images.PaymentFail,
      //   },
      // });
    }
  };

  const handleOpenData = (data, preTAx) => {
    setSingleData({ ...data, preTAx: preTAx });
    setOpen(true);
  };

  const emailValidation = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  };

  return (
    <>
      {Questionare === 1 ? (
        <>
          <div className="home-modal-section">
            <div className="d-flex col-6 flex-column align-items-center home-modal">
              <img src={logo} width="150px" alt="unicap-logo" />
              <h4>Answer Few Questions</h4>
              {/* <h6>For Better recommandation</h6> */}
              <div className="px-2 px-md-4 py-2 home-modal-content">
                {/* <h6>Let’s have a quick survey</h6> */}
                <h6>
                  Let's know what is best for you! No more than 1 minute..
                </h6>

                <button onClick={() => setQuestionare(Questionare + 1)}>
                  Get Started
                </button>
              </div>
            </div>

            <div className="col-6">
              <img src={bg1} className="w-100" alt="modal-banner" />
            </div>
          </div>
        </>
      ) : Questionare === 2 ? (
        <>
          <div>
            <div className="pt-3 mt-3  px-2 px-md-4">
              <h4>Questions 1 of 8</h4>
              <p>Select 2 options</p>
            </div>

            <div className="home-modal-questions-container">
              <form className="d-flex flex-wrap justify-content-between  px-2 px-md-4 py-3">
                <div className="form-group col-12  my-4 questions">
                  <label for="exampleInputEmail1" className="mb-4">
                    The investment objective?
                  </label>
                  <div className="w-100 my-3 d-flex flex-column">
                    {ModalQuestion[0]?.map((item) => {
                      return (
                        <span
                          style={{
                            padding: "10px 20px",
                            color: selectedOptions?.q1?.includes(item?.value)
                              ? "white"
                              : "black",
                            fontWeight: "500",
                            border: "1px solid gray",
                            borderRadius: "25px",
                            marginRight: "10px",
                            marginBottom: "30px",
                            display: "inline-block",
                            cursor: "pointer",
                            boxShadow: selectedOptions?.q1?.includes(
                              item?.value
                            )
                              ? "0 2px 4px rgba(0,0,0,0.33)"
                              : "none",
                            background: selectedOptions?.q1?.includes(
                              item?.value
                            )
                              ? "#1d4442"
                              : "none",
                          }}
                          onClick={() => handleSelectChange("q1", item?.value)}
                        >
                          {item.value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </form>
              <div className="col-5">
                <img src={bg2} className="w-100" alt="banner-2" />
              </div>
            </div>
            <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
              <button onClick={() => setQuestionare(Questionare - 1)}>
                Back
              </button>
              <button onClick={() => firstquestion()}>Next</button>
            </div>
          </div>
        </>
      ) : Questionare === 3 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 2 of 8</h4>
            <p>Select 2 options</p>
          </div>

          <div className="home-modal-questions-container">
            <form className="d-flex flex-wrap justify-content-between  px-2 px-md-4 py-3">
              <div className="form-group col-12 my-4 questions">
                <label for="exampleInputEmail1 " className="mb-4">
                  The time horizon?
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[1]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q2?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q2?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q2?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q2", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg3} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => secondquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 4 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 3 of 8</h4>
            <p>Select 2 options</p>
          </div>
          <div className="home-modal-questions-container">
            <form className="d-flex flex-wrap justify-content-between  px-2 px-md-4 py-3">
              <div className="form-group col-12 my-4 questions">
                <label htmlFor="exampleInputEmail1" className="mb-4">
                  What is your risk tolerance?{" "}
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[2]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q3?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q3?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q3?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q3", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg2} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => thirdquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 5 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 4 of 8</h4>
            <p>Select 2 options</p>
          </div>
          <div className="home-modal-questions-container">
            <form className="d-flex  flex-wrap justify-content-between px-2 px-md-4 py-3">
              <div className="form-group col-12 my-4 questions">
                <label htmlFor="exampleInputEmail1" className="mb-4">
                  Got any experience?{" "}
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[3]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q4?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q4?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q4?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q4", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg3} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => fourthquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 6 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 5 of 8</h4>
            <p>Select 2 options</p>
          </div>
          <div className="home-modal-questions-container">
            <form className="d-flex flex-wrap  justify-content-between px-2 px-md-4 py-3">
              <div className="form-group col-12  my-4 questions">
                <label for="exampleInputEmail1 " className="mb-4">
                  How important is it for you to invest in socially responsible
                  companies?
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[4]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q5?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q5?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q5?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q5", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg2} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => fifthquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 7 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 6 of 8</h4>
            <p>Select 2 options</p>
          </div>
          <div className="home-modal-questions-container">
            <form className="d-flex flex-wrap justify-content-between px-2 px-md-4 py-3">
              <div className="form-group col-12  my-4 questions">
                <label for="exampleInputEmail1 " className="mb-4">
                  How often would you like to receive updates?
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[5]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q6?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q6?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q6?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q6", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg3} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => sixthquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 8 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 7 of 8</h4>
            <p>Select 2 options</p>
          </div>
          <div className="home-modal-questions-container">
            <form className="d-flex  flex-wrap justify-content-between px-2 px-md-4 py-3">
              <div className="form-group col-12 my-4 questions">
                <label htmlFor="exampleInputEmail1" className="mb-4">
                  How important is it for you to have access to investment
                  analysis?{" "}
                </label>
                <div className="col-12 my-3 d-flex flex-column">
                  {ModalQuestion[6]?.map((item) => {
                    return (
                      <span
                        style={{
                          padding: "10px 20px",
                          color: selectedOptions?.q7?.includes(item?.value)
                            ? "white"
                            : "black",
                          fontWeight: "500",
                          border: "1px solid gray",
                          borderRadius: "25px",
                          marginRight: "10px",
                          marginBottom: "30px",
                          display: "inline-block",
                          cursor: "pointer",
                          boxShadow: selectedOptions?.q7?.includes(item?.value)
                            ? "0 2px 4px rgba(0,0,0,0.33)"
                            : "none",
                          background: selectedOptions?.q7?.includes(item?.value)
                            ? "#1d4442"
                            : "none",
                        }}
                        onClick={() => handleSelectChange("q7", item?.value)}
                      >
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="col-5">
              <img src={bg2} className="w-100" alt="banner-2" />
            </div>
          </div>
          <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
            <button onClick={() => setQuestionare(Questionare - 1)}>
              Back
            </button>
            <button onClick={() => seventhquestion()}>Next</button>
          </div>
        </>
      ) : Questionare === 9 ? (
        <>
          <div className="pt-3  px-2 px-md-4 mt-3">
            <h4>Questions 8 of 8</h4>
          </div>

          <form
            onSubmit={(e) => handleSubmit(e)}
            className="d-flex flex-wrap justify-content-between px-2 px-md-4 py-3"
          >
            <div class="form-group col-12 my-4 questions">
              <label for="exampleInputEmail1 ">
                Preferred method of communication? (Don't worry, NO SPAMS!)
              </label>
              <div className="row gx-4 my-4">
                <div className="col-12 col-lg mb-4 mb-lg-0">
                  <input
                    type="number"
                    maxLength="10"
                    minLength="10"
                    className={`form-control col-6 ${
                      !isWhatsappNumberValid && "is-invalid"
                    }`}
                    required
                    placeholder="Type Whatsapp number"
                    value={whatsappNumber}
                    onChange={handleWhatsappNumberChange}
                    aria-describedby="whatsappNumberHelp"
                    onKeyPress={handleWhatsappNumberKeyPress}
                  />
                  {errors.whatsappNumber && (
                    <div className="invalid-feedback">
                      {errors.whatsappNumber}
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg">
                  <input
                    type="email"
                    className={`form-control col-6 ${
                      !isEmailValid && "is-invalid"
                    }`}
                    required
                    placeholder="Please Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    aria-describedby="emailHelp"
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 px-2 px-md-4 pb-4 question-btn-two">
              <button onClick={() => setQuestionare(Questionare - 1)}>
                Back
              </button>
              <button type="submit" disabled={submit}>
                {submit ? "Loading..." : "Show results"}
              </button>
            </div>
          </form>
        </>
      ) : Questionare === 10 ? (
        <main
          className="p-1 p-md-4 modal-company-info-container"
          style={{ width: "80vw" }}
        >
          {listings?.slice(1, 2).map((data) => (
            <div className="d-flex p-3 mb-4 mb-md-0 p-md-4  flex-column flex-md-row">
              {/* left */}
              <div className="modal-company-info" style={{ width: "75%" }}>
                <div className="d-flex">
                  <img
                    src={data?.companyLogo?.url}
                    height={90}
                    width={90}
                    alt="logo"
                  />
                  <div className="flex-column ms-3">
                    <h4>{data?.companyName}</h4>
                    <p>{data?.companyDescription.split(".\n")[0]}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <h5>About {data?.companyName}</h5>
                  <p>{data?.companyDescription}</p>
                </div>
              </div>

              {/* right */}
              <div className="w-50 d-flex align-items-end flex-column home-modal-recommend-maincontainer">
                {/* <div
                className=" p-3 w-75 home-modal-recommend-container "
                style={{ position: "relative" }}
              >
                <span className="d-flex justify-content-between px-2">
                  <p>Current Price</p>
                  <strong>₹ 25,000 / Unit</strong>
                </span>

                <span className="d-flex justify-content-between px-2">
                  <p>Total Unit Left</p>
                  <strong>30000/ 50000</strong>
                </span>

                <hr />
                <div className="d-flex flex-column align-items-center">
                  <strong className="text-center w-100 mt-2">
                    No. Of Unit
                  </strong>
                  <div className="d-flex justify-content-between w-100 mt-4">
                    <button
                      onClick={() => setvalue(value - 1)}
                      className="modal-increment-btn"
                    >
                      -
                    </button>
                    <button className="modal-increment-value-display">
                      {value}
                    </button>
                    <button
                      onClick={() => setvalue(value + 1)}
                      className="modal-increment-btn"
                    >
                      +
                    </button>
                  </div>
                  <p
                    className="text-danger mt-3"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Minimum No of Units should be 30
                  </p>
                </div>

                <hr />
                <span className="d-flex justify-content-between px-2">
                  <p>Current Price</p>
                  <strong>₹ 25,000 / Unit</strong>
                </span>

                <span className="d-flex justify-content-between px-2">
                  <p>Total Unit Left</p>
                  <strong>30000/ 50000</strong>
                </span>

                <button className="investNow-btn">Invest Now</button>
              </div> */}
                <InvestNowCard
                  investNow={investNow}
                  handleOpenData={handleOpenData}
                  data={data}
                />
              </div>
            </div>
          ))}
          <div
            className="w-100 d-flex my-2 my-md-0 justify-content-end px-3 "
            style={{
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100% !important",
            }}
          >
            <strong
              style={{ color: "#1d4442", cursor: "pointer" }}
              onClick={() => setQuestionare(Questionare + 1)}
            >
              {" "}
              <span className="me-2">Next</span> <AiOutlineArrowRight />{" "}
            </strong>
          </div>
        </main>
      ) : (
        <main
          className="p-1 p-md-4 modal-company-info-container"
          style={{ width: "80vw" }}
        >
          {listings?.slice(0, 1).map((data) => (
            <div className="d-flex p-3 mb-4 mb-md-0 p-md-4  flex-column flex-md-row">
              {/* left */}
              <div
                className="modal-company-info pe-5 text-justify"
                style={{ width: "75%" }}
              >
                <div className="d-flex">
                  <img
                    src={data?.companyLogo?.url}
                    height={90}
                    width={90}
                    alt="logo"
                  />
                  <div className="flex-column ms-3">
                    <h4>{data?.companyName}</h4>
                    <p style={{ textAlign: "justify" }}>
                      {data?.companyDescription.split(".\n")[0]}
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <h5>About {data?.companyName}</h5>
                  <p style={{ textAlign: "justify" }}>
                    {data?.companyDescription}
                  </p>
                </div>
              </div>

              {/* right */}
              <div className="w-50 d-flex align-items-end flex-column home-modal-recommend-maincontainer">
                {/* <div
                className=" p-3 w-75 home-modal-recommend-container "
                style={{ position: "relative" }}
              >
                <span className="d-flex justify-content-between px-2">
                  <p>Current Price</p>
                  <strong>₹ 25,000 / Unit</strong>
                </span>

                <span className="d-flex justify-content-between px-2">
                  <p>Total Unit Left</p>
                  <strong>30000/ 50000</strong>
                </span>

                <hr />
                <div className="d-flex flex-column align-items-center">
                  <strong className="text-center w-100 mt-2">
                    No. Of Unit
                  </strong>
                  <div className="d-flex justify-content-between w-100 mt-4">
                    <button
                      onClick={() => setvalue(value - 1)}
                      className="modal-increment-btn"
                    >
                      -
                    </button>
                    <button className="modal-increment-value-display">
                      {value}
                    </button>
                    <button
                      onClick={() => setvalue(value + 1)}
                      className="modal-increment-btn"
                    >
                      +
                    </button>
                  </div>
                  <p
                    className="text-danger mt-3"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Minimum No of Units should be 30
                  </p>
                </div>

                <hr />
                <span className="d-flex justify-content-between px-2">
                  <p>Current Price</p>
                  <strong>₹ 25,000 / Unit</strong>
                </span>

                <span className="d-flex justify-content-between px-2">
                  <p>Total Unit Left</p>
                  <strong>30000/ 50000</strong>
                </span>

                <button className="investNow-btn">Invest Now</button>
              </div> */}
                <InvestNowCard
                  investNow={investNow}
                  handleOpenData={handleOpenData}
                  data={data}
                />
              </div>
            </div>
          ))}
          <div className="w-100 d-flex my-2 my-md-0  justify-content-end px-3">
            {/* <strong
              style={{ color: "#1d4442" }}
              onClick={() => setQuestionare(Questionare - 1)}
            >
              <AiOutlineArrowLeft /> <span className="m-2">Previous</span>{" "}
            </strong> */}
            <strong
              style={{ color: "#1d4442" }}
              onClick={() => navigate("/offers")}
            >
              <AiOutlineArrowRight />{" "}
              <span className="m-2 cursor">View All</span>{" "}
            </strong>
          </div>
        </main>
      )}
      <VisualizeReturn
        open={open}
        setOpen={setOpen}
        data={singleData}
        Time={singleData?.timeToMaturity ? singleData?.timeToMaturity : 36}
        // Total={handlePayable(amount, data?.faceValue)}
        Total={singleData?.preTAx}
        date={singleData.dateOfIssue}
      />
    </>
  );
}

export default HomeModal;
